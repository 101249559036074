import React, { useContext, useEffect, useMemo, useState } from "react";
import ParentContext from "../../context/context";
import EditTransactionModal from "./EditTransactionModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faEdit,
  faMinus,
  faPlus,
  faPlusSquare,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AddMissingTransaction from "./AddMissingTransaction";
import AddNewColumn from "./AddNewColumn";

const BankStatementTable = (props) => {
  const { state } = useContext(ParentContext);
  const { backendResponse, currentPage } = state;
  const {
    tableData,
    selectedRow,
    rowRefs,
    searchRow,
    setSearchRow,
    setSelectedRow,
    tableAccNo,
    serviceType,
  } = props;
  const [show, setShow] = useState(false);
  const [addTransactnModel, setAddTransactnModel] = useState(false);
  const [editableTransactionData, setEditableTransactionData] = useState(null);
  const [commonTransactionData, setCommonTransactionData] = useState(null);
  const [bankSttTableData, setBankSttTableData] = useState(tableData);
  const [tableRowIndex, setTableRowIndex] = useState(null);
  const [addColumn, setAddColumn] = useState(false);
  const [newColumn, setNewColumn] = useState("");
  const [columnID, setColumnID] = useState(null);
  const [columnName, setColumnName] = useState(null);

  const tableHeaders = useMemo(() => {
    return (
      serviceType === "Bank Statement" &&
      bankSttTableData?.data &&
      bankSttTableData?.data[0] &&
      bankSttTableData?.data[0][tableAccNo] &&
      bankSttTableData?.data[0][tableAccNo][0] &&
      Object.keys(bankSttTableData?.data[0][tableAccNo][0])
    );
  }, [bankSttTableData, tableAccNo]);

  const tableBodyRowValues = useMemo(() => {
    return (
      serviceType === "Bank Statement" &&
      bankSttTableData?.data &&
      bankSttTableData?.data[0] &&
      bankSttTableData?.data[0][tableAccNo]
    );
  }, [bankSttTableData, tableAccNo]);

  const transposedData =
    serviceType !== "Bank Statement" && transposeData(tableData);

  function transposeData(data) {
    if (
      !Array.isArray(data) ||
      data.length === 0 ||
      !Array.isArray(data[0].Value)
    ) {
      console.error("Invalid table data format");
      return [];
    }
    const numRows = data[0]?.Value?.length;
    const numCols = data?.length;
    const transposedData = Array.from({ length: numRows }, () =>
      Array(numCols).fill([])
    );
    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < numCols; col++) {
        transposedData[row][col] = data[col].Value[row];
      }
    }
    return transposedData;
  }

  const hanleDeleteColumn = (headerName) => {
    const updatedColumnsAfterDeletion = {
      ...bankSttTableData,
      data: [
        {
          [tableAccNo]: bankSttTableData?.data[0]?.[tableAccNo]?.map(
            (rowValues) => {
              const updatedRow = { ...rowValues };
              delete updatedRow[headerName];
              return updatedRow;
            }
          ),
        },
      ],
    };
    setBankSttTableData(updatedColumnsAfterDeletion);
  };

  useEffect(() => {
    serviceType === "Bank Statement" &&
      rowRefs?.current?.filter((ref) => {
        if (ref?.id === selectedRow) {
          ref?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      });
  }, [rowRefs, selectedRow]);

  return (
    <React.Fragment>
      {serviceType !== "Bank Statement" &&
        backendResponse?.Data[currentPage] && (
          <table id="BsTable" className="table1">
            <thead className="thead1">
              <tr className="tr1">
                {transposedData[0].map((header) => (
                  <th className="th1" key={header}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="tbody1">
              {transposedData.slice(1).map((transposedRow, rowIndex) => (
                <tr className="tr1" key={rowIndex}>
                  {transposedRow.map((cell, cellIndex) => (
                    <td className="td1" key={cellIndex}>
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      {serviceType === "Bank Statement" &&
        tableHeaders &&
        tableBodyRowValues && (
          <table id="bsTable" className="table1">
            <thead className="thead1">
              <tr className="tr1 position-relative">
                {tableHeaders?.map((header, headerIndex) => (
                  <th
                    className="th1 column position-relative"
                    key={headerIndex}
                  >
                    {header.replace("_", " ")}
                    {}
                    {header?.includes("edit") === false && (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Add Column</Tooltip>}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          onClick={() => {
                            setColumnID(headerIndex + 1);
                            setColumnName(header);
                            setAddColumn(true);
                          }}
                          className="add-column-icon"
                        />
                      </OverlayTrigger>
                    )}
                    {header?.includes("edit") === true && (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Delete Column</Tooltip>}
                      >
                        <FontAwesomeIcon
                          icon={faMinus}
                          onClick={() => {
                            hanleDeleteColumn(header);
                          }}
                          className="add-column-icon"
                        />
                      </OverlayTrigger>
                    )}
                    {addColumn &&
                      columnID === headerIndex + 1 &&
                      header?.includes("edit") === false && (
                        <AddNewColumn
                          setShow={setAddColumn}
                          newColumn={newColumn}
                          setNewColumn={setNewColumn}
                          columnName={columnName}
                          bankSttTableData={bankSttTableData}
                          setBankSttTableData={setBankSttTableData}
                          tableAccNo={tableAccNo}
                        />
                      )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="tbody1">
              {tableBodyRowValues.map((rowValues, rowIndex) => {
                return (
                  <tr
                    className="tr1"
                    id={rowValues.TEXTRACT_ID}
                    key={rowIndex}
                    // this code is needed, check and uncomment it
                    // ref={(el) => (rowRefs.current[rowIndex] = el)} // Store the row refs
                    style={{
                      // Highlight the selected row
                      backgroundColor:
                        (selectedRow === rowValues.TEXTRACT_ID ||
                          searchRow === rowValues.TEXTRACT_ID) &&
                        "#cdcdda75",
                      border:
                        (selectedRow === rowValues.TEXTRACT_ID ||
                          searchRow === rowValues.TEXTRACT_ID) &&
                        "3px dashed #4b4b4b",
                    }}
                  >
                    {Object.entries(rowValues).map(
                      ([cellKey, cellValue], cellIndex) => {
                        const { TEXTRACT_ID, PAGE_NUMBER } = rowValues;
                        if (cellKey === "PAGE_NUMBER") {
                          return (
                            <td className="td1" key={cellIndex}>
                              <div className="page_number_search">
                                {cellValue}
                                {cellValue !== "" && (
                                  <FontAwesomeIcon
                                    icon={faSearch}
                                    onClick={() => {
                                      setSelectedRow(TEXTRACT_ID);
                                      setSearchRow(
                                        TEXTRACT_ID,
                                        parseInt(PAGE_NUMBER)
                                      );
                                    }}
                                    className="searchIcon"
                                  />
                                )}
                              </div>
                            </td>
                          );
                        }
                        if (
                          cellValue?.length > 1 &&
                          cellValue?.includes("editable")
                        ) {
                          return (
                            <td className="td1" key={cellIndex}>
                              <textarea
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  resize: "none",
                                  border: "unset",
                                  background: "transparent",
                                }}
                              />
                            </td>
                          );
                        } else {
                          return (
                            <td className="td1" key={cellIndex}>
                              {cellIndex === 0 && (
                                <div className="verify_data td1">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip">Approve</Tooltip>
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faCheckSquare}
                                      className="approve"
                                    />
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip">Edit</Tooltip>
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faEdit}
                                      onClick={() => {
                                        setEditableTransactionData(
                                          tableBodyRowValues[rowIndex]
                                        );
                                        setShow(true);
                                      }}
                                    />
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip">
                                        Add Transaction
                                      </Tooltip>
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faPlusSquare}
                                      onClick={() => {
                                        setCommonTransactionData(rowValues);
                                        setAddTransactnModel(true);
                                        setTableRowIndex(rowIndex);
                                      }}
                                    />
                                  </OverlayTrigger>
                                </div>
                              )}
                              {cellValue}
                            </td>
                          );
                        }
                      }
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

      {serviceType === "Bank Statement" && editableTransactionData && (
        <EditTransactionModal
          transactionData={editableTransactionData}
          show={show}
          setShow={setShow}
          bankSttTableData={bankSttTableData}
          setBankSttTableData={setBankSttTableData}
          tableAccNo={tableAccNo}
        />
      )}
      {serviceType === "Bank Statement" && commonTransactionData && (
        <AddMissingTransaction
          transactionData={commonTransactionData}
          show={addTransactnModel}
          setShow={setAddTransactnModel}
          bankSttTableData={bankSttTableData}
          setBankSttTableData={setBankSttTableData}
          tableAccNo={tableAccNo}
          rowIndex={tableRowIndex}
        />
      )}
    </React.Fragment>
  );
};

export default BankStatementTable;
