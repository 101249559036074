import { faCalendarDays, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Row, Col, DropdownButton, Dropdown } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";

const DateSelector = ({ placeholder }) => {
  return (
    <DatePicker
      render={(value, openCalendar) => (
        <div
          style={{
            position: "relative",
            display: "inline-block",
            width: "100%",
          }}
        >
          <input
            type="text"
            value={value}
            readOnly
            onClick={openCalendar}
            placeholder={placeholder}
            style={{
              padding: "5px 30px 5px 12px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
          <FontAwesomeIcon
            icon={faCalendarDays}
            onClick={openCalendar}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
          />
        </div>
      )}
    />
  );
};

const AnalysisFilter = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [currentAccNo, setCurrentAccNo] = useState("Choose Account Number");
  const [filterBy, setFilterBy] = useState("Monthly");

  return (
    <Row className="d-flex justify-content-between align-items-center m-0 analysis-filter w-100">
      <Col
        className="d-flex align-items-center p-0 flex-wrap"
      >
        <Col className="d-flex analysis-btn" xs={4}>
          <Button
            className={filterBy === "Monthly" && "active"}
            onClick={() => setFilterBy("Monthly")}
          >
            Monthly
          </Button>
          <Button
            className={filterBy === "Weekly" && "active"}
            onClick={() => setFilterBy("Weekly")}
          >
            Weekly
          </Button>
          <Button
            className={filterBy === "Daily" && "active"}
            onClick={() => setFilterBy("Daily")}
          >
            Daily
          </Button>
        </Col>
        <Col className="d-flex align-items-center dates-analysis ml-4">
          <DateSelector
            placeholder="Choose Start Date"
            value={startDate}
            onChange={setStartDate}
          />
          <DateSelector
            placeholder="Choose End Date"
            value={endDate}
            onChange={setEndDate}
          />
          <Button className="filterBtn">
            <FontAwesomeIcon icon={faFilter} /> Filter
          </Button>
        </Col>
      </Col>
      <Col xs={3} className="d-flex justify-content-end p-0">
        <DropdownButton
          title={currentAccNo}
          drop="down"
          onSelect={(eventKey) => {
            setCurrentAccNo(eventKey);
          }}
        >
          <Dropdown.Item eventKey="Acc No. 1">Acc No. 1</Dropdown.Item>
          <Dropdown.Item eventKey="Acc No. 2">Acc No. 2</Dropdown.Item>
        </DropdownButton>
      </Col>
    </Row>
  );
};

export default AnalysisFilter;
