import React from "react";
import "./ChartContainer.css";
import { Modal } from "react-bootstrap";
import { CustomButton } from "../../../InferIq-Solution/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const ChartContainer = ({ children, modalChart, setLgShow, lgShow, title }) => {
  return (
    <div style={{ padding: "4px" }}>
      <div className="chart_container_chart">{children}</div>
      {lgShow && (
        <Modal
          size="lg"
          backdrop="static"
          show={lgShow}
          onHide={() => setLgShow(false)}
        >
          <div
            style={{
              display: "flex",
              padding: "5px",
              margin: "5px 5px",
              borderRadius: "5px",
              justifyContent: "space-between",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 2px",
            }}
          >
            <div className="heading">{title}</div>
            <CustomButton
              onClick={() => setLgShow(false)}
              size="sm"
              variant="light"
            >
              <FontAwesomeIcon icon={faXmark} />
            </CustomButton>
          </div>

          <div
            style={{
              margin: "2px 5px",
              borderRadius: "5px",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 2px",
            }}
          >
            {modalChart}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ChartContainer;
