import React, { useContext } from "react";
import ParentContext from "../../InferIq-Solution/context/context";
import "./ClientOnboarding.css";
import { CustomButton } from "../../InferIq-Solution/components";
import { inputList, envdropDownList } from "./utils/InputData";
import { Tabs, Tab, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faClone,
} from "@fortawesome/free-solid-svg-icons";
import ClientForm from "./components/ClientForm/ClientForm";
import GetClientInfo from "./components/GetClientInfo/GetClientInfo";

const ClientOnboarding = () => {
  const { state, dispatch } = useContext(ParentContext);
  const {
    clientOnboardingActiveTab,
    checkBoxServicesValues,
    checkBoxllmsValues,
    checkBoxEnvValues,
  } = state;

  const handleTabSelect = (selected) => {
    dispatch({ type: "SET_Client_ONBOARDING_ACTIVE_TAB", payload: selected });
  };
  const handleCompanyName = (e) => {
    dispatch({ type: "SET_CLIENT_COMPANY_NAME", payload: e.target.value });
  };
  const handleServicesCheckBoxChange = (value) => {
    if (checkBoxServicesValues.includes(value)) {
      dispatch({
        type: "SET_CHECK_BOX_SERVICES_SELECTED_VALUES",
        payload: checkBoxServicesValues.filter((val) => val !== value),
      });
    } else {
      dispatch({
        type: "SET_CHECK_BOX_SERVICES_SELECTED_VALUES",
        payload: [...checkBoxServicesValues, value],
      });
    }
  };
  const handleLlmsCheckBoxChange = (value) => {
    if (checkBoxllmsValues.includes(value)) {
      dispatch({
        type: "SET_CHECK_BOX_LLMS_SELECTED_VALUES",
        payload: checkBoxllmsValues.filter((val) => val !== value),
      });
    } else {
      dispatch({
        type: "SET_CHECK_BOX_LLMS_SELECTED_VALUES",
        payload: [...checkBoxllmsValues, value],
      });
    }
  };
  const handleEnvCheckboxChange = (value) => {
    if (checkBoxEnvValues.includes(value)) {
      dispatch({
        type: "SET_CHECK_BOX_ENV_SELECTED_VALUES",
        payload: checkBoxEnvValues.filter((val) => val !== value),
      });
    } else {
      dispatch({
        type: "SET_CHECK_BOX_ENV_SELECTED_VALUES",
        payload: [...checkBoxEnvValues, value],
      });
    }
  };

  const checkList = [
    {
      id: 1,
      title: "Services",
      selectTitle: "Selected Services",
      selectedValues: checkBoxServicesValues,
      handleChange: handleServicesCheckBoxChange,
      checkboxes: [
        { value: "Extraction" },
        { value: "Predictive Analytics" },
        { value: "Commercial Real Estate" },
        { value: "Redaction" },
        { value: "Generative AI" },
        { value: "Recognition" },
      ],
    },
    {
      id: 2,
      title: "LLM's",
      selectTitle: "Selected LLM's",
      selectedValues: checkBoxllmsValues,
      handleChange: handleLlmsCheckBoxChange,
      checkboxes: [
        { value: "Claude" },
        { value: "Gpt3" },
        { value: "Mistrel" },
      ],
    },
    {
      id: 3,
      title: "Environment",
      selectTitle: "Selected Environment",
      selectedValues: checkBoxEnvValues,
      handleChange: handleEnvCheckboxChange,
      checkboxes: [
        { value: "Development" },
        { value: "Test" },
        { value: "UAT" },
        { value: "Demo" },
        { value: "Production" },
      ],
    },
  ];

  const copyModels = {
    avalibleResources: [
      {
        id: 1,
        title: "Avalible Services",
        selectedValues: [
          { value: "Extraction" },
          { value: "Predictive Analytics" },
          { value: "Commercial Real Estate" },
          { value: "Redaction" },
          { value: "Generative AI" },
          { value: "Recognition" },
        ],
      },
      {
        id: 2,
        title: "Avalible LLM's",
        selectedValues: [
          { value: "Claude" },
          { value: "Gpt3" },
          { value: "Mistrel" },
        ],
      },
      {
        id: 3,
        title: "Avalible Environment",
        selectedValues: [
          { value: "Development" },
          { value: "Test" },
          { value: "UAT" },
          { value: "Demo" },
          { value: "Production" },
        ],
      },
    ],
    optedResources: [
      {
        id: 1,
        title: "Opted Services",
        selectedValues: [
          { value: "Extraction" },
          { value: "Predictive Analytics" },
          { value: "Commercial Real Estate" },
        ],
      },
      {
        id: 2,
        title: "Opted LLM's",
        selectedValues: [{ value: "Claude" }, { value: "Gpt3" }],
      },
      {
        id: 3,
        title: "Opted Environment",
        selectedValues: [
          { value: "Development" },
          { value: "Demo" },
          { value: "Production" },
        ],
      },
    ],
  };

  return (
    <div className="tabs-container">
      <Tabs
        id="justify-tab-example"
        className="mb-3"
        justify
        activeKey={clientOnboardingActiveTab}
        onSelect={handleTabSelect}
      >
        <Tab
          eventKey="Client Onboarding"
          title={
            <span className="tab-title">
              <FontAwesomeIcon icon={faBuilding} />
              Client Onboarding
            </span>
          }
        >
          <ClientForm
            inputList={inputList}
            checkList={checkList}
            handleCompanyName={handleCompanyName}
          />
        </Tab>
        <Tab
          eventKey="Copy Models"
          title={
            <span className="tab-title">
              <FontAwesomeIcon icon={faClone} />
              Copy Models
            </span>
          }
        >
          <GetClientInfo/>
          <div
            style={{
              margin: "3px",
              padding: "5px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CustomButton size="sm" variant="primary">
              Get Client Info
            </CustomButton>
          </div>
          <div style={{ margin: "3px", padding: "5px" }}>
            {envdropDownList.map((item) => {
              const { id, label, name, required, value } = item;
              return (
                <div
                  key={id}
                  className="pa-form-input-cont"
                  style={{ width: "100%", marginBottom: "60px" }}
                >
                  <label for="input" className="pa-form-input-label">
                    {label}
                  </label>

                  <select
                    className="pa-form-input itemselect"
                    name={name}
                    // value={formData?.name}
                    // onChange={handleInputChange}
                    required={required}
                  >
                    {value.map((item) => {
                      const { id, selected, hidden, disabled, label, value } =
                        item;
                      return (
                        <option
                          key={id}
                          value={value}
                          selected={selected}
                          hidden={hidden}
                          disabled={disabled}
                        >
                          {label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "48%" }}>
              {copyModels.avalibleResources.map((item) => (
                <div
                  key={item.id}
                  style={{
                    border: "2px solid #ccc",
                    borderRadius: "5px",
                    padding: "10px",
                    marginBottom: "15px",
                    width: "100%",
                    height: "35%",
                  }}
                >
                  <span className="para">{item.title}</span>
                  <hr />
                  <div>
                    {item.selectedValues.map((item) => (
                      <div key={item.value}>
                        <Badge bg="secondary">{item.value}</Badge>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div style={{ width: "48%" }}>
              {copyModels.optedResources.map((item) => (
                <div
                  key={item.id}
                  style={{
                    border: "2px solid #ccc",
                    borderRadius: "5px",
                    padding: "10px",
                    marginBottom: "15px",
                    width: "100%",
                    height: "35%",
                  }}
                >
                  <span className="para">{item.title}</span>
                  <hr />
                  <div>
                    {item.selectedValues.map((item) => (
                      <div key={item.value}>
                        <Badge bg="primary">{item.value}</Badge>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ClientOnboarding;
