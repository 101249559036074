import DatePicker from "react-multi-date-picker";
import DatePickerHeader from "react-multi-date-picker/plugins/date_picker_header";
import transition from "react-element-popper/animations/transition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faCalendarDays } from "@fortawesome/free-solid-svg-icons";

const SubmitPlugin = ({ filterDates }) => {
  const [startDate, endDate] = filterDates;
  if (startDate === "" && endDate === "") {
    return <p>Please select the dates</p>;
  }

  return (
    <div
      style={{
        display: "flex",
        padding: "5px",
        gap: "5px",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span className="para">
        {startDate.isValid && (
          <span
            style={{
              border: "1px solid transperent",
              borderRadius: "3px",
              padding: "3px 5px",
              backgroundColor: "#102447",
              color: "#fff",
            }}
          >
            {startDate.isValid && startDate.format()}
          </span>
        )}
        {endDate.isValid && (
          <>
            <span> -To- </span>
            <span
              style={{
                border: "1px solid transperent",
                borderRadius: "3px",
                padding: "3px",
                backgroundColor: "#102447",
                color: "#fff",
              }}
            >
              {endDate.isValid && endDate.format()}
            </span>
          </>
        )}
      </span>

      {startDate.isValid && (
        <span>
          Click Above <FontAwesomeIcon icon={faArrowUp} />
          Button
        </span>
      )}
    </div>
  );
};

const DateRangePicker = ({
  filterDates,

  handleDateChange,
  dateFormat,
  minDate,
  maxDate,
  placeholder,
}) => {
  const handleChange = (dates) => {
    if (dates && dates.length === 2) {
      handleDateChange(dates);
    }
  };

  return (
    <div className="range-date-picker para">
      <FontAwesomeIcon icon={faCalendarDays} />
      <DatePicker
        placeholder={placeholder}
        range
        calendarPosition="bottom-left"
        fixMainPosition
        dateSeparator=" -To- "
        value={filterDates}
        onChange={handleChange}
        format={dateFormat}
        minDate={minDate}
        maxDate={maxDate}
        sort
        rangeHover
        numberOfMonths={1}
        animations={[transition({ duration: 700, from: 35 })]}
        plugins={[
          <DatePickerHeader
            position="right"
            size="small"
            style={{ backgroundColor: "rgb(16,31,86)" }}
          />,
          <SubmitPlugin position="bottom" filterDates={filterDates} />,
        ]}
      />
    </div>
  );
};

export default DateRangePicker;
