import { Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import AnomalyDetectionGraphs from "../AnomalyDetection/AnomalyDetectionGraphs";
import { useMemo } from "react";

const CompareGraph = (props) => {
  const {
    chartFirstData,
    chartSecondData,
    firstVisual,
    setFirstVisual,
    secondVisual,
    setSecondVisual,
  } = props;
  const GRAPH_TYPES = ["Income", "Expense", "Balance"];

  const LEFT_COMPARE_GRAPH = useMemo(() => {
    const DATASET = chartFirstData.datasets.filter((data) => {
      return data.label === firstVisual;
    });
    return {
      ...chartFirstData,
      datasets: DATASET,
    };
  }, [chartFirstData, firstVisual]);

  const RIGHT_COMPARE_GRAPH = useMemo(() => {
    const DATASET = chartSecondData.datasets.filter((data) => {
      return data.label === secondVisual;
    });
    return {
      ...chartSecondData,
      datasets: DATASET,
    };
  }, [chartSecondData, secondVisual]);

  const accounts = ["1234/BANK/7890", "0987/BANK/1234"];

  return (
    <>
      <Col className="p-2 pt-0 graph-options" style={{height: '80vh'}}>
        <Row className="d-flex justify-content-end">
          <DropdownButton
            title={firstVisual}
            drop="down"
            onSelect={(eventKey) => {
              setFirstVisual(eventKey);
              setSecondVisual(eventKey);
            }}
            style={{ fontSize: "12px", width: 'auto' }}
          >
            {GRAPH_TYPES.map((chart, id) => {
              return (
                <Dropdown.Item
                  key={id}
                  style={{ fontSize: "12px" }}
                  eventKey={chart}
                >
                  {chart}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </Row>
        <Row>
          {[1, 2].map((graphNumber, id) => {
            return (
              <Col className="graph-column" key={id}>
                <Row className="d-flex justify-content-end m-0 p-0">
                  <h5 className="w-auto p-2 text-white text-center rounded m-0 account">
                    {accounts[id]}
                  </h5>
                </Row>
                <AnomalyDetectionGraphs
                  key={id}
                  type="Line"
                  data={
                    graphNumber === 1 ? LEFT_COMPARE_GRAPH : RIGHT_COMPARE_GRAPH
                  }
                />
              </Col>
            );
          })}
        </Row>
      </Col>
    </>
  );
};

export default CompareGraph;
