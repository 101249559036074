function calculateOverallTime(time) {
  const { hours, seconds, minutes } = time;
  const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
  return totalMilliseconds;
}

function convertMilliseconds(ms) {
  const hours = Math.floor(ms / (1000 * 60 * 60));
  const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((ms % (1000 * 60)) / 1000);

  return { hours, minutes, seconds };
}

const BStransactions = [
  {
    id: 0,
    fileName: "michael cirrito - sbna-2021-11547 stmts acct ending 111",
    inTime: "2024-10-04 1:57:40",
    status: "Processing",
    developer: "Developer 1",
    outTime: "",
    addressIn: { hours: 0, minutes: 5, seconds: 0 },
    waitTime: { hours: 0, minutes: 0, seconds: 0 },
    s: "",
  },
  {
    id: 1,
    fileName: "michael cirrito - sbna-2021-11547 stmts acct ending 222",
    inTime: "2024-10-05 2:57:40",
    status: "Processing",
    developer: "Developer 2",
    outTime: "",
    addressIn: { hours: 0, minutes: 0, seconds: 0 },
    waitTime: { hours: 0, minutes: 0, seconds: 0 },
    s: "",
  },
  {
    id: 2,
    fileName: "michael cirrito - sbna-2021-11547 stmts acct ending 333",
    inTime: "2024-10-06 3:57:40",
    status: "Processing",
    developer: "Developer 3",
    outTime: "",
    addressIn: { hours: 0, minutes: 0, seconds: 0 },
    waitTime: { hours: 0, minutes: 0, seconds: 0 },
    s: "",
  },
  {
    id: 3,
    fileName: "michael cirrito - sbna-2021-11547 stmts acct ending 444",
    inTime: "2024-10-04 4:57:40",
    status: "Processing",
    developer: "Developer 4",
    outTime: "",
    addressIn: { hours: 0, minutes: 0, seconds: 0 },
    waitTime: { hours: 0, minutes: 0, seconds: 0 },
    s: "",
  },
  {
    id: 4,
    fileName: "michael cirrito - sbna-2021-11547 stmts acct ending 555",
    inTime: "2024-10-04 5:57:40",
    status: "Processing",
    developer: "Developer 5",
    outTime: "",
    addressIn: { hours: 0, minutes: 0, seconds: 0 },
    waitTime: { hours: 0, minutes: 0, seconds: 0 },
    s: "",
  },
  {
    id: 5,
    fileName: "michael cirrito - sbna-2021-11547 stmts acct ending 666",
    inTime: "2024-10-04 6:57:40",
    status: "Processing",
    developer: "Developer 6",
    outTime: "",
    addressIn: { hours: 0, minutes: 0, seconds: 0 },
    waitTime: { hours: 0, minutes: 0, seconds: 0 },
    s: "",
  },
  {
    id: 6,
    fileName: "michael cirrito - sbna-2021-11547 stmts acct ending 777",
    inTime: "2024-10-04 7:57:40",
    status: "Processing",
    developer: "Developer 7",
    outTime: "",
    addressIn: { hours: 0, minutes: 0, seconds: 0 },
    waitTime: { hours: 0, minutes: 0, seconds: 0 },
    s: "",
  },
  {
    id: 7,
    fileName: "michael cirrito - sbna-2021-11547 stmts acct ending 888",
    inTime: "2024-10-04 7:57:40",
    status: "Processing",
    developer: "Developer 8",
    outTime: "",
    addressIn: { hours: 0, minutes: 0, seconds: 0 },
    waitTime: { hours: 0, minutes: 0, seconds: 0 },
    s: "",
  },
  {
    id: 8,
    fileName: "michael cirrito - sbna-2021-11547 stmts acct ending 999",
    inTime: "2024-10-04 9:57:40",
    status: "Processing",
    developer: "Developer 9",
    outTime: "",
    addressIn: { hours: 0, minutes: 0, seconds: 0 },
    waitTime: { hours: 0, minutes: 0, seconds: 0 },
    s: "",
  },
  {
    id: 9,
    fileName: "michael cirrito - sbna-2021-11547 stmts acct ending 1010",
    inTime: "2024-10-04 10:57:40",
    status: "Processing",
    developer: "Developer 10",
    outTime: "",
    addressIn: { hours: 0, minutes: 0, seconds: 0 },
    waitTime: { hours: 0, minutes: 0, seconds: 0 },
    s: "",
  },
  {
    id: 10,
    fileName: "michael cirrito - sbna-2021-11547 stmts acct ending 1111",
    inTime: "2024-10-04 11:57:40",
    status: "Processing",
    developer: "Developer 11",
    outTime: "",
    addressIn: { hours: 0, minutes: 0, seconds: 0 },
    waitTime: { hours: 0, minutes: 0, seconds: 0 },
    s: "",
  },
  {
    id: 11,
    fileName: "michael cirrito - sbna-2021-11547 stmts acct ending 1212",
    inTime: "2024-10-04 12:57:40",
    status: "Processing",
    developer: "Developer 12",
    outTime: "",
    addressIn: { hours: 0, minutes: 0, seconds: 0 },
    waitTime: { hours: 0, minutes: 0, seconds: 0 },
    s: "",
  },
];
export { calculateOverallTime, convertMilliseconds, BStransactions };
