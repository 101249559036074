const ModulesList = [
  {
    category: "Extraction",
    items: [
      {
        id: 1,
        label: "Legal docs and Contracts",
        check: false,
      },
      {
        id: 2,
        label: "IRS Forms",
        check: false,
      },
      {
        id: 3,
        label: "Plain Text",
        check: false,
      },
      {
        id: 4,
        label: "Tax Form",
        check: false,
      },
      {
        id: 5,
        label: "Property Cash Flow",
        check: false,
      },
      {
        id: 6,
        label: "Invoice & Receipts",
        check: false,
      },
      {
        id: 7,
        label: "Rent Roll Extraction",
        check: false,
      },
      {
        id: 8,
        label: "Application Form ",
        check: false,
      },
      {
        id: 9,
        label: "Bank Form",
        check: false,
      },
    ],
  },
  {
    category: "Bank Statement",
    items: [
      {
        id: 10,
        label: "Credit Card Statement",
        check: false,
      },
      {
        id: 11,
        label: "Bank Statement",
        check: false,
      },
    ],
  },
  {
    category: "FinStat Extraction",
    items: [
      {
        id: 12,
        label: "Financial Statement",
        check: false,
      },
    ],
  },
  {
    category: "Conversational AI",
    items: [
      {
        id: 13,
        label: "Financial Statement ",
        check: false,
      },
      {
        id: 14,
        label: "Bank Forms",
        check: false,
      },
      {
        id: 15,
        label: "Bank Statement",
        check: false,
      },
      {
        id: 16,
        label: "Legal docs & Contracts",
        check: false,
      },
      {
        id: 17,
        label: "Invoice & Receipts",
        check: false,
      },
    ],
  },
  {
    category: "Rent Roll",
    items: [
      {
        id: 18,
        label: "Rent Roll",
        check: false,
      },
    ],
  },
  {
    category: "Forecasting ",
    items: [
      {
        id: 19,
        label: "Loan Default",
        check: false,
      },
    ],
  },
  {
    category: "Redaction ",
    items: [
      {
        id: 20,
        label: "Plain Text ",
        check: false,
      },
      {
        id: 21,
        label: "Forms",
        check: false,
      },
    ],
  },
  {
    category: "Recognition",
    items: [
      {
        id: 22,
        label: "Document Understanding",
        check: false,
      },
    ],
  },
  {
    category: "Classification",
    items: [
      {
        id: 23,
        label: "Generic Document Classifer",
        check: false,
      },
    ],
  },
];
export const organizations = [
  { id: 1, label: "Google", value: "google" },
  { id: 2, label: "Microsoft", value: "microsoft" },
  { id: 3, label: "Apple", value: "apple" },
  { id: 4, label: "Amazon", value: "amazon" },
  { id: 5, label: "Meta", value: "meta" },
];

export default ModulesList;
