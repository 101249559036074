import React, { Fragment, useContext } from "react";
import ParentContext from "../../../../InferIq-Solution/context/context";
import useClientOnboarding from "../../../Hooks/useClientOnboarding";
import CheckBoxList from "../CheckBoxList/CheckBoxList";
import { CustomButton } from "../../../../InferIq-Solution/components";

const ClientForm = ({ inputList, checkList, handleCompanyName }) => {
  const { state } = useContext(ParentContext);
  const { clientCompanyName } = state;
  const { fetchClientOnboardingData } = useClientOnboarding();
  return (
    <div className="client-onboarding-container">
      <form onSubmit={fetchClientOnboardingData}>
        <div className="input-cont">
          {inputList.map((item) => {
            const {
              id,
              label,
              type,
              name,
              placeholder,
              title,
              pattern,
              required,
              min,
              minLength,
              maxLength,
              max,
            } = item;
            return (
              <div key={id} className="pa-form-input-cont">
                <label for="input" className="pa-form-input-label">
                  {label}
                </label>
                <input
                  type={type}
                  name={name}
                  value={clientCompanyName.name}
                  placeholder={placeholder}
                  onChange={handleCompanyName}
                  className="pa-form-input"
                  title={title}
                  pattern={pattern}
                  required={required}
                  min={min}
                  minLength={minLength}
                  maxLength={maxLength}
                  max={max}
                />
              </div>
            );
          })}
        </div>
        <Fragment>
          {checkList.map((item) => (
            <span key={item.id}>
              <CheckBoxList
                title={item.title}
                selectTitle={item.selectTitle}
                options={item.checkboxes}
                selectedValues={item.selectedValues}
                handleChange={item.handleChange}
              />
            </span>
          ))}
        </Fragment>
        <div className="sunmit-btn-cont">
          <CustomButton size="md" variant="primary" type="submit">
            Submit
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default ClientForm;
