import React, {
  useReducer,
  useEffect,
  useState,
  useMemo,
  memo,
  Fragment,
  useRef,
} from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import ParentContext from "./context/context";
import ErrorBoundary from "./Error/ErrorBoundary";
import reducer from "./Reducer/reducer";
import initialState from "./Reducer/initialState";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import options from "./utils/options.js";
import Layout from "./Layout/Layout.jsx";
import LoginPage from "./pages/LoginPage/LoginPage.jsx";
import AutoAppLogout from "./components/AutoAppLogout";
import PrivateRoute from "./PrivateRoute.jsx";
import { token, login_status } from "./Auth/authConfig.js";
import { PageNotFound, TermsAndConditions } from "./pages/index.js";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "./Templates.jsx";
import AuthFallback from "./components/AuthFallback/AuthFallback.jsx";

const currentPath = window.location.pathname;

const findActiveRoute = options.find((option) =>
  option.validateRoutes.includes(currentPath)
);

const FallBackLoader = () => {
  return (
    <div className="container-loader3">
      <div className="loading">
        <div className="ball one"></div>
        <div className="ball two"></div>
        <div className="ball three"></div>
        <div className="ball four"></div>
      </div>
    </div>
  );
};

const MainContent = (props) => {
  const [authFailModalShow, setAuthFailModalShow] = useState(false);
  const { Components, loggedIn } = props;
  const renderAuthFailRef = useRef(false);
  useEffect(() => {
    const handleNavigation = () => {
      window.location.reload();
    };
    window.addEventListener("popstate", handleNavigation);
    return () => {
      window.removeEventListener("popstate", handleNavigation);
    };
  }, []);

  const navigate = useNavigate();
  if (!token && login_status === false) return navigate("/");

  const checkForAuthFail = Object.keys(props?.backendResponse);
  if (!renderAuthFailRef?.current) {
    if (
      checkForAuthFail?.includes("status") &&
      checkForAuthFail?.includes("statusCode") &&
      props?.backendResponse?.statusCode === 401
    ) {
      setAuthFailModalShow(true);
    }
    renderAuthFailRef.current = true;
  }

  return (
    <ErrorBoundary>
      <Layout>
        <Routes>
          {Components
            ? Components.map(({ path, component, exact }) => {
                return (
                  <Fragment key={path}>
                    <Route
                      exact={exact}
                      path={path}
                      element={
                        <PrivateRoute isAuthenticated={loggedIn}>
                          {component}
                        </PrivateRoute>
                      }
                    />
                  </Fragment>
                );
              })
            : ""}
        </Routes>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <AuthFallback
          show={authFailModalShow}
          onHide={() => setAuthFailModalShow(false)}
        />
      </Layout>
    </ErrorBoundary>
  );
};

const InferIq = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState();
  const [currentItem, setCurrentItem] = useState(findActiveRoute?.path);

  const { backendResponse } = state;

  const ContextValues = useMemo(() => {
    return {
      state,
      loggedIn,
      dispatch,
      setLoggedIn,
      currentItem,
      setCurrentItem,
    };
  }, [state, dispatch,currentItem]);

  useEffect(() => {
    if (token && login_status === true) {
      setLoggedIn(true);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <FallBackLoader />;
  }

  return (
    <BrowserRouter>
      <ParentContext.Provider value={ContextValues}>
        <AuthenticatedTemplate isAuthenticated={loggedIn}>
          <AutoAppLogout>
            <MainContent
              Components={props.Routes}
              loading={loading}
              loggedIn={loggedIn}
              backendResponse={backendResponse}
            />
          </AutoAppLogout>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate isAuthenticated={loggedIn}>
          <Routes>
            <Route exact={true} path="/" element={<LoginPage />} />
            <Route
              path="/termsAndConditions"
              element={<TermsAndConditions />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </UnauthenticatedTemplate>
      </ParentContext.Provider>
    </BrowserRouter>
  );
};

export default memo(InferIq);
