import React, { useCallback, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { InputGroups } from "../../../../components/SelectionElements/SelectionElement";
import { CustomButton } from "../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./TemplateModal.css";

function AddTemplateModal({
  ModalHeading,
  show,
  setShow,
  save,
  editableContent,
  setEditableContent,
  addIcon,
}) {
  const [isExiting, setIsExiting] = useState(false);

  const isFormValid = () => {
    return (
      editableContent.label &&
      editableContent.questions.length > 0 &&
      editableContent.questions.every((q) => q.question)
    );
  };

  const addNewQuestion = useCallback(() => {
    const newQuestion = { id: Date.now(), question: "" };
    setEditableContent((prev) => ({
      ...prev,
      questions: [...prev.questions, newQuestion],
    }));
  }, [setEditableContent]);

  const handleQuestionChange = useCallback(
    (index, value) => {
      const updatedQuestions = editableContent.questions.map((q, i) =>
        i === index ? { ...q, question: value } : q
      );
      setEditableContent({ ...editableContent, questions: updatedQuestions });
    },
    [editableContent, setEditableContent]
  );

  const handleClose = useCallback(() => {
    setIsExiting(true);
    setTimeout(() => {
      setShow(false);
      setIsExiting(false);
    }, 300);
  }, [setShow]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      // Validate form fields

      save();
      handleClose();
    },
    [handleClose, save]
  );

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className={isExiting ? "fade-out" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title>{ModalHeading}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <InputGroups
          className="mb-3 equal-label-width"
          label="Label"
          placeholder="Enter New Label..."
          value={editableContent.label || ""}
          onChange={(e) =>
            setEditableContent({ ...editableContent, label: e.target.value })
          }
        />

        {editableContent.questions.map((question, index) => (
          <InputGroups
            key={question.id}
            className="mb-3 equal-label-width"
            label={`Question ${index + 1}`}
            placeholder="Enter New Question..."
            value={question.question || ""}
            onChange={(e) => handleQuestionChange(index, e.target.value)}
          />
        ))}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <div>
          <CustomButton
            variant="primary"
            onClick={addNewQuestion}
            disabled={!isFormValid()}
          >
            <FontAwesomeIcon icon={addIcon} />
            <span className="ms-2">Add Questions</span>
          </CustomButton>
        </div>
        <div className="d-flex">
          <CustomButton variant="primary" onClick={handleClose}>
            Close
          </CustomButton>
          <CustomButton
            variant="primary"
            type="submit"
            style={{ marginLeft: "2px" }}
            disabled={!isFormValid()}
            onClick={handleSubmit}
          >
            Save
          </CustomButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default AddTemplateModal;
