import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ChartContainer from "../ChartContainer/ChartContainer";
import "./TabsContainer.css";
import { CustomButton } from "../../../InferIq-Solution/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMaximize } from "@fortawesome/free-solid-svg-icons";

const TabsContainer = ({
  title,
  chartOne,
  chartTwo,
  chartOneTitle,
  chartTwoTitle,
  modalChartOne,
  modalChartTwo,
}) => {
  const [showChart1, setShowChart1] = useState(true);
  const [showChart2, setShowChart2] = useState(false);
  const [lgShow, setLgShow] = useState(false);

  const handleChart1 = () => {
    setShowChart1(true);
    setShowChart2(false);
  };
  const handleChart2 = () => {
    setShowChart2(true);
    setShowChart1(false);
  };
  return (
    <div className="tabs_container">
      <div
        style={{
          display: "flex",
          borderRadius: "5px",
          padding: "4px 8px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <OverlayTrigger placement="top" overlay={<Tooltip>{title}</Tooltip>}>
          <div className="para" style={{ width: "60%", fontWeight: "500" }}>
            {title && title.slice("").length > 25
              ? title?.slice(0, 25) + "..."
              : title}
          </div>
        </OverlayTrigger>
        {chartOne && chartTwo && chartOneTitle && chartTwoTitle && (
          <div
            style={{
              width: "35%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CustomButton onClick={handleChart1} size="sm" variant="light">
              {chartOneTitle}
            </CustomButton>
            <CustomButton onClick={handleChart2} size="sm" variant="light">
              {chartTwoTitle}
            </CustomButton>
          </div>
        )}
        <div style={{ width: "5%", marginLeft: "10px" }}>
          <CustomButton
            onClick={() => setLgShow(true)}
            size="sm"
            variant="light"
          >
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Maximize</Tooltip>}
            >
              <FontAwesomeIcon icon={faMaximize} />
            </OverlayTrigger>
          </CustomButton>
        </div>
      </div>
      <div>
        {showChart1 && (
          <ChartContainer
            lgShow={lgShow}
            setLgShow={setLgShow}
            modalChart={modalChartOne}
            title={title}
          >
            {chartOne}
          </ChartContainer>
        )}
        {showChart2 && (
          <ChartContainer
            lgShow={lgShow}
            setLgShow={setLgShow}
            modalChart={modalChartTwo}
            title={title}
          >
            {chartTwo}
          </ChartContainer>
        )}
      </div>
    </div>
  );
};

export default TabsContainer;
