import { Col, Tab, Tabs } from "react-bootstrap";
import TimeSeries from "./components/TimeSeries/TimeSeries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClockRotateLeft,
  faCodeCompare,
  faCompass,
  faGear,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import AnomalyDetection from "./components/AnomalyDetection/AnomalyDetection";
import { useState } from "react";
import AccountComparison from "./components/AccountComparison/AccountComparison";
import "./components/AnalysisFilter/AnalysisFilter.css";
import "./components/AnalysisFilter/AnalysisFilter.css";
import CategorizationSeries from "./components/CategorizationSeries/CategorizationSeries";
import CustomCharts from "./components/Customization/CustomCharts";

const BankStatementsAnalysis = () => {
  const [activeTab, setActiveTab] = useState("Time Series Analysis");

  return (
    <Col className="analysis">
      <Tabs
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
        id="justify-tab-example"
        className="mb-3"
        justify
      >
        <Tab
          eventKey="Time Series Analysis"
          title={
            <span className="d-flex align-items-center justify-content-center">
              <FontAwesomeIcon
                icon={faClockRotateLeft}
                style={{ marginRight: "10px" }}
              />
              Time Series Analysis
            </span>
          }
        >
          {activeTab === "Time Series Analysis" && <TimeSeries />}
        </Tab>
        <Tab
          eventKey="Categorization"
          title={
            <span className="d-flex align-items-center justify-content-center">
              <FontAwesomeIcon
                icon={faLayerGroup}
                style={{ marginRight: "10px" }}
              />
              Categorization
            </span>
          }
        >
          {activeTab === "Categorization" && <CategorizationSeries />}
        </Tab>
        <Tab
          eventKey="Anomaly Detection"
          title={
            <span className="d-flex align-items-center justify-content-center">
              <FontAwesomeIcon
                icon={faCompass}
                style={{ marginRight: "10px" }}
              />
              Anomaly Detection
            </span>
          }
        >
          {activeTab === "Anomaly Detection" && <AnomalyDetection />}
        </Tab>
        <Tab
          eventKey="Account Comparison"
          title={
            <span className="d-flex align-items-center justify-content-center">
              <FontAwesomeIcon
                icon={faCodeCompare}
                style={{ marginRight: "10px" }}
              />
              Account Comparison
            </span>
          }
        >
          <AccountComparison />
        </Tab>

        <Tab
          eventKey="Custom Charts"
          title={
            <span className="d-flex align-items-center justify-content-center">
              <FontAwesomeIcon
                icon={faGear}
                style={{ marginRight: "10px" }}
              />
              Customization
            </span>
          }
        >
          <CustomCharts />
        </Tab>
      </Tabs>
    </Col>
  );
};

export default BankStatementsAnalysis;
