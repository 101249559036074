import  { useContext, useState } from "react";
import ParentContext from "../context/context";

const useStatus = () => {
  const { state } = useContext(ParentContext);
  const { status, serviceType, activeTab } = state;
  const [style, setStyle] = useState({});
  let parsedStatus = parseFloat(status);

  if (parsedStatus === 100) {
    parsedStatus = 5;
  }

  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${parsedStatus}%`,
    };

    setStyle(newStyle);
  }, 200);

  let statusMsgForServiceTypes = getStatusMsgForServiceTypes(
    serviceType,
    parsedStatus
  );
  let statusMsgForGenaiTabs = getStatusMsgForGenaiTabs(
    serviceType,
    activeTab,
    parsedStatus
  );
  let statusMsgForRedactionTab = getStatusMsgForRedactionTab(
    serviceType,
    parsedStatus
  );

  return {
    parsedStatus,
    style,
    statusMsgForServiceTypes,
    statusMsgForGenaiTabs,
    statusMsgForRedactionTab,
  };
};

const getStatusMsgForRedactionTab = (serviceType, parsedStatus) => {
  const statusMessages = {
    Redaction: [
      "Processing data...",
      "Processing data...",
      "Processing data...",
      "Processing data...",
      "Processing data...",
    ],
  };

  return statusMessages?.[serviceType]?.[getStatusIndex(parsedStatus)];
};
const getStatusMsgForServiceTypes = (serviceType, parsedStatus) => {
  const statusMessages = {
    Extraction: [
      "Initiating...",
      "Pre-Extraction data...",
      "Extracting data...",
      "Analyzing data...",
      "Generating response...",
    ],
    "Conversational AI": [
      "Initiating...",
      "Pre-Extraction data...",
      "Extracting data...",
      "Analyzing data...",
      "Generating response...",
    ],
    Redaction: [
      "Initiating...",
      "Processing data...",
      "Processing data...",
      "Analyzing data...",
      "Generating response...",
    ],
    Recognition: [
      "Initiating...",
      "Processing data...",
      "Processing data...",
      "Analyzing data...",
      "Generating response...",
    ],
    Classification: [
      "Initiating...",
      "Processing data...",
      "Processing data...",
      "Analyzing data...",
      "Generating response...",
    ],
  };

  return statusMessages?.[serviceType]?.[getStatusIndex(parsedStatus)];
};

const getStatusMsgForGenaiTabs = (serviceType, activeTab, parsedStatus) => {
  if (serviceType !== "Conversational AI") return "";

  const statusMessages = {
    redaction_settings: [
      "Processing data...",
      "Processing data...",
      "Processing data...",
      "Processing data...",
      "Processing data...",
    ],
    questions: [
      "Preparing input for the modal...",
      "Processing data...",
      "Processing data...",
      "Processing data...",
      "Generating output...",
    ],
  };

  return statusMessages?.[activeTab]?.[getStatusIndex(parsedStatus)];
};

const getStatusIndex = (parsedStatus) => {
  if (parsedStatus === 5) return 0;
  if (parsedStatus <= 25) return 1;
  if (parsedStatus <= 50) return 2;
  if (parsedStatus <= 75) return 3;
  return 4;
};

export default useStatus;
