import React, { memo, useCallback, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { CustomButton } from "../../../../components";
import {
  UserManagementRollOptions,
  UserManagementStatusOptions,
} from "../../../../utils/settings";
import { InputGroups } from "../../../../components/SelectionElements/SelectionElement";
import { Form } from "react-bootstrap";
import "./TemplateModal.css";

function UserManagementTemplateModal({
  ModalHeading,
  show,
  setShow,
  save,
  editableContent,

  handleChange,
  DataCheckID,
}) {
  const [isExiting, setIsExiting] = useState(false);

  const handleClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      setShow(false);
      setIsExiting(false);
    }, 300); // Duration of the animation
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    save();
    handleClose();
  };
function checkdate(){
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); 
  const day = String(today.getDate()).padStart(2, '0');
  const maxDate = `${year}-${month}-${day}`;
  return maxDate
}

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className={isExiting ? "fade-out" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title>{ModalHeading}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {/* User Management Modal Content */}
          {editableContent && DataCheckID && (
            <>
              <InputGroups
                className="mb-3 equal-label-width"
                placeholder="Enter New Name here..."
                label="Name"
                arialabel="First name"
                value={editableContent?.name}
                onChange={(e) =>
                  handleChange(DataCheckID, "name", e.target.value)
                }
                required={true}
              />
              <InputGroups
                className="mb-3 equal-label-width"
                placeholder="Enter New Roll here..."
                arialabel="Roll"
                label="Roll"
                value={editableContent?.roll}
                onChange={(e) =>
                  handleChange(DataCheckID, "roll", e.target.value)
                }
                required={true}
                type={"select"}
                options={UserManagementRollOptions}
              />
              <InputGroups
                className="mb-3 equal-label-width"
                placeholder="Enter Created at here..."
                arialabel="Created at"
                label="Created"
                value={editableContent.created}
                onChange={(e) => {
                  handleChange(DataCheckID, "created", e.target.value);
                }}
                required={true}
                type="date"
                max={checkdate()}
              />
              <InputGroups
                className="mb-3 equal-label-width"
                placeholder="Enter New Status here..."
                arialabel="Status"
                label="Status"
                value={editableContent?.Status}
                onChange={(e) =>
                  handleChange(DataCheckID, "Status", e.target.value)
                }
                required={true}
                type="select"
                options={UserManagementStatusOptions}
              />
            </>
          )}
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-between">
          <div></div>
          <div className="d-flex">
            <CustomButton variant="primary" type="button" onClick={handleClose}>
              Close
            </CustomButton>
            <CustomButton
              variant="primary"
              type="submit"
              style={{ marginLeft: "2px" }}
            >
              Save
            </CustomButton>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default memo(UserManagementTemplateModal);
