import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Col, Row } from "react-bootstrap"
import AnalysisFilter from "../AnalysisFilter/AnalysisFilter"

const AnomalyDetection = () => {

  const fileUploadDetection = [
    {
      label: 'Number of Abnormal Transactions',
      value: '23'
    },
    {
      label: 'Value of Abnormal Transactions',
      value: '$12,000'
    },
    {
      label: '% of Abnormal Transactions in the period',
      value: '1%'
    },
    {
      label: 'Overdraft Charges',
      value: '$298'
    },
    {
      label: 'Frequency of multiple Transactions to the same Vendor and Vendor name',
      value: '10'
    },
    {
      label: '% of Abnormal Transactions in the period',
      value: '30%'
    },
    {
      label: 'Overdraft Frequency',
      value: '2'
    },
    {
      label: 'Value of multiple Transactions to the same Vendor and Vendor name',
      value: '$10,000'
    },
    {
      label: '% of Unfamiliar monthly merchants in the period',
      value: '20%'
    }
  ]

  return (
    <Col className="d-flex flex-wrap detection">
      <AnalysisFilter />
      <Row className="d-flex justify-content-evenly flex-wrap analysis-main-wrap mx-0">
        {
          fileUploadDetection?.map((detect, id) => {
            return (
              <Col key={id} xs={3} className="m-3 each-box position-relative">
                <FontAwesomeIcon icon={faMagnifyingGlass} className="position-absolute fs-5 search" />
                <h1 className="text-center fw-bold fs-2">{detect?.value}</h1>
                <h4 className="text-center fw-semibold fs-6">{detect.label}</h4>
              </Col>
            )
          })
        }
      </Row>
    </Col>
  )
}

export default AnomalyDetection
