import React, { useState } from "react";
import "./Org.css";
import  { organizations } from "./utils/ModulesList";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
export const OrganiztionDropDown = () => {
  const [selectedOrg, setSelectedOrg] = useState("");

  const handleInputChange = (e) => {
    console.log(e.target.value)
    setSelectedOrg(e.target.value);
  };
  return (
    <div className="organization-select-box">
    <div className="pa-form-input-cont">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip className="para">{"Select Organization Name"}</Tooltip>}
      >
        <label htmlfor="input" className="pa-form-input-label" style={{}}>
          {"Organization Name"}
        </label>
      </OverlayTrigger>
      <select
        className="pa-form-input itemselect"
        value={selectedOrg}
        onChange={handleInputChange}
        required
      >
        {organizations.map((item) => {
          const { id, label, value } = item;
          return (
            <option key={id} value={value}>
              {label}
            </option>
          );
        })}
      </select>
    </div>
    </div>
  );
};
