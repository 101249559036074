import { useState } from "react";
import { Container, Form, Card } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./CustomCharts.module.css";
import { CustomButton } from "../../../../../components";
import AnomalyDetectionGraphs from "../AnomalyDetection/AnomalyDetectionGraphs";
import CustomCheckbox from "../../../../../components/SelectionElements/SelectionElement";
const checkboxOptions = [
  { label: "Time Series", id: "timeSeries", value: "Line" },
  { label: "Pie Chart", id: "pieChart", value: "Pie" },
  { label: "Parallel Bar", id: "polarArea", value: "Bar" },
];
const CustomCharts = () => {
  const [chartType, setChartType] = useState("Line");
  const [variables, setVariables] = useState([""]);
  const data = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Income",
        data: [115, 129, 130, 141, 156, 165],
        backgroundColor: ["rgba(255, 127, 80, 0.5)"], // Light coral
        borderColor: ["rgba(255, 69, 0, 1)"], // Dark coral
        borderWidth: 1,
      },
      {
        label: "Expenses",
        data: [175, 149, 190, 141, 126, 65],
        backgroundColor: ["rgba(135, 206, 235, 0.5)"], // Light blue
        borderColor: ["rgba(70, 130, 180, 1)"], // Steel blue
        borderWidth: 1,
      },
    ],
  };

  const addVariable = (e) => {
    e.preventDefault();
    setVariables([...variables, ""]);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    plugins: {
      legend: {
        position: "top",
        align: "center",
      },
    },
  };

  return (
    <Container fluid className={styles.dashboardContainer}>
      <div className={styles.dashboardGrid}>
        <Card className={styles.controlsPanel}>
          <Card.Body>
            <h4 className="mb-4">Chart Configuration</h4>
            <Form>
              <Form.Group className="mb-4">
                <Form.Label className="fw-bold">Chart Type</Form.Label>
                <div className={styles.chartTypeControls}>
                  {checkboxOptions.map((option) => (
                    <CustomCheckbox
                      key={option.id}
                      type="radio"
                      label={option.label}
                      name="chartType"
                      id={option.id}
                      checked={chartType === option.value}
                      onChange={() => setChartType(option.value)}
                    />
                  ))}
                </div>
              </Form.Group>

              <div className={styles.variablesSection}>
                {variables.map((_, index) => (
                  <Form.Group key={index} className="mb-3">
                    <Form.Label className={styles.variableLabel}>
                      Choose variable {index + 1}
                    </Form.Label>
                    <Form.Select className={styles.variableSelect}>
                      <option>Select</option>
                      <option value="1">Variable 1</option>
                      <option value="2">Variable 2</option>
                      <option value="3">Variable 3</option>
                    </Form.Select>
                  </Form.Group>
                ))}
              </div>

              <CustomButton
                variant="primary"
                onClick={addVariable}
                className={styles.addVariableBtn}
                // type={"submit"}
              >
                + Add variable
              </CustomButton>
            </Form>
          </Card.Body>
        </Card>

        <Card className={styles.chartPanel}>
          <Card.Body style={{ height: "430px" }}>
            <AnomalyDetectionGraphs
              type={chartType}
              data={data}
              options={options}
            />
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default CustomCharts;
