import { useState, useEffect } from "react";

const usePagination = (
  perPageItems,
  stages_data,
  currentPage,
  setCurrentPage
) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (!stages_data) return;

    const ITEMS_PER_PAGE = perPageItems;
    const totalItems = stages_data.length;
    setTotalPages(Math.ceil(totalItems / ITEMS_PER_PAGE));
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setCurrentItems(stages_data.slice(startIndex, endIndex));
  }, [perPageItems, stages_data, currentPage]); // Dependencies

  return {
    currentItems,
    currentPage,
    setCurrentPage,
    totalPages,
  };
};

export default usePagination;
