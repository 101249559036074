import React, {
  useCallback,
  useContext,
  useEffect,
  Fragment,
  useState,
} from "react";
import ParentContext from "../../context/context";
import { useLocation } from "react-router";
import { useHandleApi } from "../../Hooks/index";
import "./FileUploadForm.css";
import { Container, Row, Col } from "react-bootstrap";
import { FileUploader, Loader } from "../index";
import { GenAiInputTab } from "../../services/Generative AI/components";


const FileUploadForm = (props) => {
  const [historyTabActive, setHistoryTabActive] = useState(false);
  const { state, dispatch } = useContext(ParentContext);
  const { loading, multipleFileCheck, serviceType } = state;
  const { BatchProcessing, RadioOptions, ButtonText } = props;

  const location = useLocation();
  const { handleSubmit } = useHandleApi();
  const Radio_Handler = useCallback(
    (e) => {
      dispatch({ type: "SET_SEL_VALUE", payload: e.target.value });
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch({ type: "SET_MULTIPLE_FILE_CHECK", payload: false });
    return multipleFileCheck ? () => handleSubmit() : () => {};
  }, [location.pathname]);

  let TabViewer;
  if (serviceType === "Conversational AI") {
    TabViewer = (
      <GenAiInputTab
        BatchProcessing={BatchProcessing}
        RadioOptions={RadioOptions}
        ButtonText={ButtonText}
        Radio_Handler={Radio_Handler}
        historyTabActive={historyTabActive}
        setHistoryTabActive={setHistoryTabActive}
      />
    );
  } else if (serviceType === "Bank Statement") {
   
    TabViewer = (
      <FileUploader
        BatchProcessing={BatchProcessing}
        RadioOptions={RadioOptions}
        ButtonText={ButtonText}
        RadioHandler={Radio_Handler}
        historyTabActive={historyTabActive}
        setHistoryTabActive={setHistoryTabActive}
      />
    );
  } else {
    TabViewer = (
      <FileUploader
        BatchProcessing={BatchProcessing}
        RadioOptions={RadioOptions}
        ButtonText={ButtonText}
        RadioHandler={Radio_Handler}
      />
    );
  }

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Container fluid>
          <Row>
            <div className="fileupload-component">
              <Col xs={!historyTabActive ? 8 : 12}>{TabViewer}</Col>
              {!historyTabActive ? (
                <Col xs={4}>
                  {props.sideContent && (
                    <div className="fileupload-component-content">
                      {props.sideContent}
                    </div>
                  )}
                </Col>
              ) : (
                <></>
              )}
            </div>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};

export default FileUploadForm;
