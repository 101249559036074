import React, { useContext, useCallback, useEffect, useRef } from "react";
import ParentContext from "../context/context";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import env from "../env/env.js";
import {
  // encryptionKey,
  // initVector,
  notificationAccess,
  secretKeyJwt,
  token,
  userDetails,
} from "../Auth/authConfig.js";
// import { decryptData } from "../utils/crypto.js";
import sign from "jwt-encode";

const useHandleApi = () => {
  const { state, dispatch } = useContext(ParentContext);

  const userInfo = {
    username: userDetails?.name,
    email: userDetails?.email,
    userId: userDetails?.user_id,
    token: token,
    environment: env?.apiEndpoints,
  };

  const jwtToken = sign(userInfo, secretKeyJwt);

  const { selectedValue, file, serviceType, uploadedFiles, multipleFileCheck } =
    state;

  const navigate = useNavigate();

  const redirectToNextPage = useCallback(() => {
    navigate("/OutputScreen");
  }, [navigate]);

  const getNotifaction = async () => {
    const apiUrl = `${env.API_GATEWAY}/dblambda`;
    try {
      const res = await axios.post(
        apiUrl,
        {
          callType: "notification",
          userId: userDetails?.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      );

      if (res.data.response !== null && res.data.response !== undefined) {
        const existingResponses =
          JSON.parse(sessionStorage.getItem("notificationData")) || [];
        const combinedResponses = [res.data.response, ...existingResponses];
        sessionStorage.setItem(
          "notificationData",
          JSON.stringify(combinedResponses)
        );
        sessionStorage.setItem(
          "notificationDataTimestamp",
          new Date().getTime().toString()
        );
        dispatch({ type: "SET_NOTIFICATION", payload: res.data.response });
      }
    } catch (error) {
      dispatch({ type: "SET_ERR_MSG", payload: error.message });
    }
  };

  const convertBlobToBase64 = async (blob) => {
    return await blobToBase64(blob);
  };
  const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const backendResponseProcessingCompleted = useRef();

  const get_progress = (selectedPages) => {
    let reqId = sessionStorage.getItem("requestId");
    if (!(reqId === "" || reqId === null || reqId === undefined)) {
      axios
        .post(
          `${env.API_GATEWAY + "/extractData"}`,
          {
            documentId: reqId,
            userId: userDetails?.user_id,
            service: serviceType,
            formType: selectedValue,
            fileSize: file.size / 1024,
            fileContent: "",
            filename: file.name,
            selected_pages: selectedPages,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: "SET_STATUS",
            payload: response?.data?.status,
          });
          if (response?.data?.status === "100%") {
            backendResponseProcessingCompleted.current = true;
            sessionStorage.setItem("requestId", "");
            dispatch({
              type: "SET_BACKEND_RES",
              payload: response?.data?.response,
            });
            dispatch({
              type: "SET_GET_SELECTED_PAGES",
              payload: response?.data?.response?.selectedPages,
            });
            dispatch({ type: "SET_LOADING", payload: false });
            dispatch({ type: "SET_RES_ERROR", payload: false });
            redirectToNextPage();
            notificationAccess && getNotifaction();
          }
        });
    }
  };

  // async function putToS3(fileObject, presignedUrl) {
  //   const requestOptions = {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": fileObject.type,
  //     },
  //     body: fileObject,
  //   };
  //   const response = await fetch(presignedUrl, requestOptions);
  //   return await response;
  // }

  const handleSubmit = React.useCallback(
    async (event, selectedPages) => {
      event?.preventDefault();
      let preSignedUrl, fileObject;
      dispatch({ type: "SET_LOADING", payload: true });
      dispatch({ type: "SET_RES_ERROR", payload: false });
      let reqId = sessionStorage.getItem("requestId");
      if (reqId === "" || reqId == null || reqId === undefined) {
        if (uploadedFiles.length >= 1 && selectedValue) {
          if (file.size / 1024 >= 6000) {
            preSignedUrl = await axios.post(
              `${env.API_GATEWAY + "/extractData"}`,
              {
                service: serviceType,
                formType: selectedValue,
                documentId: null,
                fileSize: file.size / 1024,
                fileContent: "",
                filename: file.name,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: jwtToken,
                },
              }
            );

            // const decodedUrl = decryptData(
            //   preSignedUrl.data.presignedurl,
            //   encryptionKey,
            //   initVector
            // );
            // const upload_resp = await putToS3(file, decodedUrl);
          }
          if (file.size / 1024 <= 6000) {
            fileObject = await convertBlobToBase64(file);
          }
          axios
            .post(
              `${env.API_GATEWAY + "/extractData"}`,
              {
                service: serviceType,
                formType: selectedValue,
                documentId:
                  file.size / 1024 >= 6000
                    ? preSignedUrl.data.documentId
                    : null,
                fileSize: file.size / 1024,
                fileContent: file.size / 1024 >= 6000 ? "" : fileObject,
                filename: file.name,
                userId: userDetails?.user_id,
                selected_pages: selectedPages,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: jwtToken,
                },
              }
            )
            .then(async (response) => {
              dispatch({
                type: "SET_GET_LATEST_REQUEST_ID",
                payload: response?.data?.documentId,
              });
              sessionStorage.setItem("requestId", response?.data?.documentId);
              if (serviceType === "Redaction") {
                localStorage.setItem("requestId", response?.data?.documentId);
              }
              const delayInMilliseconds = 2000;
              let timeLapsed = 0;
              const timeout = 900000;

              while (!backendResponseProcessingCompleted.current) {
                await new Promise((resolve) =>
                  setTimeout(resolve, delayInMilliseconds)
                );
                timeLapsed = timeLapsed + delayInMilliseconds;
                get_progress(selectedPages);
                if (
                  backendResponseProcessingCompleted.current ||
                  timeLapsed > timeout
                ) {
                  dispatch({ type: "SET_LOADING", payload: false });
                  return;
                }
              }
            })
            .catch((error) => {
              dispatch({ type: "SET_ERR_MSG", payload: error.message });
              dispatch({ type: "SET_RES_ERROR", payload: true });
            });
        }
      }
    },
    [
      backendResponseProcessingCompleted.current,
      dispatch,
      file,
      multipleFileCheck,
      redirectToNextPage,
      selectedValue,
      uploadedFiles,
    ]
  );

  const getBankStatementHistory = React.useCallback(async () => {
    axios
      .post(
        `${env.API_GATEWAY + "/extractData"}`,
        {
          service: "Bank Statement",
          formType: "Bank Statement",
          documentId: "80c39dee-86d7-11ef-b623-a501b09ddd9b",
          fileSize: 28.5869140625,
          fileContent: "",
          filename:
            "032913364524_2Wells-Fargo-Bank-Statement-TemplateLab.com_.pdf",
          userId: "8438e498-1071-70fb-478d-1066a12fab16",
          selected_pages: [1],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: "SET_GET_LATEST_REQUEST_ID",
          payload: response?.data?.documentId,
        });
        dispatch({
          type: "SET_STATUS",
          payload: response?.data?.status,
        });
        if (response?.data?.status === "100%") {
          backendResponseProcessingCompleted.current = true;
          sessionStorage.setItem("requestId", "");
          dispatch({
            type: "SET_BACKEND_RES",
            payload: response.data?.response,
          });
          dispatch({
            type: "SET_GET_SELECTED_PAGES",
            payload: response?.data?.response?.selectedPages,
          });
          dispatch({ type: "SET_LOADING", payload: false });
          dispatch({ type: "SET_RES_ERROR", payload: false });
          notificationAccess && getNotifaction();
        }
      })
      .catch((error) => {
        dispatch({ type: "SET_ERR_MSG", payload: error.message });
        dispatch({ type: "SET_RES_ERROR", payload: true });
      });
  }, [
    backendResponseProcessingCompleted.current,
    dispatch,
    file,
    multipleFileCheck,
    selectedValue,
    uploadedFiles,
  ]);

  useEffect(() => {
    sessionStorage.setItem("requestId", "");
    dispatch({ type: "SET_BACKEND_RES", payload: [] });
    dispatch({ type: "SET_SEL_VALUE", payload: "" });
    dispatch({ type: "SET_RESPONSE_TEXT", payload: "" });
  }, [dispatch, navigate]);

  return { handleSubmit, getBankStatementHistory };
};

export default useHandleApi;
