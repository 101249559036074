import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  memo,
} from "react";
import "./SideBar.css";
import ParentContext from "../../../context/context";
import { Container, Row, Col } from "react-bootstrap";
import { SideBarHeader, SidebarBody } from "./components/index";
import { useLocation } from "react-router";
import { useValidateRoute } from "../../../Hooks/index";
import { userPermission } from "../../../Auth/authConfig";
import {
  SideBarRoutesIcons,
  moduleselectionDashboardRoutesIcons,
  requestMonitoringRoutesIcons,
  billingDashboardRoutesIcons,
  settingDashboardRoutesIcons,
} from "../../../utils/SideBarRoutesIcons";
import { userPermission1 } from "../../../utils/permissionConfig";
import { userPermissionLocal } from "../../../utils/userPermission";

const Sidebar = () => {
  const { state, dispatch, currentItem } = useContext(ParentContext);
  const { breadcrumbs, isOpen, activeIndex, loading, backendResponse } = state;
  const [currentIndex, setCurrentIndex] = useState(activeIndex);

  const history = useLocation();

  let clientPermission = userPermission1(userPermission, SideBarRoutesIcons);

  let requestMonitoringPermission = userPermission1(
    userPermissionLocal.requestMonitoringRoutes,
    requestMonitoringRoutesIcons
  );

  let billingDashboardPermission = userPermission1(
    userPermissionLocal.billingDashboardRoutes,
    billingDashboardRoutesIcons
  );

  let SettingDashBoardPermission = userPermission1(
    userPermissionLocal.settingDashboardRoutes,
    settingDashboardRoutesIcons
  );

  let moduleselectionDashBoardPermission = userPermission1(
    userPermissionLocal.moduleselectionDashboardRoutes,
    moduleselectionDashboardRoutesIcons
  );

  useEffect(() => {
    if (activeIndex !== undefined) {
      setCurrentIndex(activeIndex);
    }
  }, [activeIndex]);

  const handle_Click = useCallback(
    (id) => {
      setCurrentIndex(id);
      dispatch({ type: "SET_ACTIVE_INDEX", payload: id });
      dispatch({ type: "SET_DISPLAY_TEXT", payload: false });
      dispatch({
        type: "SET_CURRENT_PAGE",
        payload: Object.keys(backendResponse?.Data ?? {})?.[0],
      });
      dispatch({ type: "SET_BACKEND_RES", payload: [] });
      localStorage.removeItem("templateQuestions");
    },
    [dispatch]
  );

  let ActiveSideBarRoutes;
  switch (currentItem) {
    case "/":
      if (currentItem) {
        ActiveSideBarRoutes = clientPermission;
      }
      break;
    case "/requestMonitoring":
      if (currentItem) {
        ActiveSideBarRoutes = requestMonitoringPermission;
      }
      break;
    case "/billingMetrics":
      if (currentItem) {
        ActiveSideBarRoutes = billingDashboardPermission;
      }
      break;
    case "/settings":
      if (currentItem) {
        ActiveSideBarRoutes = SettingDashBoardPermission;
      }
      break;
    case "/moduleselection":
      if (currentItem) {
        ActiveSideBarRoutes = moduleselectionDashBoardPermission;
      }
      break;
    default:
  }

  const find_Active_Bread_Crum = ActiveSideBarRoutes?.map(({ id, path }) => {
    return { path, id };
  })?.find(({ path }) => path === history.pathname);

  useEffect(() => {
    dispatch({ type: "SET_ACTIVE_INDEX", payload: find_Active_Bread_Crum?.id });
  }, [breadcrumbs, dispatch]);

  const {
    validateSolutionRoutes,
    validateModuleSelectionRoutes,
    validateRequestMonitoringRoutes,
    validateBillingRoutes,
    validateSettingsRoutes,
  } = useValidateRoute();

  return (
    <Container fluid>
      <Row>
        <Col xs={{ order: "first" }}>
          <div
            className={`sidebar ${isOpen === true ? "active" : ""}`}
            style={{
              left: isOpen ? 0 : " -18%",
              pointerEvents: loading ? "none" : "auto",
            }}
          >
            <div className="sidebar-core">
              <SideBarHeader />
              {validateSolutionRoutes.includes(currentItem) && (
                <SidebarBody
                  activeIndex={currentIndex}
                  handle_Click={handle_Click}
                  SideBarRoutes={clientPermission}
                />
              )}
              {validateRequestMonitoringRoutes.includes(currentItem) && (
                <SidebarBody
                  activeIndex={currentIndex}
                  handle_Click={handle_Click}
                  SideBarRoutes={requestMonitoringPermission}
                />
              )}
              {validateBillingRoutes.includes(currentItem) && (
                <SidebarBody
                  activeIndex={currentIndex}
                  handle_Click={handle_Click}
                  SideBarRoutes={billingDashboardPermission}
                />
              )}
              {validateSettingsRoutes.includes(currentItem) && (
                <SidebarBody
                  activeIndex={currentIndex}
                  handle_Click={handle_Click}
                  SideBarRoutes={SettingDashBoardPermission}
                />
              )}
              {validateModuleSelectionRoutes.includes(currentItem) && (
                <SidebarBody
                  activeIndex={currentIndex}
                  handle_Click={handle_Click}
                  SideBarRoutes={moduleselectionDashBoardPermission}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default memo(Sidebar);
