import { memo } from "react";
import ReactApexChart from "react-apexcharts";

const CategorizationExpanseIncomeBar = () => {
  const series = [
    {
      name: "Income",
      data: [700, 750],
    },
    {
      name: "Expense",
      data: [400, 450],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    colors: ["#008FFB", "#FF4560"], // Colors for Income and Expense
    plotOptions: {
      bar: {
        horizontal: true,

        dataLabels: {
          position: "bottom",
        },
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: true,
      intersect: false,
      theme:"dark"
    },
    dataLabels: {
      enabled: true,
      formatter: (val, { seriesIndex }) => {
        return seriesIndex === 0 ? `Income: ${val}$` : `Expense: ${val}$`; // Label each bar as "Income" or "Expense"
      },
      style: {
        colors: ["#000"], // Set label color
      },
      offsetX: 50,
    },
    xaxis: {
      categories: ["June", "July"],
      title: {
        text: "Amount",
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      //   reversed: true,

      title: {
        text: "Months",
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetY: 0,
      fontSize: "13px",
      markers: {
        width: 12,
        height: 12,
        radius: 6,
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />
  );
};

export default memo(CategorizationExpanseIncomeBar);
