import React, { useContext, useState, useEffect, memo } from "react";
import "./Setting.css";
import ParentContext from "../../context/context";
import {
  AdminControlledRedaction,
  AdminControlledQuestionnaire,
  Template,
} from "./components/index";
import { AdminControlledRedactionOptions } from "../../utils/settings";

import {
  FontAwesomeIcon,
  faPencil,
  faPlus,
  faTrashCan,
  faUsers,
} from "../../import/FontAwesomeIcons";

const Setting = () => {
  const { dispatch } = useContext(ParentContext);
  const [isEditTemplateDisabled, setIsEditTemplateDisabled] = useState(false);
  const [isAddQuestionDisabled, setIsAddQuestionDisabled] = useState(false);
  const [isDeleteQuestionDisabled, setIsDeleteQuestionDisabled] =
    useState(false);

  useEffect(() => {
    const editTemplateState = localStorage.getItem("TemplateDisabled");
    const addQuestionState = localStorage.getItem("AddQuestionDisabled");
    const deleteQuestionState = localStorage.getItem("DeleteQuestionDisabled");
    if (editTemplateState)
      setIsEditTemplateDisabled(JSON.parse(editTemplateState));

    if (addQuestionState)
      setIsAddQuestionDisabled(JSON.parse(addQuestionState));

    if (deleteQuestionState)
      setIsDeleteQuestionDisabled(JSON.parse(deleteQuestionState));
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "TemplateDisabled",
      JSON.stringify(isEditTemplateDisabled)
    );
    localStorage.setItem(
      "AddQuestionDisabled",
      JSON.stringify(isAddQuestionDisabled)
    );
    localStorage.setItem(
      "DeleteQuestionDisabled",
      JSON.stringify(isDeleteQuestionDisabled)
    );
  }, [isEditTemplateDisabled, isAddQuestionDisabled, isDeleteQuestionDisabled]);

  const handleEditTemplateSwitchChange = () => {
    setIsEditTemplateDisabled(!isEditTemplateDisabled);
  };

  const handleAddQuestionSwitchChange = () => {
    setIsAddQuestionDisabled(!isAddQuestionDisabled);
  };

  const handleDeleteQuestionSwitchChange = () => {
    setIsDeleteQuestionDisabled(!isDeleteQuestionDisabled);
  };

  useEffect(() => {
    dispatch({ type: "SET_SERVICE_TYPE", payload: "AdminSetting" });
  }, [dispatch]);

  return (
    <div className="adminPage">
      <div className="Admin Settings">
        <div className="admin_settings">
          <div
            className="admin_setting_container1"
            style={{ fontWeight: "600" }}
          >
            <div className="admin_setting1">
              <AdminControlledRedaction
                AdminControlledRedactionOptions={
                  AdminControlledRedactionOptions
                }
              />
            </div>
            <div className="admin_setting2">
              <AdminControlledQuestionnaire
                handleDeleteQuestionSwitchChange={() =>
                  handleDeleteQuestionSwitchChange()
                }
                handleAddQuestionSwitchChange={() =>
                  handleAddQuestionSwitchChange()
                }
                handleEditTemplateSwitchChange={() =>
                  handleEditTemplateSwitchChange()
                }
                isEditTemplateDisabled={isEditTemplateDisabled}
                isAddQuestionDisabled={isAddQuestionDisabled}
                isDeleteQuestionDisabled={isDeleteQuestionDisabled}
              />
            </div>
          </div>

          <div className="admin_setting_container2">
            <Template
              title="Conversational AI Template"
              editIcon={faPencil}
              deleteIcon={faTrashCan}
              addIcon={faPlus}
              addButtonText="Add New Template"
              isEditTemplateDisabled={isEditTemplateDisabled}
              isAddQuestionDisabled={isAddQuestionDisabled}
              isDeleteQuestionDisabled={isDeleteQuestionDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Setting);
