import "./SelectionElement.css";
import { Form, InputGroup } from "react-bootstrap";

const CheckBox = (props) => {
  let checkbox;
  if ("onChange" in props) {
    checkbox = (
      <Form.Check
        type="checkbox"
        label={props.label}
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
      />
    );
  } else {
    checkbox = (
      <Form.Check
        type="checkbox"
        checked={props.checked}
        value={props.value}
        label={props.label}
      />
    );
  }
  return <div className="item">{checkbox}</div>;
};

const Switch = (props) => {
  const { label } = props;
  return (
    <div>
      <Form.Check
        reverse
        label={label}
        type="switch"
        id={label}
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </div>
  );
};

const LabelSwitch = (props) => {
  const { label, value, Checked, onChange, disabled } = props;
  return (
    <div className={`check-status-${Checked} check-status`}>
      <Form.Check
        label={label}
        type="switch"
        id={value}
        value={value}
        checked={Checked}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

const Radio = (props) => {
  const { label, group, value, onChange, checked } = props;
  return (
    <div className="item">
      <Form.Check
        type="radio"
        id={label}
        name={group}
        value={value}
        onChange={onChange}
        checked={checked}
        label={label}
      />
    </div>
  );
};

const RedactionCheckBox = (props) => {
  let checkbox;
  if ("onChange" in props) {
    checkbox = (
      <input
        className="Redaction-Check-input"
        type="checkbox"
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
      />
    );
  } else {
    checkbox = (
      <input
        className="Redaction-Check-input"
        type="checkbox"
        checked={props.checked}
        value={props.value}
      />
    );
  }

  return (
    <div className="Redaction-Check-box">
      {checkbox}
      <label className="Redaction-Check-label"> {props.label} </label>
    </div>
  );
};

const InputBox = (props) => {
  const { size, type, value, disabled, onChange, placeholder } = props;
  return (
    <Form.Control
      size={size}
      type={type}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

const InputGroups = (props) => {
  const {
    className,
    type,
    value,
    required,
    onChange,
    placeholder,
    label,
    options, // New prop for select options
    max
  } = props;

  return (
    <InputGroup className={className}>
      <InputGroup.Text>{label}</InputGroup.Text>
      {type === "select" ? (
        <Form.Select value={value} onChange={onChange} required={required}>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Select>
      ) : (
        <Form.Control
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required}
          type={type}
          max={type==="date"?max:undefined}
        />
      )}
    </InputGroup>
  );
};
const CustomCheckbox = ({ label, value, checked, onChange, id, className }) => {
  return (
    <Form.Check
      type="checkbox"
      id={id}
      className={className}
      label={label}
      value={value}
      checked={checked}
      onChange={onChange}
    />
  );
};

export default CustomCheckbox;

export {
  CheckBox,
  Radio,
  Switch,
  RedactionCheckBox,
  LabelSwitch,
  InputBox,
  InputGroups,
  CustomCheckbox,
};
