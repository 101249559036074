import React, { useContext, useCallback, useEffect } from "react";
import ParentContext from "../context/context";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import env from "../env/env.js";
import { secretKeyJwt, token, userDetails } from "../Auth/authConfig.js";
import sign from "jwt-encode";
import { decryptData } from "../utils/crypto.js";

const usePredictiveAnalyticsApiCall = () => {
  const { state, dispatch } = useContext(ParentContext);

  const userInfo = {
    username: userDetails?.name,
    email: userDetails?.email,
    userId: userDetails?.user_id,
    token: token,
    environment: env?.apiEndpoints,
  };
  
  const jwtToken = sign(userInfo, secretKeyJwt);

  const {
    selectedValue,
    file,
    email,
    serviceType,
    uploadedFiles,
    multipleFileCheck,
    // paActiveTab
  } = state;

  const navigate = useNavigate();

  const redirectToNextPage = useCallback(() => {
    navigate("/predictiveAnalytics/PredictiveAnalyticsOutputScreen");
  }, [navigate]);

  let backendResponseProcessingCompleted;
  const get_progress = () => {
    let reqId = sessionStorage.getItem("requestId");
    if (!(reqId === "" || reqId == null || reqId === undefined)) {
      axios
        .post(
          `${env.API_GATEWAY + "/extractData"}`,
          {
            service: serviceType,
            formType: selectedValue,
            documentId: reqId,
            userId: userDetails?.user_id,
            formData: "",
            fileSize: 1024,
            fileContent: "",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: "SET_STATUS",
            payload: response?.data?.status,
          });
          if (response?.data?.status === "100%") {
            backendResponseProcessingCompleted = true;
            sessionStorage.setItem("requestId", "");
            dispatch({
              type: "SET_BACKEND_RES",
              payload: response.data?.response,
            });
            dispatch({ type: "SET_LOADING", payload: false });
            dispatch({ type: "SET_RES_ERROR", payload: false });
            redirectToNextPage();
          }
        });
    }
  };

  async function putToS3(fileObject, presignedUrl) {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": fileObject.type,
      },
      body: fileObject,
    };
    const response = await fetch(presignedUrl, requestOptions);
    return response;
  }

  const handleSubmit = React.useCallback(
    async (event, formData, paActiveTab) => {
      event?.preventDefault();
      let inputData = formData;
      const updatedFormData = {};

      for (let key in inputData) {
        if (!isNaN(inputData[key])) {
          inputData[key] = parseFloat(inputData[key]);
        }
        const updatedKey = key.replace(/_/g, "-");
        updatedFormData[updatedKey] = inputData[key];
      }

      updatedFormData.LoanTerm *= 12;

      dispatch({ type: "SET_LOADING", payload: true });
      dispatch({ type: "SET_RES_ERROR", payload: false });

      let reqId = sessionStorage.getItem("requestId");
      if (reqId === "" || reqId == null || reqId === undefined) {
        if (paActiveTab === "Fill the Form" && selectedValue) {
          axios
            .post(
              `${env.API_GATEWAY + "/extractData"}`,
              {
                service: serviceType,
                formType: selectedValue,
                documentId: null,
                userId: userDetails?.user_id,
                formData: updatedFormData,
                fileSize: 1024,
                fileContent: "test,test",
                filename: "sample"
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: jwtToken,
                },
              }
            )
            .then(async (response) => {
              dispatch({
                type: "SET_GET_LATEST_REQUEST_ID",
                payload: response?.data?.requestId,
              });
              sessionStorage.setItem("requestId", response?.data?.documentId);
              if (serviceType === "Redaction") {
                localStorage.setItem("requestId", response?.data?.documentId);
              }
              const delayInMilliseconds = 2000;
              let timeLapsed = 0;
              const timeout = 900000;

              while (!backendResponseProcessingCompleted) {
                await new Promise((resolve) =>
                  setTimeout(resolve, delayInMilliseconds)
                );
                timeLapsed = timeLapsed + delayInMilliseconds;
                get_progress();
                if (
                  backendResponseProcessingCompleted ||
                  timeLapsed > timeout
                ) {
                  dispatch({ type: "SET_LOADING", payload: false });
                  return;
                }
              }
            })
            .catch((error) => {
              dispatch({ type: "SET_ERR_MSG", payload: error.message });
              dispatch({ type: "SET_RES_ERROR", payload: true });
            });
        }
        if (paActiveTab === "Upload file") {
          if (uploadedFiles.length >= 1 && selectedValue) {
            const preSignedUrl = await axios.post(
              `${env.API_GATEWAY + "/getPreSignedUrl"}`,
              {
                filename: file.name,
                callType: "upload",
                fileExtn: file.name.split(".").pop(),
                fileType: file.type,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: jwtToken,
                },
              }
            );

            const decodedUrl = decryptData(preSignedUrl.data.presignedurl);
            await putToS3(file, decodedUrl);

            axios
              .post(
                `${env.API_GATEWAY + "/extractData"}`,
                {
                  service: serviceType,
                  filename_str: file.name,
                  "E-Mail": serviceType === "Redaction" ? email : "",
                  Extraction: serviceType === "Redaction" ? false : true,
                  financialStatementDateData: "noFinancialStatementData",
                  formType: selectedValue,
                  Redaction: serviceType === "Redaction" ? true : false,
                  mode: multipleFileCheck ? "batchprocess" : "realtime",
                  documentId: null,
                  preSignedUrlRequestId: preSignedUrl.data.requestId,
                  userId: userDetails?.user_id,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: jwtToken,
                  },
                }
              )
              .then(async (response) => {
                dispatch({
                  type: "SET_GET_LATEST_REQUEST_ID",
                  payload: response?.data?.requestId,
                });
                sessionStorage.setItem("requestId", response?.data?.requestId);
                if (serviceType === "Redaction") {
                  localStorage.setItem("requestId", response?.data?.requestId);
                }
                const delayInMilliseconds = 2000;
                let timeLapsed = 0;
                const timeout = 900000;

                while (!backendResponseProcessingCompleted) {
                  await new Promise((resolve) =>
                    setTimeout(resolve, delayInMilliseconds)
                  );
                  timeLapsed = timeLapsed + delayInMilliseconds;
                  get_progress();
                  if (
                    backendResponseProcessingCompleted ||
                    timeLapsed > timeout
                  ) {
                    dispatch({ type: "SET_LOADING", payload: false });
                    break;
                  }
                }
              })
              .catch((error) => {
                dispatch({ type: "SET_ERR_MSG", payload: error.message });
                dispatch({ type: "SET_RES_ERROR", payload: true });
              });
          }
        }
      } else {
        get_progress();
      }
    },
    [
      backendResponseProcessingCompleted,
      dispatch,
      file,
      multipleFileCheck,
      redirectToNextPage,
      selectedValue,
      uploadedFiles,
    ]
  );

  useEffect(() => {
    sessionStorage.setItem("requestId", "");
    dispatch({ type: "SET_BACKEND_RES", payload: [] });
    dispatch({ type: "SET_SEL_VALUE", payload: "" });
    dispatch({ type: "SET_RESPONSE_TEXT", payload: "" });
  }, [dispatch, navigate]);

  return { handleSubmit };
};

export default usePredictiveAnalyticsApiCall;
