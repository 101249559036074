import { useState } from "react";
import { Col } from "react-bootstrap";
import CompareGraph from "./CompareGraph";

const AccountComparison = () => {
  const [firstVisual, setFirstVisual] = useState("Income");
  const [secondVisual, setSecondVisual] = useState("Income")
  const chartFirstData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Income",
        data: [75, 62, 85, 78, 64, 58],
        backgroundColor: ["rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
      {
        label: "Expense",
        data: [20, 35, 45, 50, 60, 72],
        backgroundColor: ["rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
      {
        label: "Balance",
        data: [55, 27, 40, 28, 45, 55],
        backgroundColor: ["rgba(153, 102, 255, 0.2)"],
        borderColor: ["rgba(153, 102, 255, 1)"],
        borderWidth: 1,
      },
    ],
  };
  
  const chartSecondData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Income",
        data: [95, 105, 98, 125, 135, 145],
        backgroundColor: ["rgba(255, 206, 86, 0.2)"],
        borderColor: ["rgba(255, 206, 86, 1)"],
        borderWidth: 1,
      },
      {
        label: "Expense",
        data: [70, 88, 76, 92, 100, 110],
        backgroundColor: ["rgba(75, 192, 192, 0.2)"],
        borderColor: ["rgba(75, 192, 192, 1)"],
        borderWidth: 1,
      },
      {
        label: "Balance",
        data: [25, 17, 22, 33, 35, 45],
        backgroundColor: ["rgba(255, 159, 64, 0.2)"],
        borderColor: ["rgba(255, 159, 64, 1)"],
        borderWidth: 1,
      },
    ],
  };
  
  return (
    <Col className="analysis-main-wrap d-flex flex-wrap accounts-compare" xs={12}>
      <CompareGraph
        chartFirstData={chartFirstData}
        firstVisual={firstVisual}
        setFirstVisual={setFirstVisual}
        chartSecondData={chartSecondData}
        secondVisual={secondVisual}
        setSecondVisual={setSecondVisual}
      />
    </Col>
  );
};

export default AccountComparison;
