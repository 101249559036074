import React, { Fragment, useState } from "react";
import ReqMTabelHeader from "./ReqMTableHeader";
import usePagination from "../../../Hooks/usePagination";
import ReqMTablePagination from "./ReqMTablePagination";
import ReqMTableBody from "./ReqMTableBody";
import "./ReqMTable.css";
import { Col } from "react-bootstrap";
import ReqMTableNumOfLists from "./ReqMTableNumOfLists";

const ReqMTabel = ({
  stages_data,
  containerNo,
  totalPages,
  itemsPerPage,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const [toggelCard, setToggelCard] = useState(
    new Array(stages_data?.length).fill(false)
  );

  const openCard = (index) => {
    const newState = toggelCard.map((state, i) =>
      i === index ? !state : false
    );
    setToggelCard(newState);
  };

  const { currentItems } = usePagination(
    itemsPerPage,
    stages_data,
    currentPage,
    setCurrentPage
  );

  return (
    <Fragment>
      <ReqMTabelHeader />
      <ReqMTableBody
        containerNo={containerNo}
        currentItems={currentItems}
        toggelCard={toggelCard}
        itemPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        openCard={openCard}
        stages_data={stages_data}
      />
      <Col className="d-flex justify-content-end pagination">
        <ReqMTableNumOfLists
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
        />
        <ReqMTablePagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </Col>
    </Fragment>
  );
};

export default ReqMTabel;
