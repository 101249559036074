import React, { useState, useContext, useCallback, useMemo, memo } from "react";
import { Carousel } from "react-bootstrap";
import { ExtractionImgs } from "../../utils/carousel";
import ParentContext from "../../context/context";
import { extFiller } from "../../images/sideContent";

const InferCarousel = () => {
  const { state } = useContext(ParentContext);
  const { selectedValue } = state;
  const [index, setIndex] = useState(0);

  const handle_Select = useCallback((selectedIndex, e) => {
    setIndex(selectedIndex);
  }, []);

  const filteredImgs = useMemo(() => {
    return selectedValue
      ? ExtractionImgs.filter((item) => item.Value === selectedValue)
      : ExtractionImgs;
  }, [selectedValue]);

  const fillerData = [
    {
      id: "filler1",
      img: extFiller,
      title: "Extraction",
      desc: [
        {
          id: "filler-desc1",
          cont: "InferIQ is a versatile software solution designed to automatically extract structured data from various document types. It streamlines data capture processes, enhancing efficiency and accuracy across multiple industries and applications.",
        },
      ],
    },
  ];

  const displayImgs = filteredImgs.length > 0 ? filteredImgs : fillerData;

  return (
    <Carousel
      activeIndex={index}
      onSelect={handle_Select}
      variant="dark"
      controls={!selectedValue}
    >
      {window.location.pathname === "/extraction" &&
        displayImgs.map(({ id, img, desc, title }) => {
          return (
            <Carousel.Item key={id}>
              <div className="carousel_conatiner">
                <img src={img} alt="img" draggable="false" />
                <div style={{ padding: "8px" }}>
                  <h6 className="heading carousel_conatiner_title">{title}</h6>
                  <span className="para">
                    {desc.map(({ id, cont }) => (
                      <p key={id}>{cont}</p>
                    ))}
                  </span>
                </div>
              </div>
            </Carousel.Item>
          );
        })}
    </Carousel>
  );
};

export default memo(InferCarousel);
