import React, { memo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./TemplateModal.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { InputGroups } from "../../../../components/SelectionElements/SelectionElement";
import Form from "react-bootstrap/Form";

import { CustomButton } from "../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TemplateModal({
  ModalHeading,
  show,
  setShow,
  save,
  editableContent,
  setEditableContent,

  DataCheckID,
  addIcon,
}) {
  const [isExiting, setIsExiting] = useState(false);

  // Function to add a new question

  const addNewQuestion = () => {
    const newQuestion = {
      id: Date.now(),
      question: "",
    };
    setEditableContent((prev) => ({
      ...prev,
      questions: [...prev.questions, newQuestion],
    }));
  };

  const handleClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      setShow(false);
      setIsExiting(false);
    }, 300);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate form fields
    if (
      editableContent.label &&
      editableContent.questions.every((q) => q.question)
    ) {
      save();
      handleClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className={isExiting ? "fade-out" : ""}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{ModalHeading}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* Admin page modal is here */}
          {editableContent && DataCheckID && (
            <>
              {/* inputgroups are the custom input tag with label using react-bootstarp */}
              <InputGroups
                className="mb-3 equal-label-width"
                label="Label"
                placeholder="Enter New Label..."
                value={editableContent?.label}
                onChange={(e) =>
                  setEditableContent({
                    ...editableContent,
                    label: e.target.value,
                  })
                }
                required={true}
              />

              {editableContent?.questions &&
                editableContent?.questions.map((question, id) => (
                  <InputGroups
                    key={question.id}
                    className="mb-3 equal-label-width"
                    label={`Question ${id + 1}`}
                    placeholder="Enter New Question..."
                    required={true}
                    value={question.question}
                    onChange={(e) =>
                      setEditableContent((prev) => ({
                        ...prev,
                        questions: prev.questions.map((q) =>
                          q.id === question.id
                            ? { ...q, question: e.target.value }
                            : q
                        ),
                      }))
                    }
                  />
                ))}
            </>
          )}
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-between">
          <div>
            {/* this used to add the questions */}

            <CustomButton variant="primary" onClick={addNewQuestion}>
              <FontAwesomeIcon icon={addIcon} />
              <span className="ms-2">Add Questions</span>
            </CustomButton>
          </div>
          <div className="d-flex">
            <CustomButton variant="primary" onClick={handleClose}>
              Close
            </CustomButton>
            <CustomButton
              variant="primary"
              type="submit"
              style={{ marginLeft: "2px" }}
            >
              Save
            </CustomButton>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default memo(TemplateModal);
