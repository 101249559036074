import {
  faFolderTree,
  faChartLine,
  faFolderOpen,
  faFilePen,
  faFileInvoice,
  faCommentDots,
  faUsers,
  faTarpDroplet,
  faMoneyBillWave,
  faComputer,
  faSliders,
  faMicrochip,
  faFileInvoiceDollar,
  faGaugeHigh,
  faFileContract,
  faNetworkWired,
  faChartPie,
  faBuildingColumns,
  faGears,
  faClock,
  faBuildingUser,
} from "@fortawesome/free-solid-svg-icons";

const SideBarRoutes = [
  {
    id: 1,
    label: "Dashboard",
    path: "/dashboard",
    icon: faChartLine,
    disabled: true,
  },
  {
    id: 2,
    label: "Extraction",
    path: "/extraction",
    icon: faFolderTree,
    disabled: false,
  },
  {
    id: 3,
    label: "Bank Statements",
    path: "/bankStatements",
    icon: faBuildingColumns,
    disabled: false,
  },
  {
    id: 4,
    label: "Conversational AI",
    path: "/generativeai",
    icon: faCommentDots,
    disabled: false,
  },
  {
    id: 5,
    label: "Rent Roll",
    path: "/commercialRealEstate",
    icon: faTarpDroplet,
    disabled: false,
  },
  {
    id: 6,
    label: "Predictive Analytics",
    path: "/predictiveAnalytics",
    icon: faChartPie,
    disabled: false,
  },
  {
    id: 7,
    label: "Redaction",
    path: "/redaction",
    icon: faFilePen,
    disabled: false,
  },
  {
    id: 8,
    label: "Recognition",
    path: "/recognition",
    icon: faFileInvoice,
    disabled: false,
  },
  {
    id: 9,
    label: "Classification",
    path: "/classification",
    icon: faFolderOpen,
    disabled: false,
  },
  {
    id: 10,
    label: "Setting",
    path: "/setting",
    icon: faGears,
    disabled: true,
  },
];

const requestMonitoringRoutes = [
  {
    id: 1,
    label: "Request Dashboard",
    path: "/requestMonitoring",
    icon: faNetworkWired,
    disabled: false,
  },
  {
    id: 2,
    label: "Queued History",
    path: "/history",
    icon: faClock,
    disabled: false,
  },
];

const billingDashboardRoutes = [
  {
    id: 1,
    label: "Billing Metrics",
    path: "/billingMetrics",
    icon: faMoneyBillWave,
    disabled: false,
  },
  {
    id:2,
    label: "Processed Files Info",
    path: "/processedFileInfo",
    icon: faFileInvoiceDollar,
    disabled: false,
  },
  {
    id: 3,
    label: "Billing Info",
    path: "/billingInfo",
    icon: faFileInvoiceDollar,
    disabled: false,
  },
  {
    id: 4,
    label: "Client Onboarding",
    path: "/clientOnboarding",
    icon: faFileInvoiceDollar,
    disabled: false,
  },
];

const SettingDashboardRoutes = [
  {
    id: 1,
    label: "Admin Setting",
    path: "/settings",
    icon: faUsers,
    disabled: false,
  },

  {
    id: 2,
    label: "User Management",
    path: "/userManagement",
    icon: faBuildingUser,
    disabled: false,
  },
  {
    id: 3,
    label: "Dashboard",
    path: "/serviceDashboard",
    icon: faGaugeHigh,
    disabled: false,
  },
  {
    id: 4,
    label: "Product Info",
    path: "/productInfo",
    icon: faFileContract,
    disabled: false,
  },
  {
    id: 5,
    label: "Customer Settings",
    path: "/customerSettings",
    icon: faSliders,
    disabled: false,
  },
  {
    id: 6,
    label: "Process Metrics",
    path: "/processMetrics",
    icon: faMicrochip,
    disabled: false,
  },
  {
    id: 7,
    label: "System Logs",
    path: "/systemLogs",
    icon: faComputer,
    disabled: false,
  },
];
const moduleselectionDashboardRoutes = [
  {
    id: 1,
    label: "Module Selection",
    path: "/moduleselection",
    icon: faFolderTree,
    disabled: false,
  },
];

export {
  SideBarRoutes,
  requestMonitoringRoutes,
  billingDashboardRoutes,
  SettingDashboardRoutes,
  moduleselectionDashboardRoutes,
};
