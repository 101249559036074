import {
  faMoneyBillTrendUp,
  faNetworkWired,
  faIdCardClip,
  faUsers,
  faFolderTree,
} from "@fortawesome/free-solid-svg-icons";

const options = [
  {
    id: 1,
    path: "/",
    label: "Solution",
    icon: faIdCardClip,
    validateRoutes: [
      "/",
      "/faqs",
      "/profile",
      "/dashboard",
      "/extraction",
      "/bankStatements",
      "/generativeai",
      "/bankstatements",
      "/bankstatements/analysis",
      "/commercialRealEstate",
      "/redaction",
      "/recognition",
      "/classification",
      "/OutputScreen",
      "/predictiveAnalytics",
      "/predictiveAnalytics/PredictiveAnalyticsOutputScreen",
      "/finStatExtraction",
    ],
  },
  {
    id: 2,
    path: "/requestMonitoring",
    label: "Request Dashboard",
    icon: faNetworkWired,
    validateRoutes: ["/faqs", "/requestMonitoring","/history"],
  },
  {
    id: 3,
    path: "/billingMetrics",
    label: "Billing Dashboard",
    icon: faMoneyBillTrendUp,
    validateRoutes: [
      "/faqs",
      "/billingMetrics",
      "/billingInfo",
      "/clientOnboarding",
      "/processedFileInfo",
    ],
  },
  // newadded
  {
    id: 4,
    path: "/settings",
    label: "Admin Settings",
    icon: faUsers,
    validateRoutes: [
      "/faqs",
      "/adminSetting",
      "/settings",
      "/userManagement",
      "/serviceDashboard",
      "/productInfo",
      "/customerSettings",
      "/processMetrics",
      "/systemLogs",
    ],
  },
  {
    id: 5,
    path: "/moduleselection",
    label: "Module Selection",
    icon: faFolderTree,
    validateRoutes: ["/faqs", "/moduleselection"],
  },
];

export default options;
