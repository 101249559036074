import { useContext } from "react";
import ParentContext from "../context/context";
import { restrictedDomains } from "../utils/restrictedDomains";

const useValidater = () => {
  const { dispatch } = useContext(ParentContext);

  const validatePassword = (input) => {
    if (!input?.trim()) {
      dispatch({
        type: "SET_FORM_ERROR",
        payload: "Password cannot be empty.",
      });
      return;
    }
    if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$#!%*?&]+$/?.test(input)) {
      dispatch({
        type: "SET_FORM_ERROR",
        payload:
          "Password is invalid. It must contain at least one uppercase letter, one lowercase letter, one number, and one special character (@ $ # ! % * ? &).",
      });
      return;
    }
    return true;
  };

  const validateOTP = (otp) => {
    const regexPattern = /^\d{6}$/;

    if (!otp?.trim()) {
      dispatch({
        type: "SET_FORM_ERROR",
        payload: "OTP cannot be empty.",
      });
      return;
    }

    if (!regexPattern?.test(otp)) {
      dispatch({
        type: "SET_FORM_ERROR",
        payload: "Invalid OTP. Please verify and try again.",
      });
      return;
    }
    return true;
  };

  const validateEmail = (email) => {
    const domain = email?.split("@")[1];
    if (restrictedDomains?.includes(domain)) {
      dispatch({
        type: "SET_FORM_ERROR",
        payload: "Please use a corporate email domain.",
      });
      return;
    }
    return true;
  };

  const comparePassword = (password, confirmPassword) => {
    if (password !== confirmPassword) {
      dispatch({
        type: "SET_FORM_ERROR",
        payload: "Passwords do not match.",
      });
      return;
    }
    return true;
  };

  const validateName = (input) => {
    if (!/^[a-zA-Z\s\p{P}.,'"-]{3,30}$/.test(input)) {
      dispatch({
        type: "SET_FORM_ERROR",
        payload: "Enter valid user name.",
      });
      return;
    }
    return true;
  };

  const validateJobTitle = (input) => {
    if (!/^[a-zA-Z\s\p{P}.,'"-]{3,30}$/.test(input)) {
      dispatch({
        type: "SET_FORM_ERROR",
        payload: "Enter valid job title. [3-30 characters]",
      });
      return;
    }
    return true;
  };

  const validateCompany = (input) => {
    if (!/^[a-zA-Z\s\p{P}.,'"-]{3,30}$/.test(input)) {
      dispatch({
        type: "SET_FORM_ERROR",
        payload: "Enter valid company name. [3-30 characters]",
      });
      return;
    }
    return true;
  };

  return {
    validatePassword,
    validateOTP,
    validateEmail,
    comparePassword,
    validateName,
    validateJobTitle,
    validateCompany,
  };
};

export default useValidater;
