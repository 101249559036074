import { CheckBox } from "../../components/SelectionElements/SelectionElement";
import { useToasts } from "../../../InferIq-Solution/Hooks";
import "./ModuleSelection.css";
import ModulesList from "./utils/ModulesList";
import React, { useContext, useState } from "react";
import { Accordion } from "react-bootstrap";
import { OrganiztionDropDown } from "./OrganiztionDropDown";

export default function ModuleSelection() {
  const [list, setList] = useState(ModulesList);
  const [openKeys, setOpenKeys] = useState(["0"]);
  const { Toast } = useToasts();

  const handleStatusChange = (category, id) => {
    const updatedState = list.map((el) => {
      if (el.category === category) {
        const updatedItems = el.items.map((item) =>
          item.id === id ? { ...item, check: !item.check } : item
        );
        return { ...el, items: updatedItems };
      }
      return el;
    });
    setList(updatedState);
  };

  const handleSave = () => {
    const isAnyChecked = list.some((category) =>
      category.items.some((item) => item.check)
    );

    if (!isAnyChecked) {
      Toast("error", "Please select at least One Module.");
    } else {
      Toast("success", "Saved Successfully");
    }
  };

  const handleToggle = (key) => {
    console.log("dcsd", key);
    setOpenKeys((prevKeys) =>
      prevKeys.includes(key)
        ? prevKeys.filter((k) => k !== key)
        : [...prevKeys, key]
    );
  };
  return (                
    <div className="moduleselection-body">
      <div className="module-selection-body-pannel">
        <OrganiztionDropDown />
        <Accordion activeKey={openKeys} alwaysOpen>
          {list?.map((el, index) => {
            return (
              <Accordion.Item
                eventKey={index.toString()}
                key={el.category}
                className="mb-4"
              >
                <div>
                  <Accordion.Header
                    onClick={() => handleToggle(index.toString())}
                    className="card-title"
                  >
                    {el.category}
                  </Accordion.Header>
                  <Accordion.Body className="itemsbox ">
                    <div className="itemsboxes">
                      {el.items.map((item, index) => (
                        <div
                          key={item.id}
                          className="item"
                          style={{ margin: "5px 0" }}
                        >
                          <CheckBox
                            type="checkbox"
                            id={`statusCheckbox${item.id}`}
                            label={item.label}
                            checked={item.check}
                            onChange={() => {
                              handleStatusChange(el.category, item.id);
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="selected-modules">
                      <div className="selected-module-heading">
                        Selected Modules
                      </div>
                      <hr></hr>
                      <div className="selected-module-values">
                        {el.items
                          .filter((item) => item.check)
                          .map((item) => (
                            <div
                              key={item.id}
                              className="selected-module-value"
                            >
                              <span className="selected-value">
                                {item.label}
                              </span>
                            </div>
                          ))}
                        {el.items.filter((item) => item.check).length === 0 && (
                          <p className="no-modules-heading">
                            No Modules Selected
                          </p>
                        )}
                      </div>
                    </div>
                  </Accordion.Body>
                </div>
              </Accordion.Item>
            );
          })}
        </Accordion>
        <div className="fixedcls">
          <div className="Btns d-flex">
            <a href="/" className="homebtn cbtn cbtn-primary cbtn-m">
              Home
            </a>
            <button onClick={handleSave} className="savebtn cbtn cbtn-success ">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
