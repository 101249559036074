import React, { useEffect, useState, memo, useCallback } from "react";

import "./Template.css";
import TemplateModal from "../TemplateModal/TemplateModal";
import { TemplateOptions } from "../../../../utils/settings";

import RenderTaxForms from "./components/RenderTaxForms";
import AddTemplateModal from "../TemplateModal/AddTemplateModal";
import TemplateHeader from "./components/TemplateHeader";

const Template = (props) => {
  const {
    title,
    editIcon,
    deleteIcon,
    addIcon,
    addButtonText,
    isEditTemplateDisabled,
    isAddQuestionDisabled,
    isDeleteQuestionDisabled,
  } = props;

  const [taxForms, setTaxForms] = useState(TemplateOptions);
  const [isEditingID, setisEditingID] = useState(null);
  const [isAddingID, setIsAddingID] = useState(null);
  const [show, setShow] = useState(false);
  const [editContent, setEditContent] = useState();
  const [addContent, setAddContent] = useState({
    label: "",
    questions: [], // Initialize with an empty array
  });
  const [addshow, addSetShow] = useState(false);

  // Load and save taxForms from/to localStorage

  useEffect(() => {
    const storedTaxForms = localStorage.getItem("taxForms");
    if (storedTaxForms) {
      setTaxForms(JSON.parse(storedTaxForms));
    }
  }, []);

  // set the data in LS

  useEffect(() => {
    localStorage.setItem("taxForms", JSON.stringify(taxForms));
  }, [taxForms]);

  // Open The Modal and sent the related data to modal

  const handleEdit = (id) => {
    const taxFormToEdit = taxForms.find((form) => form.id === id);
    setEditContent(taxFormToEdit);
    setisEditingID(id);
    setShow(true);
  };

  // used to delete the id related data

  const handleDelete = (id) => {
    const updatedTaxForms = taxForms.filter((form) => form.id !== id);
    setTaxForms(updatedTaxForms);
  };

  // used to save data which might changed and close the modal

  const handleSave = (id) => {
    const updatedTaxForms = taxForms.map((form) =>
      form.id === id ? { ...form, ...editContent } : form
    );

    setTaxForms(updatedTaxForms);

    setisEditingID(null);
    setEditContent({});
  };

  // this is used to add the new field in data

  const saveNewTemplate = useCallback(
    (id) => {
      const updatedTaxForms = [...taxForms, addContent]; // Add the new content
      setTaxForms(updatedTaxForms);

      setIsAddingID(null);
      setAddContent({
        label: "",
        questions: [], // Initialize with an empty array
      });
    },
    [addContent, taxForms]
  );
  // this is used to set the addcontent for the add template
  const openAndHandleAddTemplateModal = () => {
    const newTaxForm = {
      id: Date.now(),
      label: "",
      questions: [{ id: Date.now(), question: "" }],
    }; // Initialize with empty values

    setAddContent(newTaxForm);
    setIsAddingID(newTaxForm.id);
    addSetShow(true);
  };

  return (
    <div className="template-container">
      <TemplateHeader
        title={title}
        addIcon={addIcon}
        openAndHandleAddTemplateModal={openAndHandleAddTemplateModal}
        isEditTemplateDisabled={isEditTemplateDisabled}
        addButtonText={addButtonText}
      />
      <hr />
      <div className="templates">
        <RenderTaxForms
          taxForms={taxForms}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          isDeleteQuestionDisabled={isDeleteQuestionDisabled}
          isAddQuestionDisabled={isAddQuestionDisabled}
          editIcon={editIcon}
          deleteIcon={deleteIcon}
        />
        {show && (
          <TemplateModal
            show={show}
            setShow={setShow}
            save={() => handleSave(isEditingID)}
            setEditableContent={setEditContent}
            editableContent={editContent}
            ModalHeading={"Edit The Question And Label"}
            CheckModalFor="Admin-setting"
            addIcon={addIcon}
            DataCheckID={isEditingID}
          />
        )}
      </div>
      {addshow && (
        <AddTemplateModal
          show={addshow}
          setShow={addSetShow}
          save={() => saveNewTemplate(isAddingID)}
          setEditableContent={setAddContent}
          editableContent={addContent}
          ModalHeading={"Add New Temaplate"}
          addIcon={addIcon}
          DataCheckID={isAddingID}
        />
      )}
    </div>
  );
};

export default memo(Template);
