import { Button, Row } from "react-bootstrap";
import "./EditTransactionModal.css";
import { useEffect, useRef, useState } from "react";

const AddNewColumn = (props) => {
  const {
    setShow,
    newColumn,
    setNewColumn,
    columnName,
    bankSttTableData,
    setBankSttTableData,
    tableAccNo,
  } = props;
  const [isFieldEmpty, setIsfieldEmpty] = useState(true);
  const newColumnInputRef = useRef(null)

  const handleAddColumn = () => {
    setShow(false);
    const updateNewValue = {
      ...bankSttTableData,
      data: [
        {
          [tableAccNo]: bankSttTableData?.data[0][tableAccNo]?.map(
            (rowValues) => {
              let newRow = {};
              let columnInserted = false;
              for (let key in rowValues) {
                newRow[key] = rowValues[key];
                if (key === columnName && !columnInserted) {
                  newRow[newColumn+" edit"] = ["", "editable"]; // Add blank value for the new column
                  columnInserted = true;
                }
              }
              return newRow;
            }
          ),
        },
      ],
    };
    setBankSttTableData(updateNewValue);
  };

  useEffect(() => {
    if (newColumn?.length === 0) {
      setIsfieldEmpty(true)
    } else setIsfieldEmpty(false)
  }, [newColumn])

  useEffect(() => {
    newColumnInputRef?.current?.focus();
  }, [])

  return (
    <div className="add-column">
      <h1 className="header">Add a Column</h1>
      <div className="body">
        <input type="text" onChange={(e) => setNewColumn(e.target.value)} ref={newColumnInputRef} />
        <Row className="m-0 py-2 footer">
          <Button
            variant="primary"
            style={{ marginRight: "10px" }}
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
          <Button variant="success" onClick={handleAddColumn} disabled={isFieldEmpty}>
            Add
          </Button>
        </Row>
      </div>
    </div>
  );
};

export default AddNewColumn;
