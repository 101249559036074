import { useContext } from "react";
import ParentContext from "../context/context";
import axios from "axios";
import env from "../env/env.js";
import { secretKeyJwt, token, userDetails } from "../Auth/authConfig.js";
// import CryptoJS from "crypto-js";
import sign from "jwt-encode";

const useCreHistoryApiCall = () => {
  const { dispatch } = useContext(ParentContext);

  const userInfo = {
    username: userDetails?.name,
    email: userDetails?.email,
    userId: userDetails?.user_id,
    token: token,
    environment: env?.apiEndpoints,
  };

  const jwtToken = sign(userInfo, secretKeyJwt);

  const fetchCreHistoryData = async () => {
    const apiUrl = `${env.API_GATEWAY}/dblambda`;
    await axios
      .post(
        apiUrl,
        {
          service: "Rent Roll",
          callType: "History",
          formType: "Rent Roll",
          userId: userDetails?.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        dispatch({ type: "SET_CRE_HISTORY_RESPONSE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SET_ERR_MSG", payload: error.message });
      });
  };

  // const decryptData = (encryptedText) => {
  //   const key = CryptoJS.enc.Utf8.parse(encryptionKey);
  //   const iv = CryptoJS.enc.Utf8.parse(initVector);

  //   const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
  //     mode: CryptoJS.mode.CFB,
  //     iv: iv,
  //     padding: CryptoJS.pad.NoPadding,
  //   });

  //   return decrypted.toString(CryptoJS.enc.Utf8);
  // };

  const getDocumentFromS3PregignedUrl = async (presignedUrl) => {
    const response = axios.get(presignedUrl, {
      responseType: "blob",
    });
    return response;
  };

  const downloadFile = async (presignedUrl, fileName) => {
    const response = await getDocumentFromS3PregignedUrl(presignedUrl);
    const blob = new Blob([response.data]);
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  const getRentrollPreviewFile = async (id) => {
    const apiUrl = `${env.API_GATEWAY}/extractData`;
    try {
      const url_resp = await axios.post(
        apiUrl,
        {
          service: "Rent Roll",
          callType: "download",
          documentId: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      );

      const decodedUrl = url_resp.data.presignedurl;
      const document_data = await getDocumentFromS3PregignedUrl(decodedUrl);
      dispatch({
        type: "SET_RENT_ROLL_PREVIEW_FILE",
        payload: document_data?.data,
      });
    } catch (error) {
      dispatch({ type: "SET_ERR_MSG", payload: error.message });
    }
  };

  const getBankStatementPreviewFile = async (id) => {
    const apiUrl = `${env.API_GATEWAY}/extractData`;
    try {
      const url_resp = await axios.post(
        apiUrl,
        {
          service: "Rent Roll",
          // service: "Bank Statement",
          callType: "download",
          documentId: "69cbfe96-870a-11ef-ab21-dbc66c260d66",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      );
      const decodedUrl = url_resp.data.presignedurl;
      const document_data = await getDocumentFromS3PregignedUrl(decodedUrl);
      dispatch({
        type: "SET_BANK_STT_PREVIEW_FILE",
        payload: document_data?.data,
      });
    } catch (error) {
      dispatch({ type: "SET_ERR_MSG", payload: error.message });
    }
  };

  const getRentrollFile = async (id, fileName) => {
    const apiUrl = `${env.API_GATEWAY}/extractData`;
    try {
      const url_resp = await axios.post(
        apiUrl,
        {
          service: "Rent Roll",
          callType: "download",
          documentId: id,
          fileName: fileName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      );
      const decodedUrl = url_resp.data.presignedurl;
      const file_split = fileName.split(".");
      const file_index = fileName.lastIndexOf(
        file_split[file_split.length - 1]
      );
      const file_initals = fileName.substring(0, file_index);
      downloadFile(decodedUrl, file_initals + url_resp.data.extension);
    } catch (error) {
      dispatch({ type: "SET_ERR_MSG", payload: error.message });
    }
  };

  return {
    fetchCreHistoryData,
    getRentrollFile,
    getRentrollPreviewFile,
    getBankStatementPreviewFile,
  };
};

export default useCreHistoryApiCall;
