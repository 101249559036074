import { memo } from "react";
import ReactApexChart from "react-apexcharts";

const CategorizationNeedvsWantChart = () => {
  const series = [
    {
      name: "Needs",
      data: [1500, 1800, 1600, 1700, 1900, 1750], // Essential expenses like rent, utilities, groceries
    },
    {
      name: "Wants",
      data: [800, 1200, 900, 1100, 1400, 1000], // Discretionary expenses like entertainment, shopping
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      background: "transparent",
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 350,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    colors: ["#4B0082", "#FF69B4"], // Deep Purple for Needs, Pink for Wants
    plotOptions: {
      bar: {
        horizontal: false,
        // columnWidth: "55%",
        borderRadius: 6,
        borderRadiusApplication: "end",
        dataLabels: {
          position: "top",
        },
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    tooltip: {
      shared: true,
      intersect: false,
      theme: "dark",
      y: {
        formatter: function (val) {
          return `$${val.toLocaleString()}`;
        },
      },
      marker: {
        show: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return "$" + val.toLocaleString();
      },
      offsetY: -20,
      style: {
        fontSize: "13px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      title: {
        text: "Months",
        style: {
          fontSize: "13px",
          // fontWeight: 600,
        },
      },
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    yaxis: {
      title: {
        text: "Amount",
        style: {
          fontSize: "13px",
          // fontWeight: 600,
        },
      },
      labels: {
        formatter: function (val) {
          return `$${val.toLocaleString()}`;
        },
        style: {
          fontSize: "13px",
        },
      },
    },

    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetY: 0,
      fontSize: "13px",
      markers: {
        width: 12,
        height: 12,
        radius: 6,
      },
      itemMargin: {
        horizontal: 10,
      },
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />
  );
};

export default memo(CategorizationNeedvsWantChart);
