import React, { Fragment } from "react";
import Input from "../../../components/Input/Input";
import CustomButton from "../../../components/Button/CustomButton";
import ForgotPassVerifiData from "../utils/ForgotPassVerifiData";

export const ForgetPassVerificationForm = (props) => {
  return (
    <form onSubmit={props.handeleChangeNewPassword}>
      {ForgotPassVerifiData?.map((item) => {
        const { id, label, type, name, placeholder, required } = item;
        const inputProps = {
          ...(name === "password" || name === "confirmPassword" ? { maxLength: 20, minLength: 8 } : {}),
        };
        return (
          <Fragment key={id}>
            <Input
              label={label}
              type={props.showPassword ? "text" : type}
              name={name}
              value={props.name}
              placeholder={placeholder}
              handleChange={props.handleForgotPassInputChange}
              required={required}
              {...inputProps}
            />
          </Fragment>
        );
      })}
      <div className="signin_form_btn_redirect">
        <div className="signin_form_show_pass">
          <input
            type="checkbox"
            checked={props.showPassword}
            onChange={props.handleShowPassword}
          />
          <div
            style={{
              marginLeft: "5px",
            }}
          >
            Show Password
          </div>
        </div>
        <span className="signin_form_req_demo" onClick={props.showSignIn}>
          Back
        </span>
      </div>
      <div style={{ display: "grid" }}>
        <CustomButton variant="primary" size="md" type="submit">
          Submit
        </CustomButton>
      </div>
      {props.formError && (
        <p className="signin_form_error">{props.formError}</p>
      )}
    </form>
  );
};
