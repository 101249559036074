import React, { useContext, memo, useState } from "react";
import { FontAwesomeIcon } from "../../../import/FontAwesomeIcons";
import { Link } from "react-router-dom";
import {
  requestMonitoringRoutes,
  billingDashboardRoutes,
  SettingDashboardRoutes,
  moduleselectionDashboardRoutes,
} from "../../../utils/SideBarRoutes";
import "./CollapsedSideBar.css";
import {
  OverlayTrigger,
  Tooltip,
  Container,
  Row,
  Col,
  Stack,
} from "react-bootstrap";
import { useValidateRoute } from "../../../Hooks/index";
import ParentContext from "../../../context/context";
import { userPermission } from "../../../Auth/authConfig";
import { SideBarRoutesIcons } from "../../../utils/SideBarRoutesIcons";

const CollapsedSideBarHeader = () => {
  return (
    <div className="c-sidebar-logo">
      <Link to="/">
        infer
        <span
          style={{
            color: "rgb(18, 144, 235)",
            fontWeight: "bold",
            fontSize: ".8rem",
          }}
        >
          IQ
        </span>
      </Link>
    </div>
  );
};

const CollapsedSideBarBody = (props) => { 
  const [activePath, setActivePath] = useState(window.location.pathname || "");
  const handleLinkClick = (path) => {
    setActivePath(path);
  };

  return (
    <div className="c-sidebar-links">
      {props.SideBarRoutes.map(({ id, path, label, icon }) => {
        return (
          <Stack
            key={id}
            className={activePath === path ? "active" : "notactive"}
          >
            <Link
              to={path}
              draggable={false}
              onClick={() => handleLinkClick(path)}
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip">{label}</Tooltip>}
              >
                <FontAwesomeIcon
                  className={`c-sidebar-svg ${
                    activePath === path ? "active-icon" : ""
                  }`}
                  icon={icon}
                />
              </OverlayTrigger>
            </Link>
          </Stack>
        );
      })}
    </div>
  );
};

const CollapsedSideBar = () => {
  const { currentItem } = useContext(ParentContext);

  const {
    validateSolutionRoutes,
    validateRequestMonitoringRoutes,
    validateBillingRoutes,
    validateSettingsRoutes,
    validateModuleSelectionRoutes,
  } = useValidateRoute();
  let UpdateduserPermission = userPermission?.map((route) => {
    const match = SideBarRoutesIcons.find(
      (iconRoute) => iconRoute.label === route.label
    );
    return match ? { ...route, icon: match.icon } : route;
  });

  return (
    <Container>
      <Row>
        <Col xs={2}>
          <div className="c-sidebar">
            <Stack>
              <CollapsedSideBarHeader />
              {validateSolutionRoutes.includes(currentItem) && (
                <CollapsedSideBarBody SideBarRoutes={UpdateduserPermission} />
              )}
              {validateRequestMonitoringRoutes.includes(currentItem) && (
                <CollapsedSideBarBody SideBarRoutes={requestMonitoringRoutes} />
              )}
              {validateBillingRoutes.includes(currentItem) && (
                <CollapsedSideBarBody SideBarRoutes={billingDashboardRoutes} />
              )}
              {validateSettingsRoutes.includes(currentItem) && (
                <CollapsedSideBarBody SideBarRoutes={SettingDashboardRoutes} />
              )}
              {validateModuleSelectionRoutes.includes(currentItem) && (
                <CollapsedSideBarBody
                  SideBarRoutes={moduleselectionDashboardRoutes}
                />
              )}
            </Stack>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default memo(CollapsedSideBar);
