import { Col, Row } from "react-bootstrap";

import TabsContainer from "../../../../../../InferIq-Billing-Dashboard/Components/TabsContainer/TabsContainer";

import "../../components/AnalysisTabs.css";

import CategorizationExpanseIncomeBar from "../../ChartsComponent/CategorizationExpanseIncomeBar";
import CategorizationBar from "../../ChartsComponent/CategorizationBar";
import CategorizationParallelBarChart from "../../ChartsComponent/CategorizationParallelBarChart";
import CategorizationNeedvsWantChart from "../../ChartsComponent/CategorizationNeedvsWantChart";
import Categorization from "../../ChartsComponent/Categorization";
import AnalysisFilter from "../AnalysisFilter/AnalysisFilter";

const CategorizationSeries = () => {
  return (
    <Col className="d-flex flex-wrap time-series">
      <AnalysisFilter />
      {/* <Col className="d-flex flex-wrap justify-content-evenly time-series p-0"> */}
      <Row className="analysis-main-wrap d-flex justify-content-evenly mx-0">
        <Col xs={5} style={{ flexBasis: "48.31%" }} className="TScharts">
          <h4 className="charts-header">Income VS Expanse</h4>
          <TabsContainer
            chartOne={<CategorizationExpanseIncomeBar showLegend={false} />}
            modalChartOne={<CategorizationExpanseIncomeBar showLegend={true} />}
          />
        </Col>
        <Col xs={5} style={{ flexBasis: "48.31%" }} className="TScharts">
          <h4 className="charts-header">Debit Credit Expenses</h4>
          <TabsContainer
            chartOne={<CategorizationBar showLegend={false} />}
            modalChartOne={<CategorizationBar showLegend={true} />}
          />
        </Col>
        <Col xs={5} style={{ flexBasis: "48.31%" }} className="TScharts">
          <h4 className="charts-header">Fixed vs Variable Expenses</h4>
          <TabsContainer
            chartOne={<CategorizationParallelBarChart showLegend={false} />}
            modalChartOne={<CategorizationParallelBarChart showLegend={true} />}
          />
        </Col>
        <Col xs={5} style={{ flexBasis: "48.31%" }} className="TScharts">
          <h4 className="charts-header">Needs vs Wants Analysis</h4>
          <TabsContainer
            chartOne={<CategorizationNeedvsWantChart showLegend={false} />}
            modalChartOne={<CategorizationNeedvsWantChart showLegend={true} />}
          />
        </Col>

        <Col xs={12} style={{ flexBasis: "98.31%" }} className="TScharts">
          <h4 className="charts-header">Account balance Vs Expense Variance</h4>
          <TabsContainer
            chartOne={<Categorization showLegend={false} />}
            modalChartOne={<Categorization showLegend={true} />}
          />
        </Col>
      </Row>

      {/* </Col> */}
    </Col>
  );
};

export default CategorizationSeries;
