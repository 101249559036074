import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./EditTransactionModal.css";
import CustomButton from "../Button/CustomButton";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditTransactionModal = React.memo((props) => {
  const {
    transactionData,
    show,
    setShow,
    bankSttTableData,
    setBankSttTableData,
    tableAccNo,
  } = props;
  const [editTransaction, setEditTransaction] = useState({});
  const [editDataSave, setEditDataSave] = useState(false);

  const handleEditTransaction = () => {
    setEditDataSave(true);
    const UPDATE_TRANSACTION = bankSttTableData?.data[0][tableAccNo]?.map(
      (transaction) => {
        return transaction?.TEXTRACT_ID == editTransaction?.TEXTRACT_ID
          ? editTransaction
          : transaction;
      }
    );
    let newData = {
      ...bankSttTableData,
      data: [
        [
          { ...bankSttTableData.data[0][tableAccNo], UPDATE_TRANSACTION }, // Update the specific account
        ],
      ],
    };

    // Ensure state updates immutably
    newData.data[0][tableAccNo] = UPDATE_TRANSACTION;
    setBankSttTableData(newData);

    setTimeout(() => {
      setShow(false);
      setEditDataSave(false);
    }, 1000);
  };

  useEffect(() => {
    setEditTransaction(transactionData);
  }, [transactionData]);

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="bsEditModal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Modify Transaction Data
          <FontAwesomeIcon icon={faTimes} onClick={() => setShow(false)} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="bsEditModalForm">
          <div className="wrap">
            {editTransaction &&
              Object.entries(editTransaction).map(([key, value], index) => {
                return (
                  <div key={index} className="editField">
                    <label>{key.replaceAll("_", " ")}</label>
                    <input
                      type="text"
                      value={value}
                      name={key}
                      onChange={(e) => {
                        setEditTransaction({
                          ...editTransaction,
                          [key]: key == "TEXTRACT_ID" ? e.target.value+"123TEST" : e.target.value,
                        });
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          size="sm"
          variant="primary"
          onClick={() => setShow(false)}
        >
          Remove
        </CustomButton>
        {editDataSave ? (
          <CustomButton
            size="sm"
            variant="success"
            style={{ display: "flex", alignItems: "center" }}
          >
            Saved
            <FontAwesomeIcon
              icon={faCheck}
              style={{ color: "white", marginLeft: "10px" }}
            />
          </CustomButton>
        ) : (
          <CustomButton
            size="sm"
            variant="primary"
            onClick={() => handleEditTransaction()}
          >
            Save
          </CustomButton>
        )}
      </Modal.Footer>
    </Modal>
  );
});

export default EditTransactionModal;
