import React, { useCallback, memo, useState, useEffect } from "react";
import "./UserManagment.css";
import { CustomButton } from "../../../../components/index";
import { FontAwesomeIcon } from "../../../../import/FontAwesomeIcons";
import {
  faAngleLeft,
  faBuildingUser,
  faFileExport,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { EditableTable } from "../index";
import { Accordion, Button, Form, InputGroup } from "react-bootstrap";
import CustomCheckbox from "../../../../components/SelectionElements/SelectionElement";

const userValues = [
  {
    id: 1,
    name: "John",
    roll: "Admin",
    created: "2023-10-10",
    Status: "INACTIVE",
    edit: false,
  },
  {
    id: 2,
    name: "Jane",
    roll: "User",
    created: "2023-10-10",
    Status: "ACTIVE",
    edit: false,
  },
  {
    id: 3,
    name: "Mark",
    roll: "User",
    created: "2023-10-10",
    Status: "PENDING",
    edit: false,
  },
];

const UserManagement = () => {
  const headers = ["S.No", "Name", "Role", "Created", "Status", "Edit"];
  const navigate = useNavigate();

  // State management
  const [masterData, setMasterData] = useState(userValues);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterData, setFilterData] = useState(userValues);

  // this function is used to filter the data
  const filterByMe = (masterData) => {
    return masterData.filter((item) => {
      const statusMatch = !selectedStatus || item.Status === selectedStatus;
      const roleMatch = !selectedRole || item.roll === selectedRole;
      const nameMatch = item.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      return statusMatch && roleMatch && nameMatch;
    });
  };

  // this changed the master data with filtered data
  useEffect(() => {
    const filteredData = filterByMe(masterData);
    setFilterData(filteredData);
  }, [masterData, selectedStatus, selectedRole, searchTerm, setFilterData]);

  // Handlers
  const handleStatusChange = (status) => {
    setSelectedStatus((prev) => (prev === status ? null : status));
  };

  const handleRoleChange = (role) => {
    setSelectedRole((prev) => (prev === role ? null : role));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClear = () => {
    setSearchTerm("");
  };

  const handleUpdateData = (newData) => {
    // setFilterData(newData);
    setMasterData(newData);
  };

  const handleBackToSettings = useCallback(() => {
    navigate("/settings");
  }, [navigate]);

  return (
    <div className="user_managment">
      <div className="user_management_side">
        <div className="user_management_left_side">
          <div className="user_managment_header">
            <CustomButton size="md" variant={"primary"}>
              <span className="user_managment_export_btn">Export</span>
              <FontAwesomeIcon icon={faFileExport} />
            </CustomButton>
          </div>
          <div>
            <EditableTable
              headers={headers}
              data={masterData}
              onUpdateData={handleUpdateData}
              masterData={filterData}
              searchTerm={searchTerm}
            />
          </div>
        </div>
        <div>
          <div className="user_management_right_side">
            <InputGroup className="mb-3 Search">
              <Form.Control
                aria-label="Search By Name..."
                aria-describedby="basic-addon2"
                type="text"
                required={true}
                placeholder="Search By Name..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <Button className="success" onClick={handleSearchClear}>
                {searchTerm ? <FontAwesomeIcon icon={faTimes} /> : "Search"}
              </Button>
            </InputGroup>
            <h3>FILTER BY</h3>
            <div className="userManagementAccordion">
              <Accordion alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Select Status</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      {["ACTIVE", "INACTIVE", "PENDING"].map(
                        (status, index) => (
                          <CustomCheckbox
                            key={index}
                            type="checkbox"
                            id={`statusCheckbox${index}`}
                            label={status}
                            checked={selectedStatus === status}
                            onChange={() => handleStatusChange(status)}
                            className={`userManagement-custom-checkbox ${
                              selectedStatus === status ? "addgreen" : ""
                            }`}
                          />
                        )
                      )}
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Select Role</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      {["Admin", "User"].map((role, index) => (
                        <CustomCheckbox
                          key={index}
                          type="checkbox"
                          id={`roleCheckbox${index}`}
                          label={role}
                          checked={selectedRole === role}
                          onChange={() => handleRoleChange(role)}
                          className={`userManagement-custom-checkbox ${
                            selectedRole === role ? "addgreen" : ""
                          }`}
                        />
                      ))}
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(UserManagement);


