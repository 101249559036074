import { memo, useEffect, useMemo, useRef } from "react";
import ApexSankey from "apexsankey";
import CategorizationData from "../../ChartsData";
import "./Categorization.css";
const Categorization = ({ showLegend }) => {
  const optionsGraph = useMemo(() => {
    return {
      nodeWidth: 10,
      fontWeight: 300,
      height: 350,
      edgeOpacity: 0.8,
      fontSize: "13px",
    };
  }, []);

  const container = useRef();
  useEffect(() => {
    if (showLegend) {
      container.current = document.getElementById("svg-sankey-modal");
    } else {
      container.current = document.getElementById("svg-sankey");
    }

    // Remove any existing sankey elements to prevent duplicates
    if (container.current.firstChild) {
      container.current.removeChild(container.current.firstChild);
    }

    const sankey = new ApexSankey(container.current, optionsGraph);
    sankey.render(CategorizationData);
    setTimeout(() => {
      // Apply CSS classes to nodes and links for animation
      const nodes = container.current.querySelectorAll(".node rect");

      nodes.forEach((node) => {
        node.style.strokeWidth = "1px";
        node.style.stroke = "white";
      });
      container.current.querySelectorAll(".sankey-node").forEach((node) => {
        node.classList.add("node");
      });
      container.current.querySelectorAll(".sankey-link").forEach((link) => {
        link.classList.add("link");
      });
    }, 500);

    // Cleanup function to remove the graph instance on unmount
    return () => {
      while (container.current.secondChild) {
        container.current.removeChild(container.current.secondChild);
      }
    };
  }, [optionsGraph]);

  return <div id={showLegend ? "svg-sankey-modal" : "svg-sankey"} />;
};

export default memo(Categorization);
