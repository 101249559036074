export const IncomeReport = {
  other_service_area_chart: {
    title: "Daily Spend Categorized by Offering/Service",
    datasets: [
      {
        name: "Amazon Simple Storage Service",
        data: [
          0.015, 0.25, 0.35, 0.45, 0.055, 0.75, 0.78, 0.007,
        ],
      },
      {
        name: "AWS Backup",
        data: [
          0.049, 0.151, 0.821, 0.921, 0.351, 0.451, 0.551, 0.651,
        ],
      },
    ],
    yaxis: {
      title: "Cost($)",
      minValue: 0,
      maxValue: 1,
    },
    xaxis: {
      categories: [
        "08-23",
        "08-29",
        "09-05",
        "09-12",
        "09-19",
        "09-26",
        "10-03",
        "10-10",
      ],
      title: "Date",
    },
    name: "Other Services",
  },
};

export const IncomeForecast = {
  other_service_area_chart: {
    title: "Daily Spend Categorized by Offering/Service",
    datasets: [
      {
        name: "AWS Lambda",
        data: [
          0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7,
        ],
      },
      {
        name: "AWS Secrets Manager",
        data: [
          0.04, 0.11, 0.22, 0.56, 0.78, 0.88, 0.90, 0.3,
        ],
      },
    ],
    yaxis: {
      title: "cost($)",
      minValue: 0,
      maxValue: 1,
    },
    xaxis: {
      categories: [
        "08-23",
        "08-29",
        "09-05",
        "09-12",
        "09-19",
        "09-26",
        "10-03",
        "10-10",
      ],
      title: "date",
    },
    name: "Other Services",
  },
};

export const ExpenseReport = {
  other_service_area_chart: {
    title: "Daily Spend Categorized by Offering/Service",
    datasets: [
      {
        name: "Amazon API Gateway",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0,
        ],
      },
      {
        name: "Amazon EC2 Container Registry (ECR)",
        data: [
          0.047, 0.049, 0.049, 0.049, 0.049, 0.049, 0.049, 0.049, 0.049, 0.049,
          0.049, 0.049, 0.049, 0.049, 0.049, 0.049, 0.049, 0.049, 0.075, 0.058,
          0.058, 0.058, 0.058, 0.058, 0.058, 0.07, 0.056, 0.056, 0.056, 0.056,
          0.063, 0.067, 0.055, 0.067, 0.065, 0.062, 0.054, 0.054, 0.054, 0.054,
          0.059, 0.037, 0.037, 0.037, 0.037, 0.037, 0.037, 0.037, 0.056, 0.054,
          0.054, 0.054, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
      },
    ],
    yaxis: {
      title: "cost($)",
      minValue: 0,
      maxValue: 1,
    },
    xaxis: {
      categories: [
        "08-23",
        "",
        "",
        "",
        "",
        "",
        "08-29",
        "",
        "",
        "",
        "",
        "",
        "",
        "09-05",
        "",
        "",
        "",
        "",
        "",
        "",
        "09-12",
        "",
        "",
        "",
        "",
        "",
        "",
        "09-19",
        "",
        "",
        "",
        "",
        "",
        "",
        "09-26",
        "",
        "",
        "",
        "",
        "",
        "",
        "10-03",
        "",
        "",
        "",
        "",
        "",
        "",
        "10-10",
        "",
        "",
        "",
        "",
        "",
        "",
        "10-17",
        "",
        "",
        "",
        "",
      ],
      title: "date",
    },
    name: "Other Services",
  },
};

export const ExpenseForecast = {
  other_service_area_chart: {
    title: "Daily Spend Categorized by Offering/Service",
    datasets: [
      {
        name: "EC2 - Other",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0,
        ],
      },
      {
        name: "Amazon Elastic File System",
        data: [
          0.296, 0.306, 0.306, 0.306, 0.306, 0.306, 0.306, 0.306, 0.306, 0.306,
          0.306, 0.306, 0.306, 0.306, 0.306, 0.306, 0.306, 0.306, 0.357, 0.384,
          0.384, 0.384, 0.384, 0.384, 0.384, 0.389, 0.391, 0.396, 0.399, 0.399,
          0.399, 0.387, 0.387, 0.387, 0.388, 0.389, 0.389, 0.389, 0.389, 0.39,
          0.39, 0.39, 0.39, 0.39, 0.39, 0.391, 0.391, 0.391, 0.391, 0.391,
          0.391, 0.391, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
      },
    ],
    yaxis: {
      title: "cost($)",
      minValue: 0,
      maxValue: 1,
    },
    xaxis: {
      categories: [
        "08-23",
        "",
        "",
        "",
        "",
        "",
        "08-29",
        "",
        "",
        "",
        "",
        "",
        "",
        "09-05",
        "",
        "",
        "",
        "",
        "",
        "",
        "09-12",
        "",
        "",
        "",
        "",
        "",
        "",
        "09-19",
        "",
        "",
        "",
        "",
        "",
        "",
        "09-26",
        "",
        "",
        "",
        "",
        "",
        "",
        "10-03",
        "",
        "",
        "",
        "",
        "",
        "",
        "10-10",
        "",
        "",
        "",
        "",
        "",
        "",
        "10-17",
        "",
        "",
        "",
        "",
      ],
      title: "date",
    },
    name: "Other Services",
  },
};

export const BalanceReport = {
  other_service_area_chart: {
    title: "Daily Spend Categorized by Offering/Service",
    datasets: [
      {
        name: "Amazon RelationalDataase Service",
        data: [
          0.482, 0.485, 0.485, 0.485, 0.485, 0.485, 0.485, 0.485, 0.485, 0.29,
          0.077, 0.077, 0.077, 0.077, 0.077, 0.077, 0.265, 0.485, 0.485, 0.485,
          0.485, 0.485, 0.485, 0.485, 0.485, 0.485, 0.485, 0.485, 0.485, 0.485,
          0.485, 0.482, 0.482, 0.482, 0.482, 0.342, 0.074, 0.074, 0.312, 0.482,
          0.482, 0.482, 0.482, 0.482, 0.482, 0.482, 0.482, 0.482, 0.482, 0.482,
          0.482, 0.482, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
      },
      {
        name: "Amazon Simple Queue Service",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.001,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.003, 0.0, 0.0, 0.0, 0.0, 0.0, 0.002, 0.002,
          0.001, 0.003, 0.0, 0.0, 0.002, 0.0, 0.0, 0.0, 0.001, 0.0, 0.0, 0.002,
          0.0, 0.001, 0.001, 0.0, 0.0, 0.0, 0.0, 0.002, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
      },
    ],
    yaxis: {
      title: "cost($)",
      minValue: 0,
      maxValue: 1,
    },
    xaxis: {
      categories: [
        "08-23",
        "",
        "",
        "",
        "",
        "",
        "08-29",
        "",
        "",
        "",
        "",
        "",
        "",
        "09-05",
        "",
        "",
        "",
        "",
        "",
        "",
        "09-12",
        "",
        "",
        "",
        "",
        "",
        "",
        "09-19",
        "",
        "",
        "",
        "",
        "",
        "",
        "09-26",
        "",
        "",
        "",
        "",
        "",
        "",
        "10-03",
        "",
        "",
        "",
        "",
        "",
        "",
        "10-10",
        "",
        "",
        "",
        "",
        "",
        "",
        "10-17",
        "",
        "",
        "",
        "",
      ],
      title: "date",
    },
    name: "Other Services",
  },
};

export const BalanceForecast = {
  other_service_area_chart: {
    title: "Daily Spend Categorized by Offering/Service",
    datasets: [
      {
        name: "AmazonCloudWatch",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.001, 0.0, 0.0, 0.0, 0.0, 0.0, 0.001, 0.001, 0.0,
          0.001, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.007, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
      },
      {
        name: "CodeBuild",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0,
        ],
      },
    ],
    yaxis: {
      title: "cost($)",
      minValue: 0,
      maxValue: 1,
    },
    xaxis: {
      categories: [
        "08-23",
        "",
        "",
        "",
        "",
        "",
        "08-29",
        "",
        "",
        "",
        "",
        "",
        "",
        "09-05",
        "",
        "",
        "",
        "",
        "",
        "",
        "09-12",
        "",
        "",
        "",
        "",
        "",
        "",
        "09-19",
        "",
        "",
        "",
        "",
        "",
        "",
        "09-26",
        "",
        "",
        "",
        "",
        "",
        "",
        "10-03",
        "",
        "",
        "",
        "",
        "",
        "",
        "10-10",
        "",
        "",
        "",
        "",
        "",
        "",
        "10-17",
        "",
        "",
        "",
        "",
      ],
      title: "date",
    },
    name: "Other Services",
  },
};

export const TransactionFrequency = {
  series: [
    {
      name: "Income Frequency",
      type: "column",
      data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6],
    },
    {
      name: "Expense Frequency",
      type: "column",
      data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5],
    },
    {
      name: "Income Size",
      type: "line",
      data: [20, 29, 37, 36, 44, 45, 50, 58],
    },
    {
      name: "Expense Size",
      type: "line",
      data: [24, 21, 30, 33, 40, 49, 55, 58],
    },
  ],
  options: {
    chart: {
      height: "100%",
      type: "line",
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [1, 1, 4],
    },
    title: {
      text: "",
      align: "left",
      offsetX: 110,
    },
    xaxis: {
      categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
    },
    yaxis: [
      {
        seriesName: "Income",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#008FFB",
        },
        labels: {
          style: {
            colors: "#008FFB",
          },
        },
        title: {
          text: "",
          style: {
            color: "#008FFB",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        seriesName: "Expense",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#00E396",
        },
        labels: {
          style: {
            colors: "#00E396",
          },
        },
        title: {
          text: "",
          style: {
            color: "#00E396",
          },
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft",
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
    },
  },
};

export const AccountExpenseVariance = {
  series: [
    {
      name: "Account Balance",
      type: "area",
      data: [22, 55, 31, 47, 31],
    },
    {
      name: "Expense Variance",
      type: "line",
      data: [30, 30, 30, 30, 30],
    },
  ],
  options: {
    chart: {
      // height: 350,
      type: "line",
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "solid",
      opacity: [0.35, 1],
    },
    labels: ["2024-02", "2024-04", "2024-06", "2024-08", "2024-10"],
    markers: {
      size: 0,
    },
    yaxis: [
      // {
      //   title: {
      //     text: 'Series A',
      //   },
      // },
      // {
      //   opposite: true,
      //   title: {
      //     text: 'Series B',
      //   },
      // },
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " points";
          }
          return y;
        },
      },
    },
  },
};

// this is data of category analyze
const CategorizationData = {
  nodes: [
    { id: "Salary", title: "Salary (88.32%)" },
    { id: "Credit Card Rewards", title: "Credit Card Rewards" },
    { id: "Dividends", title: "Dividends (4.40%)" },
    { id: "Interest", title: "Interest (2.47%)" },
    { id: "Earned Income", title: "Earned Income (88%)" },
    { id: "Passive Income", title: "Passive Income (12%)" },
    { id: "Income", title: "Income (100%)" },
    { id: "Financial Independence", title: "Financial Independence (20%)" },
    { id: "Deduction", title: "Deduction (20%)" },
    { id: "Core Expenses", title: "Core Expenses (53%)" },
    { id: "Disposable Income", title: "Disposable Income (7%)" },
    { id: "Investment", title: "Investment (7%)" },
    { id: "Pension", title: "Pension (9%)" },
    { id: "Income Tax", title: "Income Tax (8%)" },
    { id: "Social Justice", title: "Social Justice (11%)" },
    { id: "Bill Expenses", title: "Bill Expenses (13%)" },
    { id: "Food", title: "Food (16%)" },
    { id: "Personal Care", title: "Personal Care (11%)" },
    { id: "Transportation", title: "Transportation (14%)" },
    { id: "Real Estate", title: "Real Estate (5%)" },
    { id: "Emergency Fund", title: "Emergency Fund" },
    { id: "Leisure", title: "Leisure (2%)" },
  ],
  edges: [
    {
      source: "Salary",
      target: "Earned Income",
      value: 25.6,
    },
    {
      source: "Salary",
      target: "Earned Income",
      value: 24.6,
    },
    { source: "Credit Card Rewards", target: "Earned Income", value: 2 },
    { source: "Dividends", target: "Passive Income", value: 4.4 },
    { source: "Interest", target: "Passive Income", value: 1.47 },
    { source: "Earned Income", target: "Income", value: 54.6 },
    { source: "Passive Income", target: "Income", value: 5.87 },
    { source: "Income", target: "Financial Independence", value: 12.2 },
    { source: "Income", target: "Deduction", value: 11.7 },
    { source: "Income", target: "Core Expenses", value: 28.7 },
    { source: "Income", target: "Disposable Income", value: 2 },
    { source: "Income", target: "Core Expenses", value: 3 },
    { source: "Income", target: "Disposable Income", value: 1.47 },
    { source: "Financial Independence", target: "Investment", value: 3.92 },
    { source: "Financial Independence", target: "Pension", value: 5.36 },
    { source: "Deduction", target: "Income Tax", value: 4.84 },
    { source: "Deduction", target: "Social Justice", value: 6.77 },
    { source: "Core Expenses", target: "Bill Expenses", value: 7.58 },
    { source: "Core Expenses", target: "Food", value: 9.33 },
    { source: "Core Expenses", target: "Personal Care", value: 6.49 },
    { source: "Core Expenses", target: "Transportation", value: 8.25 },
    { source: "Disposable Income", target: "Real Estate", value: 2 },
    { source: "Disposable Income", target: "Leisure", value: 1.47 },
  ],
};

export default CategorizationData;
