import React, { useState, memo, useCallback } from "react";
import { CustomButton } from "../../../../components/index";
import { FontAwesomeIcon } from "../../../../import/FontAwesomeIcons";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import "./EditableTable.css";
import UserManagementTemplateModal from "../TemplateModal/UserManagementTemplateModal";

const EditableTable = ({ headers, data, onUpdateData, masterData }) => {
  const [show, setShow] = useState(false);
  const [isEditingID, setIsEditingID] = useState(null);
  const [editContent, setEditContent] = useState({});

  const handleEdit = (id) => {
    const updatedData = data?.find((form) => form.id === id);
    setShow(true);
    setEditContent(updatedData);

    setIsEditingID(id);
  };

  const handleSave = useCallback(
    (id) => {
      const updatedData = data?.map((row) => {
        if (row.id === id) {
          return { ...row, ...editContent };
        }
        return row;
      });
      onUpdateData(updatedData);
      setIsEditingID(null);
      setShow(false);
    },
    [editContent, data, onUpdateData]
  );

  const handleChange = useCallback(
    (id, field, value) => {
      setEditContent({ ...editContent, [field]: value });
    },
    [editContent]
  );

  return (
    <div>
      <Table bordered hover responsive className="table-bootstrap outer-border">
        <thead>
          <tr>
            {headers?.map((header, index) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {masterData?.map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>{row.name}</td>
              <td>{row.roll}</td>
              <td>{row.created}</td>
              <td>
                <span
                  className={
                    row.Status === "ACTIVE"
                      ? "status-active"
                      : row.Status === "INACTIVE"
                      ? "status-inactive"
                      : row.Status === "PENDING"
                      ? "status-pending"
                      : ""
                  }
                  style={{
                    display: "inline-block",
                    padding: "4px 6px",
                    borderRadius: "100px",
                    width: "100px",
                  }}
                >
                  {row.Status}
                </span>
              </td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "41px",
                }}
              >
                <CustomButton
                  size="sm"
                  variant="primary-outline"
                  onClick={() => handleEdit(row.id)}
                >
                  <FontAwesomeIcon icon={faPencil} />
                </CustomButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {isEditingID && (
        <UserManagementTemplateModal
          show={show}
          setShow={setShow}
          save={() => handleSave(isEditingID)}
          editableContent={editContent}
          ModalHeading="Edit The User Details"
          handleChange={handleChange}
          DataCheckID={isEditingID}
        />
      )}
    </div>
  );
};

export default memo(EditableTable);
