import React, { useContext, useEffect, Fragment } from "react";
import "./BillingMatrics.css";
import RangeDatePicker from "../Components/RangeDatePicker/RangeDatePicker";
import {
  BillingBarChart,
  BillingHeatMap,
  BillingAreaChart,
  BillingPieChart,
  BillingTreeMapChart,
} from "../charts";
import TabsContainer from "../Components/TabsContainer/TabsContainer";
import ParentContext from "../../InferIq-Solution/context/context";
import useBillingMatrics from "../Hooks/useBillingMatrics";
import Spinner from "../../InferIq-Service-Dashboard/Spinner/Spinner";
import { CustomButton } from "../../InferIq-Solution/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";

const BillingMatrics = () => {
  const { state, dispatch } = useContext(ParentContext);
  const { billingMetricesAllResponse, loading, allChartsBillingDates } = state;
  const { fetchAllChartsBillingData } = useBillingMatrics();

  const handleAllGraphsDateChange = (dateObjects) => {
    dispatch({ type: "SET_ALL_CHARTS_BILLING_DATES", payload: dateObjects });
  };

  useEffect(() => {
    fetchAllChartsBillingData();
  }, []);

  return (
    <div className="billing-matrics">
      <div className="billing-matrics-header-pannel">
        <CustomButton
          variant="light"
          size="sm"
          onClick={() => fetchAllChartsBillingData()}
        >
          <FontAwesomeIcon icon={faRotateRight} />
        </CustomButton>
        <RangeDatePicker
          billingDates={allChartsBillingDates}
          handleDateChange={handleAllGraphsDateChange}
          dateFormat="YYYY MMM DD"
          minDate="2020 nov 10"
          maxDate={new Date()}
          handleSubmit={fetchAllChartsBillingData}
        />
      </div>
      <div className="billing-matrics-body-pannel">
        {loading ? (
          <div className="rm-fallback-ui">
            <div>
              <Spinner />
            </div>
            <span className="para">Lodaing Data,Please Wait...</span>
          </div>
        ) : (
          <Fragment>
            <TabsContainer
              title={billingMetricesAllResponse?.other_service_Barchart?.title}
              chartOneTitle={
                billingMetricesAllResponse?.other_service_Barchart?.name
              }
              chartOne={
                <BillingBarChart
                  response={billingMetricesAllResponse?.other_service_Barchart}
                  showLegend={false}
                />
              }
              modalChartOne={
                <BillingBarChart
                  response={billingMetricesAllResponse?.other_service_Barchart}
                  showLegend={true}
                />
              }
              chartTwoTitle={billingMetricesAllResponse?.EC2_Barchart?.name}
              chartTwo={
                <BillingBarChart
                  response={billingMetricesAllResponse?.EC2_Barchart}
                  showLegend={false}
                />
              }
              modalChartTwo={
                <BillingBarChart
                  response={billingMetricesAllResponse?.EC2_Barchart}
                  showLegend={true}
                />
              }
            />
            <TabsContainer
              title={billingMetricesAllResponse?.other_service_pipechart?.title}
              chartOneTitle={
                billingMetricesAllResponse?.other_service_pipechart?.name
              }
              chartOne={
                <BillingPieChart
                  response={billingMetricesAllResponse?.other_service_pipechart}
                  showLegend={false}
                />
              }
              modalChartOne={
                <BillingPieChart
                  response={billingMetricesAllResponse?.other_service_pipechart}
                  showLegend={true}
                />
              }
              chartTwoTitle={billingMetricesAllResponse?.EC2_piechart?.name}
              chartTwo={
                <BillingPieChart
                  response={billingMetricesAllResponse?.EC2_piechart}
                  showLegend={false}
                />
              }
              modalChartTwo={
                <BillingPieChart
                  response={billingMetricesAllResponse?.EC2_piechart}
                  showLegend={true}
                />
              }
            />
            <TabsContainer
              title={
                billingMetricesAllResponse?.other_service_area_chart?.title
              }
              chartOneTitle={
                billingMetricesAllResponse?.other_service_area_chart?.name
              }
              chartOne={
                <BillingAreaChart
                  response={
                    billingMetricesAllResponse?.other_service_area_chart
                  }
                  showLegend={false}
                />
              }
              modalChartOne={
                <BillingAreaChart
                  response={
                    billingMetricesAllResponse?.other_service_area_chart
                  }
                  showLegend={true}
                />
              }
              chartTwoTitle={billingMetricesAllResponse?.EC2_area_chart?.name}
              chartTwo={
                <BillingAreaChart
                  response={billingMetricesAllResponse?.EC2_area_chart}
                  showLegend={false}
                />
              }
              modalChartTwo={
                <BillingAreaChart
                  response={billingMetricesAllResponse?.EC2_area_chart}
                  showLegend={true}
                />
              }
            />
            <div className="billing_tremap_cont">
              <div className="para" style={{ fontWeight: "500" }}>
                {billingMetricesAllResponse?.treemap?.title}
              </div>

              <BillingTreeMapChart
                response={billingMetricesAllResponse?.treemap}
              />
            </div>
            {billingMetricesAllResponse?.heatmap && (
              <div className="billing_heatmap_cont">
                <div className="para">
                  {billingMetricesAllResponse?.heatmap?.title}
                </div>

                <BillingHeatMap
                  response={billingMetricesAllResponse?.heatmap}
                />
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default BillingMatrics;
