import { FontAwesomeIcon } from "../../../../../import/FontAwesomeIcons";
import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

export default function TemplateHeader({
  title,
  addIcon,
  openAndHandleAddTemplateModal,
  isEditTemplateDisabled,
  addButtonText,
}) {
  // this is used for tooltip of add label button
  const renderTooltip = (props) => (
    <Tooltip id="tooltip-left" {...props}>
      {addButtonText}
    </Tooltip>
  );

  return (
    <div className="template-container-header d-flex justify-content-between align-items-center ">
      <h6 style={{ color: "#102447"}}>{title}</h6>

      <div className="add-new-template-btn">
        {addIcon && (
          <OverlayTrigger
            key={"left"}
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={renderTooltip}
          >
            <Button
              size="sm"
              className=" bg-primary text-white border-0 "
              onClick={() => {
                openAndHandleAddTemplateModal();
              }}
              disabled={isEditTemplateDisabled}
            >
              <FontAwesomeIcon icon={addIcon} style={{ color: "white" }} />
            </Button>
          </OverlayTrigger>
        )}
      </div>
    </div>
  );
}
