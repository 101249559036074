import React from "react";
import { CustomButton } from "../../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function RenderTaxForms({
  taxForms,
  handleEdit,
  handleDelete,
  isDeleteQuestionDisabled,
  isAddQuestionDisabled,
  editIcon,
  deleteIcon,
}) {
  return taxForms.map(({ id, label }) => (
    <div key={id} className="template-items">
      <span>{label}</span>
      <div className="template-edit-and-delete">
        <CustomButton
          size="sm"
          variant="primary"
          onClick={() => handleEdit(id)}
          disabled={isAddQuestionDisabled}
        >
          <FontAwesomeIcon icon={editIcon} />
        </CustomButton>
        <CustomButton
          size="sm"
          variant="danger"
          onClick={() => handleDelete(id)}
          disabled={isDeleteQuestionDisabled}
        >
          <FontAwesomeIcon icon={deleteIcon} />
        </CustomButton>
      </div>
    </div>
  ));
}
