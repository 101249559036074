import React, { Fragment } from "react";
import Input from "../../../components/Input/Input";
import CustomButton from "../../../components/Button/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import SignUpInputData from "../utils/SignUpInputData";

const SignUpForm = ({
  handleSubmit,
  signUpFormData,
  formError,
  showVerifiSignUpForm,
  handleInputChange,
  showSignIn,
  showPassword,
  handleShowPassword,
  handleTermsAndConditions,
  termsAndConditions,
}) => {
  return (
    <div>
      {!showVerifiSignUpForm && (
        <form className="signin_form_container" onSubmit={handleSubmit}>
          <div
            className="signin_form_input"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
            }}
          >
            {SignUpInputData.map((item) => {
              const { id, label, type, name, placeholder, required } = item;
              const inputProps = {
                ...(name === "password" || name === "confirmPassword" ? { maxLength: 20, minLength: 8 } : {}),
              };
              return (
                <Fragment key={id}>
                  <Input
                    label={label}
                    type={showPassword ? "text" : type}
                    name={name}
                    value={signUpFormData[name]}
                    placeholder={placeholder}
                    handleChange={handleInputChange}
                    required={required}
                    {...inputProps}
                  />
                </Fragment>
              );
            })}
          </div>
          <div className="signin_form_btn_cont">
            <div className="signin_form_btn_redirect">
              <div className="signin_form_show_pass">
                <input
                  type="checkbox"
                  checked={showPassword}
                  onChange={handleShowPassword}
                />
                <div style={{ marginLeft: "5px" }}>Show Password</div>
              </div>
              <span onClick={showSignIn}>Sign In</span>
            </div>
            <CustomButton variant="primary" size="md" type="submit">
              <FontAwesomeIcon
                style={{ transform: "rotate(270deg)", marginRight: "5px" }}
                icon={faArrowRightToBracket}
              />
              Sign Up
            </CustomButton>
            <div
              className="signin_form_show_pass"
              style={{ marginTop: "15px" }}
            >
              <input
                type="checkbox"
                checked={termsAndConditions}
                onChange={handleTermsAndConditions}
              />
              <div style={{ marginLeft: "5px" }}>
                <Link to="/termsAndConditions" target="_blank">
                  I accept the terms and Conditions.
                </Link>
              </div>
            </div>
          </div>
          {formError && <p className="signin_form_error">{formError}</p>}
        </form>
      )}
    </div>
  );
};

export default SignUpForm;
