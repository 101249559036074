import React, { memo } from "react";
import Chart from "react-apexcharts";
import colors from "../../InferIq-Solution/utils/graphColors";
// import res from "./res";

const BillingLineChart = ({ response, showLegend }) => {
  const state = {
    series:response?.datasets?.map((dataset) => ({
      name: dataset?.name,
      data: dataset?.data,
    })),
    options: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: false,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      colors: [...colors],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      grid: {
        show: false,
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0,
        },
      },
      markers: {
        size: 0,
      },
      xaxis: {
        categories: response?.xaxis?.categories,
        title: {
          text: response?.xaxis?.title,
        },
      },
      yaxis: {
        title: {
          text: response?.yaxis?.title,
        },
        min: response?.yaxis?.minValue,
        max: response?.yaxis?.maxValue,
      },
      legend: {
        position: "right",
        horizontalAlign: "center",
        show: showLegend,
        // floating: true,
        // offsetY: -25,
        // offsetX: -5,
      },
    },
  };

  return (
    <Chart
      options={state?.options}
      series={state?.series}
      type="line"
      height={350}
    />
  );
};

export default memo(BillingLineChart);
