import { Col, Row } from "react-bootstrap";
import { BillingLineChart } from "../../../../../../InferIq-Billing-Dashboard/charts";
import TabsContainer from "../../../../../../InferIq-Billing-Dashboard/Components/TabsContainer/TabsContainer";
import {
  IncomeReport,
  IncomeForecast,
  ExpenseReport,
  ExpenseForecast,
  BalanceReport,
  BalanceForecast,
  TransactionFrequency,
  AccountExpenseVariance,
} from "../../../ChartsData";
import ReactApexChart from "react-apexcharts";
import AnalysisFilter from "../AnalysisFilter/AnalysisFilter";
import "../../components/AnalysisTabs.css";

const TimeSeries = () => {
  return (
    <Col className="d-flex flex-wrap time-series">
      <AnalysisFilter />
      <Row className="analysis-main-wrap d-flex justify-content-evenly mx-0">
        <Col className="TScharts" xs={5}>
          <h4 className="charts-header">Income Report</h4>
          <TabsContainer
            chartOne={
              <BillingLineChart
                response={IncomeReport?.other_service_area_chart}
                showLegend={false}
              />
            }
            modalChartOne={
              <BillingLineChart
                response={IncomeReport?.other_service_area_chart}
                showLegend={true}
              />
            }
          />
        </Col>
        <Col className="TScharts" xs={5}>
          <h4 className="charts-header">Income Forecast</h4>
          <TabsContainer
            chartOne={
              <BillingLineChart
                response={IncomeForecast?.other_service_area_chart}
                showLegend={false}
              />
            }
            modalChartOne={
              <BillingLineChart
                response={IncomeForecast?.other_service_area_chart}
                showLegend={true}
              />
            }
          />
        </Col>
        <Col className="TScharts" xs={5}>
          <h4 className="charts-header">Expenses Report</h4>
          <TabsContainer
            chartOne={
              <BillingLineChart
                response={ExpenseReport?.other_service_area_chart}
                showLegend={false}
              />
            }
            modalChartOne={
              <BillingLineChart
                response={ExpenseReport?.other_service_area_chart}
                showLegend={true}
              />
            }
          />
        </Col>
        <Col className="TScharts" xs={5}>
          <h4 className="charts-header">Expenses Forcast</h4>
          <TabsContainer
            chartOne={
              <BillingLineChart
                response={ExpenseForecast?.other_service_area_chart}
                showLegend={false}
              />
            }
            modalChartOne={
              <BillingLineChart
                response={ExpenseForecast?.other_service_area_chart}
                showLegend={true}
              />
            }
          />
        </Col>
        <Col className="TScharts" xs={5}>
          <h4 className="charts-header">Balance Report</h4>
          <TabsContainer
            chartOne={
              <BillingLineChart
                response={BalanceReport?.other_service_area_chart}
                showLegend={false}
              />
            }
            modalChartOne={
              <BillingLineChart
                response={BalanceReport?.other_service_area_chart}
                showLegend={true}
              />
            }
          />
        </Col>
        <Col className="TScharts" xs={5}>
          <h4 className="charts-header">Balance Forecast</h4>
          <TabsContainer
            chartOne={
              <BillingLineChart
                response={BalanceForecast?.other_service_area_chart}
                showLegend={false}
              />
            }
            modalChartOne={
              <BillingLineChart
                response={BalanceForecast?.other_service_area_chart}
                showLegend={true}
              />
            }
          />
        </Col>
        <Col className="TScharts" xs={5}>
          <h4 className="charts-header">
            Transaction Frequency and Size by day
          </h4>
          <TabsContainer
            chartOne={
              <ReactApexChart
                options={TransactionFrequency?.options}
                series={TransactionFrequency?.series}
                type="line"
              />
            }
            modalChartOne={
              <ReactApexChart
                options={TransactionFrequency?.options}
                series={TransactionFrequency?.series}
                type="line"
              />
            }
          />
        </Col>
        <Col className="TScharts" xs={5}>
          <h4 className="charts-header">Account balance Vs Expense Variance</h4>
          <TabsContainer
            chartOne={
              <ReactApexChart
                options={AccountExpenseVariance?.options}
                series={AccountExpenseVariance?.series}
                type="line"
              />
            }
            modalChartOne={
              <ReactApexChart
                options={AccountExpenseVariance?.options}
                series={AccountExpenseVariance?.series}
                type="line"
              />
            }
          />
        </Col>
      </Row>
    </Col>
  );
};

export default TimeSeries;
