const reducer = (state, action) => {
  const actionHandlers = {
    SET_REDAC_DOC: () => ({ ...state, redactionDoc: action.payload }),
    SET_REDAC_SEL_FIELDS: () => ({
      ...state,
      redactionSelectedFields: action.payload,
    }),
    SET_BACKEND_RES: () => ({ ...state, backendResponse: action.payload }),
    SET_RES_ERROR: () => ({ ...state, responseError: action.payload }),
    SET_ERR_MSG: () => ({ ...state, errorMessage: action.payload }),
    SET_SEL_VALUE: () => ({ ...state, selectedValue: action.payload }),
    SET_SERVICE_TYPE: () => ({ ...state, serviceType: action.payload }),
    SET_LOADING: () => ({ ...state, loading: action.payload }),
    SET_UPLOADED_FILES: () => ({ ...state, uploadedFiles: action.payload }),
    SET_MULTIPLE_FILE_CHECK: () => ({
      ...state,
      multipleFileCheck: action.payload,
    }),
    SET_SEL_OPTION: () => ({ ...state, selectedOption: action.payload }),
    SET_NUM_PAGES: () => ({ ...state, numPages: action.payload }),
    SET_PAGE_NUMBER: () => ({ ...state, pageNumber: action.payload }),
    SET_SCALE: () => ({ ...state, scale: action.payload }),
    SET_ROTATE: () => ({ ...state, rotate: action.payload }),
    SET_IS_OPEN: () => ({ ...state, isOpen: action.payload }),
    SET_BREADCRUMBS: () => ({ ...state, breadcrumbs: action.payload }),
    SET_RESPONSE_TEXT: () => ({ ...state, responseText: action.payload }),
    SET_IMG_URL: () => ({ ...state, imageDataURL: action.payload }),
    SET_REDAC_OUT_FILE: () => ({
      ...state,
      redactionOutputFile: action.payload,
    }),
    SET_ACTIVE_INDEX: () => ({ ...state, activeIndex: action.payload }),
    SET_FILE: () => ({ ...state, file: action.payload }),
    SET_EMAIL: () => ({ ...state, email: action.payload }),
    SET_RESET_UPLOAD_FILE: () => ({ ...state, resetUploads: action.payload }),
    SET_DATA_REDACTION_CHECK: () => ({
      ...state,
      dataRedactionCheck: action.payload,
    }),
    SET_AUTO_PII_REDACTION_CHECK: () => ({
      ...state,
      autoPiiRedactionCheck: action.payload,
    }),
    SET_ACTIVE_TAB: () => ({ ...state, activeTab: action.payload }),
    SET_GENAI_TEMPLATE_RADIO_OPTIONS_AND_CHECK: () => ({
      ...state,
      genAiTemplateRadioOptionsAndCheck: action.payload,
    }),
    SET_GENAI_NEWLY_ADDED_QUESTIONS: () => ({
      ...state,
      genaiNewlyAddedQuestions: action.payload,
    }),
    SET_GENAI_REDACTION_OUTPUT_EDITING: () => ({
      ...state,
      genaiRedactionOutputEditing: action.payload,
    }),
    SET_GENAI_REDACTION_CHECKFIELDS: () => ({
      ...state,
      genaiRedactionCheckfields: action.payload,
    }),
    SET_GENAI_OUTPUT: () => ({ ...state, genaiOutput: action.payload }),
    SET_GENAI_REDACTION_OUTPUT_EDITED_TEXT: () => ({
      ...state,
      genaiRedactionOutputEditedText: action.payload,
    }),
    SET_SUBMIT_CLICKED: () => ({ ...state, submitClicked: action.payload }),
    SET_GET_LATEST_REQUEST_ID: () => ({
      ...state,
      getLatestRequestId: action.payload,
    }),
    SET_CURRENT_PAGE: () => ({ ...state, currentPage: action.payload }),
    SET_BANKSTATEMENT_ACTIVE_TAB: () => ({
      ...state,
      bankStatementActiveTab: action.payload,
    }),
    SET_RENTROLL_ACTIVE_TAB: () => ({
      ...state,
      rentRollActiveTab: action.payload,
    }),
    SET_FILE_REMOVED: () => ({ ...state, fileRemoved: action.payload }),
    SET_SELECTED_LLM_TYPE: () => ({
      ...state,
      selectedLlmType: action.payload,
    }),
    SET_RENT_ROLL_NEWLY_ADDED_QUESTIONS: () => ({
      ...state,
      rentRollNewlyAddedQuestions: action.payload,
    }),
    SET_DISPLAY_TEXT: () => ({ ...state, displayText: action.payload }),
    SET_CRE_RESPONSE: () => ({ ...state, creResponse: action.payload }),
    SET_DASHBOARD_RESPONSE: () => ({
      ...state,
      dashboardResponse: action.payload,
    }),
    SET_CRE_HISTORY_RESPONSE: () => ({
      ...state,
      creHistoryResponse: action.payload,
    }),
    SET_NOTIFICATION: () => ({ ...state, notification: action.payload }),
    SET_RENT_ROLL_PREVIEW_FILE: () => ({
      ...state,
      rentRollPreviewFile: action.payload,
    }),
    SET_BANK_STT_PREVIEW_FILE: () => ({
      ...state,
      bankSttPreviewFile: action.payload,
    }),
    SET_GET_REDACTION_OUTPUT_EVENT_KEY: () => ({
      ...state,
      getRedactioOutputEventKey: action.payload,
    }),
    SET_GET_TEMP_QUES_LENGTH: () => ({
      ...state,
      templateQuestionsLength: action.payload,
    }),
    SET_STATUS: () => ({ ...state, status: action.payload }),
    SET_GEN_AI_HISTORY_RESPONSE: () => ({
      ...state,
      genAiHistoryResponse: action.payload,
    }),
    SET_FORM_DATA: () => ({ ...state, formData: action.payload }),
    SET_REQUEST_MONITORING_DATA: () => ({
      ...state,
      requestMonitoringData: action.payload,
    }),
    SET_PA_ACTIVE_TAB: () => ({ ...state, paActiveTab: action.payload }),
    SET_REQUEST_MONITORING_ERR_LOGS: () => ({
      ...state,
      requestMonitoringErrLogs: action.payload,
    }),
    SET_BILLING_METRICES_ALL_RES: () => ({
      ...state,
      billingMetricesAllResponse: action.payload,
    }),
    SET_ALL_CHARTS_BILLING_DATES: () => ({
      ...state,
      allChartsBillingDates: action.payload,
    }),
    SET_Client_ONBOARDING_ACTIVE_TAB: () => ({
      ...state,
      clientOnboardingActiveTab: action.payload,
    }),
    SET_CHECK_BOX_SERVICES_SELECTED_VALUES: () => ({
      ...state,
      checkBoxServicesValues: action.payload,
    }),
    SET_CHECK_BOX_LLMS_SELECTED_VALUES: () => ({
      ...state,
      checkBoxllmsValues: action.payload,
    }),
    SET_CHECK_BOX_ENV_SELECTED_VALUES: () => ({
      ...state,
      checkBoxEnvValues: action.payload,
    }),
    SET_CLIENT_COMPANY_NAME: () => ({
      ...state,
      clientCompanyName: action.payload,
    }),
    SET_PROCESSED_FILES_INFO: () => ({
      ...state,
      processedFilesInfo: action.payload,
    }),

    SET_SIGN_IN_FORM_DATA: () => ({ ...state, signInFormData: action.payload }),
    SET_SIGN_UP_FORM_DATA: () => ({ ...state, signUpFormData: action.payload }),
    SET_FORGET_PASS_FORM_DATA: () => ({
      ...state,
      forgetPasswordFormData: action.payload,
    }),
    SET_FORM_ERROR: () => ({ ...state, formError: action.payload }),
    SET_IS_SIGN_IN: () => ({ ...state, isSignIn: action.payload }),
    SET_IS_SIGN_UP: () => ({ ...state, isSignUp: action.payload }),
    SET_FORGOT_PASSWORD: () => ({ ...state, isForgotPassword: action.payload }),
    SET_SHOW_PASSWORD: () => ({ ...state, showPassword: action.payload }),
    SET_TERMS_AND_CONDITIONS: () => ({
      ...state,
      termsAndConditions: action.payload,
    }),
    SET_SIGNUP_OTP: () => ({ ...state, signUpOtp: action.payload }),
    SET_SIGNIN_BACKEND_RES: () => ({
      ...state,
      signInBackendRes: action.payload,
    }),
    SET_SIGNUP_BACKEND_RES: () => ({
      ...state,
      signUpBackendRes: action.payload,
    }),
    SET_FRGT_PASS_BACKEND_RES: () => ({
      ...state,
      forgetPassBackendRes: action.payload,
    }),
    SET_SIGNUP_VERIFI_BACKEND_RES: () => ({
      ...state,
      signUpVerifiBackendRes: action.payload,
    }),

    SET_SELECTED_PAGES: () => ({ ...state, selectedPages: action.payload }),
    SET_GET_SELECTED_PAGES: () => ({
      ...state,
      getSelectedPages: action.payload,
    }),

    SET_EXCEL_DATA: () => ({ ...state, excelData: action.payload }),
    SET_EXCEL_SHEET_INDEX: () => ({
      ...state,
      excelSheetIndex: action.payload,
    }),
    SET_UPDATE_PASSWORD_STAT: () => ({
      ...state,
      getUpdatePasswordStatus: action.payload,
    }),
  };

  const handler = actionHandlers[action.type];
  if (handler) {
    return handler();
  }

  throw new Error(`Unhandled action type: ${action.type}`);
};

export default reducer;
