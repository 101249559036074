import {
  InvoiceImg,
  BankFormImg,
  FinancialStatementImg,
  PlainTextImg,
  LegalDocImg,
  TaxForm,
  ApplicationForm,
} from "../images/sideContent/index";

let ExtractionImgs = [
  {
    id: 1,
    img: TaxForm,
    title: "Tax Forms",
    Value: "Tax Forms",
    desc: [
      {
        id: 1,
        cont: "Users can upload tax forms like 1120,1120s,1065,W8 series,W9 & W2.",
      },
      {
        id: 2,
        cont: "The Al model extracts the key information from the tax form.",
      },
      {
        id: 3,
        cont: "Users can identify the variables that need to be redacted. Eg: address",
      },
      { id: 4, cont: "The system redacts all entities of that category." },
    ],
  },
  {
    id: 2,
    img: InvoiceImg,
    title: "Invoice",
    Value: "Invoice & Receipts",
    desc: [
      {
        id: 1,
        cont: `An Invoice is an itemized commercial
          document that records the products or
          services delivered to the customer, the total
          amount due, and the preferred payment
          method. The seller can send either paper or
          electronic invoices to the customer. They
          often come with agreed-upon terms and
          conditions, such as the payment due date for
          the services rendered.`,
      },
    ],
  },
  {
    id: 3,
    img: FinancialStatementImg,
    title: "Financial Statements",
    Value: "Financial Statement",
    desc: [
      {
        id: 1,
        cont: `Financial statements are a set of documents
          that show your company's financial status at
          a specific point in time. They include key data
          on what your company owns and owes and
          how much money it has made and spent.
          Balance sheet: The balance sheet provides
          an overview ot assets, liabilities, and
          shareholders' equity as a snapshot in time.
          Income Statement The income statement
          primarily focuses on a company's revenues
          and expenses during a particular period.
          Once expenses are subtracted from
          revenues, the statement produces a
          company's proflt figure called net income.`,
      },
    ],
  },
  {
    id: 5,
    img: PlainTextImg,
    title: "Plain Text",
    Value: "Plain Text",
    desc: [
      {
        id: 1,
        cont: `Suppots handwritten text and unstructured
          text from documents.`,
      },
      {
        id: 2,
        cont: `This can include text that is organized in
          the form of paragraphs`,
      },
      {
        id: 3,
        cont: "These documents may not have any fixed layouts or fixed data points.",
      },
      {
        id: 4,
        cont: "The system supports sentiment analysis, Named entity recognition, language and PII",
      },
    ],
  },
  {
    id: 6,
    img: LegalDocImg,
    title: "Legal documents or contracts",
    Value: "Legal docs & Contracts",
    desc: [
      {
        id: 1,
        cont: `Legal docs are agreements between two
          or more parties that have been signed by
          mutual assent`,
      },
      {
        id: 2,
        cont: `  An Agreement between parties, creating
          mutual obligations that are enforceable by
          law`,
      },
      {
        id: 3,
        cont: `
          The solution reads the document and
          identifies critical information based on
          context and provides that information to
          the user.`,
      },
    ],
  },
  {
    id: 7,
    img: BankFormImg,
    title: "Bank Forms",
    Value: "Bank Forms",
    desc: [
      {
        id: 1,
        cont: `These are documents used by banks to
          collect the relevant information from a
          potential borrower. These forms can vary in
          format for each bank. Common types of Bank
          forms are - Personal loan applications,
          Business Loan application, KYC forms,
          account opening form, debit / credit card
          application etc`,
      },
    ],
  },
  {
    id: 8,
    img: ApplicationForm,
    title: "Application Form",
    Value: "Application Form",
    desc: [
      {
        id: 1,
        cont: `Streamline college application processing by automatically extracting key information from submitted forms.`,
      },
    ],
  },
];

export { ExtractionImgs };
