import React from "react";
import styles from "./Input.module.css";

const Input = ({
  type,
  label,
  placeholder,
  value,
  required,
  handleChange,
  name,
  pattern,
  ...additionalProps
}) => {
  return (
    <div className={styles.pa_form_input_cont}>
      <label htmlFor="input" className={styles.pa_form_input_label}>
        {label}
      </label>
      <input
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        className={styles.pa_form_input}
        required={required}
        pattern={pattern}
        {...additionalProps}
      />
    </div>
  );
};

export default Input;
