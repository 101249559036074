import React, { memo } from "react";
import { LabelSwitch } from "../../../components/index";

const AdminControlledRedaction = (props) => {
  const { AdminControlledRedactionOptions } = props;
  return (
    <div>
      <h6>Admin Controlled Redaction</h6>
      <hr />
      {AdminControlledRedactionOptions.map(({ id, label, value }) => (
        <div key={id}>
          <LabelSwitch label={label} value={value} />
        </div>
      ))}
    </div>
  );
};

export default memo(AdminControlledRedaction);
