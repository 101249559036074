import React from "react";
import { CustomButton } from "../../../../InferIq-Solution/components";
import "./ReqMTablePagination.css";
import {
  FontAwesomeIcon,
  faCaretLeft,
  faCaretRight,
} from "../../../import/FontAwesomeIcons";

const ReqMTablePagination = (props) => {
  const { currentPage, setCurrentPage, totalPages } = props;

  const handleInputChange = (e) => {
    setCurrentPage(Math.max(1, Math.min(parseInt(e.target.value), totalPages)) || 1);
  };

  return (
    <div className="rm-table-pagination">
      <div>
        <CustomButton
          variant="primary"
          size="sm"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <FontAwesomeIcon icon={faCaretLeft} />
        </CustomButton>
      </div>

      <div className="rm-table-pagination-page-no">
        Page
        <input
          value={currentPage}
          onChange={handleInputChange}
          className="current-page"
        />
        of <span>{totalPages}</span>
      </div>
      <div>
        <CustomButton
          variant="primary"
          size="sm"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <FontAwesomeIcon icon={faCaretRight} />
        </CustomButton>
      </div>
    </div>
  );
};

export default ReqMTablePagination;
