import React, { useContext, useEffect, useState } from "react";
import "./RequestMonitoring.css";
import ReqMTable from "./components/ReqMTable/ReqMTable";
import ParentContext from "../../InferIq-Solution/context/context";
import useRequestMonitoringApiCall from "../Hooks/useRequestMonitoringApiCall";
import Spinner from "../Spinner/Spinner";
import { CustomButton } from "../../InferIq-Solution/components";
import { FontAwesomeIcon, faRotateRight } from "../import/FontAwesomeIcons";

const RequestMonitoring = () => {
  const { state } = useContext(ParentContext);
  const { requestMonitoringData, loading } = state;
  const { getRequestMonitoringData } = useRequestMonitoringApiCall();
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  let containerNo = [];
  const rowSplits = window.innerWidth;
  useEffect(() => {
    if (rowSplits >= 1920) {
      containerNo.push(7);
    } else if (rowSplits >= 1536) {
      containerNo.push(6);
    } else if (rowSplits >= 1280) {
      containerNo.push(5);
    } else if (rowSplits >= 1024) {
      containerNo.push(4);
    } else if (rowSplits >= 768) {
      containerNo.push(3);
    } else if (rowSplits >= 576) {
      containerNo.push(2);
    }
  }, [rowSplits]);

  useEffect(() => {
    const SetPageNumber = currentPage > 1 && setTimeout(() => {
      getRequestMonitoringData(itemsPerPage, currentPage);
    }, 2000);
    currentPage === 1 && getRequestMonitoringData(itemsPerPage, currentPage);
    return () => clearTimeout(SetPageNumber);
  }, [itemsPerPage, currentPage]);

  return (
    <div className="RequestMonitoring-container">
      <div className="RequestMonitoring-container-header">
        <CustomButton
          variant="light"
          size="sm"
          onClick={() => getRequestMonitoringData(itemsPerPage, currentPage)}
        >
          <FontAwesomeIcon icon={faRotateRight} />
        </CustomButton>
      </div>
      {loading ? (
        <div className="rm-fallback-ui">
          <div>
            <Spinner />
          </div>
          <span className="para">Lodaing Data,Please Wait...</span>
        </div>
      ) : (
        <div className="RequestMonitoring-card-container">
          <ReqMTable
            stages_data={requestMonitoringData?.data}
            containerNo={containerNo}
            totalPages={requestMonitoringData?.totalPages}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default RequestMonitoring;
