import { memo } from "react";
import ReactApexChart from "react-apexcharts";

const CategorizationBar = () => {
  const series = [
    {
      name: "Credit",
      type: "column",
      data: [1.5, 2.2, 3.0, 2.8, 3.5, 4.0, 4.5, 5.0],
    },
    {
      name: "Debit",
      type: "column",
      data: [1.0, 1.5, 2.0, 1.8, 2.5, 2.8, 3.0, 3.5],
    },
    {
      name: "Expenses",
      type: "column",
      data: [0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0],
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [2, 2, 4],
    },
  
    tooltip: {
      position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
      offsetY: 30,
      offsetX: 60,
      shared: true,
      intersect: false,
      theme: "dark",
      y: {
        formatter: function (val) {
          return `$${val.toLocaleString()}`;
        },
      },
    },

    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
    },
    yaxis: [
      {
        seriesName: "Credit",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#008FFB",
        },
        labels: {
          style: {
            colors: "#008FFB",
          },
        },
        title: {
          text: "Credit",
          style: {
            color: "#008FFB",
          },
        },
      },
      {
        seriesName: "Debit",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#00E396",
        },
        labels: {
          style: {
            colors: "#00E396",
          },
        },
        title: {
          text: "Debit (in thousands)",
          style: {
            color: "#00E396",
          },
        },
      },
      {
        seriesName: "Expenses",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#FEB019",
        },
        labels: {
          style: {
            colors: "#FEB019",
          },
        },
        title: {
          text: "Expenses (in thousands)",
          style: {
            color: "#FEB019",
          },
        },
      },
    ],

    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetY: 0,
      fontSize: "13px",
      markers: {
        width: 12,
        height: 12,
        radius: 6,
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={350}
    />
  );
};

export default memo(CategorizationBar);
