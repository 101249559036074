import { Col, Form } from "react-bootstrap";

const ReqMTableNumOfLists = ({ itemsPerPage, setItemsPerPage }) => {
  return (
    <Col className="d-flex align-items-center justify-content-end num-of-list">
      <span className="fw-semibold">Num of Lists: </span>
      <Form.Select
        defaultValue={itemsPerPage}
        onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
      >
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </Form.Select>
    </Col>
  );
};

export default ReqMTableNumOfLists;
