const useValidateRoute = () => {
  const validateSolutionRoutes = [
    "/",
    "/faqs",
    "/dashboard",
    "/extraction",
    "/bankStatements",
    "/generativeai",
    "/commercialRealEstate",
    "/redaction",
    "/recognition",
    "/classification",
    "/OutputScreen",
    "/privacyPloicy",
    "/predictiveAnalytics",
    "/profile",
    "/bankstatements",
    "/bankstatements/analysis",
    "/predictiveAnalytics/PredictiveAnalyticsOutputScreen",
    "/finStatExtraction",
  ];

  const validateRequestMonitoringRoutes = ["/faqs", "/requestMonitoring","/history"];

  const validateBillingRoutes = [
    "/faqs",
    "/billingMetrics",
    "/billingInfo",
    "/processedFileInfo",
  ];

  // newadded
  const validateSettingsRoutes = [
    "/faqs",
    "/settings",
    "/adminSetting",
    "/productInfo",
    "/customerSettings",
    "/processMetrics",
    "/systemLogs",
    "/serviceDashboard",
  ];

  const validateModuleSelectionRoutes = ["/faqs", "/moduleselection"];

  return {
    validateSolutionRoutes,
    validateRequestMonitoringRoutes,
    validateBillingRoutes,
    validateSettingsRoutes,
    validateModuleSelectionRoutes,
  };
};

export default useValidateRoute;
