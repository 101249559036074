import React, { memo, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import "./EditTransactionModal.css";
import CustomButton from "../Button/CustomButton";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditAccountDetails = (props) => {
  const { show, setShow, accEditDetails, currentActiveAcc } = props;
  console.log("ACC DETAILS", accEditDetails)
  console.log("currentActiveAcc", currentActiveAcc)
  const [accDetails, setAccDetails] = useState({})
  const [save, setSave] = useState(false)

  const handleAccDetails = () => {
    setSave(true)
    setTimeout(() => {
      setShow(false);
      setSave(false);
    }, 1000);
  }

  useEffect(() => {
    setAccDetails(accEditDetails?.data[0][currentActiveAcc][0])
  }, [currentActiveAcc, accEditDetails])

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="bsEditModal editAccDetailsModal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Modify Account Details
          <FontAwesomeIcon icon={faTimes} onClick={() => setShow(false)} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="bsEditModalForm">
          <div className="wrap">
            {(currentActiveAcc && accDetails) ? (
              Object.entries(accDetails)?.map(
                ([key, value]) => {
                  return (
                    (key == "BANK_NAME" ||
                      key == "BANK_CITY" ||
                      key == "BANK_STATE" ||
                      key == "ACCOUNT_NUMBER" ||
                      key == "ACCOUNT_TYPE" ||
                      key == "ACCOUNT_OWNER_1" ||
                      key == "ACCOUNT_OWNER_2") && (
                      <div key={key} className="editField">
                        <label>{key.replaceAll("_", " ")}</label>
                        <input type="text" value={value} onChange={(e) => {
                          setAccDetails({
                            ...accDetails,
                            [key]: e.target.value
                          });
                        }} />
                      </div>
                    )
                  );
                }
              )
            ) : (
              <p style={{ width: "100%", textAlign: "center" }}>
                No Bank Information Found
              </p>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          size="sm"
          variant="primary"
          onClick={() => setShow(false)}
        >
          Remove
        </CustomButton>
        {save ? (
          <CustomButton
            size="sm"
            variant="success"
            style={{ display: "flex", alignItems: "center" }}
          >
            Saved
            <FontAwesomeIcon
              icon={faCheck}
              style={{ color: "white", marginLeft: "10px" }}
            />
          </CustomButton>
        ) : (
          <CustomButton
            size="sm"
            variant="primary"
            onClick={() => handleAccDetails()}
          >
            Save
          </CustomButton>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default memo(EditAccountDetails);
