import React, { useContext, useMemo } from "react";
import { OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import "./ExtractionTable.css";
import TabTitleContext from "../../../context/TabTitleContext";
import ParentContext from "../../../context/context";
import BankStatementTable from "../BankStatementTable";
import Table from "../Table";
import {
  faFileLines,
  faFileText,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExtractionReport from "../ExtractionReport/ExtractionReport";

const ExtractionTable = (props) => {
  const {
    all_tabs,
    BankStatementResponse,
    kvpTdData,
    tableThData,
    tableTrData,
    selectedRow,
    rowRefs,
    searchRow,
    setSearchRow,
    setSelectedRow,
    showAccReport,
    setShowAccReport,
    serviceType,
    tabAccNumber,
    setTabAccNumber,
  } = props;

  const { currentTab, setCurrentTab } = useContext(TabTitleContext);
  const { state, dispatch } = useContext(ParentContext);
  const { selectedValue, bankStatementActiveTab } = state;

  const isNumber = (value) => {
    return (
      typeof value === "number" || (!isNaN(value) && !isNaN(parseFloat(value)))
    );
  };
  const handleTabSelect = (tabIndex) => {
    const currentAccountTab =
      BankStatementResponse &&
      BankStatementResponse?.data &&
      Object.keys(BankStatementResponse?.data[0]);
    currentAccountTab &&
      currentAccountTab?.length > 0 &&
      setTabAccNumber(currentAccountTab[tabIndex]);
    dispatch({ type: "SET_BANKSTATEMENT_ACTIVE_TAB", payload: tabIndex });
  };

  const BankDocKeyValues = useMemo(() => {
    return (
      serviceType === "Bank Statement" &&
      tabAccNumber &&
      BankStatementResponse &&
      BankStatementResponse?.form[0][tabAccNumber][0] &&
      Object.entries(BankStatementResponse?.form[0][tabAccNumber][0])
    );
  }, [BankStatementResponse, tabAccNumber]);

  let RenderTable;
  if (
    selectedValue !== "Bank Statement" ||
    selectedValue !== "IRS Forms" ||
    selectedValue !== "Application Form"
  ) {
    RenderTable = (
      <Tabs
        defaultActiveKey={`${currentTab || all_tabs[0]}`}
        id="justify-tab-example"
        className="mb-3"
        justify
        onSelect={(tab) => setCurrentTab(tab)}
      >
        {all_tabs.map((tab) => {
          return (
            <Tab key={tab} eventKey={tab} title={tab}>
              <div className="Extraction">
                <div className="Extraction-tables">
                  <Table
                    kvpTdData={kvpTdData}
                    tableThData={tableThData}
                    tableTrData={tableTrData}
                  />
                </div>
              </div>
            </Tab>
          );
        })}
      </Tabs>
    );
  }
  if (
    selectedValue === "Bank Statement" ||
    selectedValue === "Credit Statement" ||
    selectedValue === "Forms" ||
    selectedValue === "IRS Forms" ||
    selectedValue === "Application Form"
  ) {
    RenderTable = (
      <Tabs
        defaultActiveKey={all_tabs[0] || 0}
        activeKey={bankStatementActiveTab}
        onSelect={handleTabSelect}
        className="mb-3 extraction-table-tab"
        justify
      >
        {serviceType !== "Bank Statement"
          ? BankStatementResponse &&
            BankStatementResponse?.map((tableData, tabIndex) => {
              const tableTitle = tableData[0]?.TabTitle || "No Title";
              let title = isNumber(tableTitle)
                ? `Account Number-${tableTitle}`
                : tableTitle;
              return (
                <Tab eventKey={tabIndex} title={title} key={tabIndex}>
                  <div className="Extraction">
                    <div className="Extraction-tables">
                      {}
                      <BankStatementTable
                        tableData={tableData}
                        selectedRow={selectedRow}
                        rowRefs={rowRefs}
                        searchRow={searchRow}
                        setSearchRow={setSearchRow}
                        setSelectedRow={setSelectedRow}
                        serviceType={serviceType}
                      />
                    </div>
                  </div>
                </Tab>
              );
            })
          : BankStatementResponse &&
            all_tabs &&
            tabAccNumber &&
            all_tabs?.map((tabData, tabIndex) => {
              const tableTitle = tabData || "No Title";
              let title = isNumber(tableTitle)
                ? `Acc No-${tableTitle}`
                : tableTitle;
              return (
                <Tab
                  eventKey={tabIndex}
                  title={
                    <span>
                      {title}
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip">Report</Tooltip>}
                      >
                        <FontAwesomeIcon
                          icon={faFileText}
                          onClick={() => setShowAccReport(true)}
                          style={{ marginLeft: "10px" }}
                        />
                      </OverlayTrigger>
                    </span>
                  }
                  key={tabIndex}
                >
                  <div className="Extraction">
                    {serviceType === "Bank Statement" && showAccReport && (
                      <div className="extraction_report">
                        {
                          <ExtractionReport
                            tableAccNo={tabAccNumber}
                            showAccReport={showAccReport}
                            setShowAccReport={setShowAccReport}
                            accReport={BankStatementResponse}
                          />
                        }
                      </div>
                    )}
                    <Tabs defaultActiveKey="form" className="acc-doc-details">
                      <Tab
                        eventKey="form"
                        title={
                          <div className="d-flex align-items-center justify-content-center">
                            Form{" "}
                            <FontAwesomeIcon
                              style={{ fontSize: "12px", marginLeft: "8px" }}
                              icon={faFileLines}
                            />
                          </div>
                        }
                      >
                        <table className="acc-doc-details-table">
                          <thead>
                            <tr>
                              <th>Key</th>
                              <th>Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {BankDocKeyValues?.map((item, id) => {
                              return (
                                <tr key={id}>
                                  <td style={{ fontWeight: "600" }}>
                                    {item[0]}
                                  </td>
                                  <td>{item[1]}</td>
                                </tr>
                              );
                            })}
                            <tr></tr>
                          </tbody>
                        </table>
                      </Tab>
                      <Tab
                        eventKey="table"
                        title={
                          <div className="d-flex align-items-center justify-content-center">
                            Table{" "}
                            <FontAwesomeIcon
                              style={{ fontSize: "12px", marginLeft: "8px" }}
                              icon={faTable}
                            />
                          </div>
                        }
                      >
                        <div className="Extraction-tables">
                          <BankStatementTable
                            tableData={BankStatementResponse}
                            selectedRow={selectedRow}
                            rowRefs={rowRefs}
                            searchRow={searchRow}
                            setSearchRow={setSearchRow}
                            setSelectedRow={setSelectedRow}
                            tableAccNo={tabAccNumber}
                            serviceType={serviceType}
                          />
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </Tab>
              );
            })}
      </Tabs>
    );
  }
  return <div className="output-table">{RenderTable}</div>;
};

export default ExtractionTable;
