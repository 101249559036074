import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import {
  Line,
  Pie,
  Doughnut,
  Bar,
  Radar,
  PolarArea,
  Bubble,
  Scatter,
} from "react-chartjs-2";

ChartJS.register(
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const chartComponents = {
  Line,
  Pie,
  Doughnut,
  Bar,
  Radar,
  PolarArea,
  Bubble,
  Scatter,
};

const AnomalyDetectionGraphs = ({ type, data, options }) => {
  const ChartComponent = chartComponents[type];
  return ChartComponent ? (
    <ChartComponent data={data} options={options} />
  ) : null;
};

export default AnomalyDetectionGraphs;
