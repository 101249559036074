import React, { useContext, useEffect } from "react";
import {
  FontAwesomeIcon,
  faAnglesLeft,
  faAnglesRight,
  faAngleLeft,
  faAngleRight,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faRotate,
} from "../../import/FontAwesomeIcons.js";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./ControlPanel.css";
import ParentContext from "../../context/context";

const PageControl = (props) => {
  return (
    <div className="page-control">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">Go to first page</Tooltip>}
      >
        <FontAwesomeIcon
          className={` aleft ${props.first_Page_Class}`}
          icon={faAnglesLeft}
          onClick={props.go_To_First_Page}
        />
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">Go to previous page</Tooltip>}
      >
        <FontAwesomeIcon
          className={` aleft ${props.first_Page_Class}`}
          icon={faAngleLeft}
          onClick={props.go_To_Previous_Page}
        />
      </OverlayTrigger>
      <span>
        Page
        <input
          name="pageNumber"
          type="number"
          min={1}
          max={props.numPages || 1}
          className="pageinput"
          value={props.pageNumber}
          onChange={props.on_Page_Change}
          // readOnly
        />
        of
        {props.serviceType === "Rent Roll"
          ? props?.numPages
          : props?.numPages?.length}
      </span>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">Go to next page</Tooltip>}
      >
        <FontAwesomeIcon
          className={` aright ${props.last_Page_Class}`}
          icon={faAngleRight}
          onClick={props.go_To_Next_Page}
        />
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">Go to last page</Tooltip>}
      >
        <FontAwesomeIcon
          className={` aright ${props.last_Page_Class}`}
          icon={faAnglesRight}
          onClick={props.go_To_Last_Page}
        />
      </OverlayTrigger>
    </div>
  );
};

const PageRotate = (props) => {
  return (
    <div className="page-rotate">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">Rotate</Tooltip>}
      >
        <FontAwesomeIcon icon={faRotate} onClick={props.rotate_Page} />
      </OverlayTrigger>
    </div>
  );
};

const ZoomControl = (props) => {
  return (
    <div className="zoom-control">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">Zoom out</Tooltip>}
      >
        <FontAwesomeIcon
          className={`zoomminus  ${props.zoom_Out_Class}`}
          icon={faMagnifyingGlassMinus}
          onClick={props.zoom_Out}
        />
      </OverlayTrigger>
      <span>{props.toFixed}%</span>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">Zoom in</Tooltip>}
      >
        <FontAwesomeIcon
          className={`zoomplus ${props.zoom_In_Class}`}
          icon={faMagnifyingGlassPlus}
          onClick={props.zoom_In}
        />
      </OverlayTrigger>
    </div>
  );
};

const ControlPanel = (props) => {
  const { state, dispatch } = useContext(ParentContext);
  const { getSelectedPages, serviceType } = state;
  const { numPages, pageNumber, scale, rotate } = props;

  const is_First_Page =
    pageNumber === (serviceType === "Rent Roll" ? 1 : getSelectedPages?.[0]);
  const is_Last_Page =
    pageNumber ===
    (serviceType === "Rent Roll"
      ? numPages
      : getSelectedPages?.[getSelectedPages?.length - 1]);

  const first_Page_Class = is_First_Page ? "disabled" : "clickable";
  const last_Page_Class = is_Last_Page ? "disabled" : "clickable";

  const go_To_First_Page = () => {
    if (!is_First_Page)
      dispatch({
        type: "SET_PAGE_NUMBER",
        payload: serviceType === "Rent Roll" ? 1 : getSelectedPages?.[0],
      });
  };

  const go_To_Previous_Page = () => {
    if (!is_First_Page) {
      const currentIndex = getSelectedPages?.indexOf(pageNumber);
      dispatch({
        type: "SET_PAGE_NUMBER",
        payload:
          serviceType === "Rent Roll"
            ? pageNumber - 1
            : getSelectedPages?.[currentIndex - 1],
      });
    }
  };

  const go_To_Next_Page = () => {
    if (!is_Last_Page) {
      const currentIndex = getSelectedPages?.indexOf(pageNumber);
      dispatch({
        type: "SET_PAGE_NUMBER",
        payload:
          serviceType === "Rent Roll"
            ? pageNumber + 1
            : getSelectedPages?.[currentIndex + 1],
      });
    }
  };
  const go_To_Last_Page = () => {
    if (!is_Last_Page)
      dispatch({
        type: "SET_PAGE_NUMBER",
        payload:
          serviceType === "Rent Roll"
            ? numPages
            : getSelectedPages?.[getSelectedPages?.length - 1],
      });
  };

  const on_Page_Change = (e) => {
    const { value } = e.target;
    dispatch({ type: "SET_PAGE_NUMBER", payload: Number(value) });
  };

  const rotate_Page = () => {
    dispatch({ type: "SET_ROTATE", payload: rotate + 90 });
  };

  const is_Min_Zoom = scale < 0.5;
  const is_Max_Zoom = scale >= 5.0;

  const zoom_Out_Class = is_Min_Zoom ? "disabled" : "clickable";
  const zoom_In_Class = is_Max_Zoom ? "disabled" : "clickable";

  const zoom_Out = () => {
    if (!is_Min_Zoom) dispatch({ type: "SET_SCALE", payload: scale - 0.1 });
  };

  const zoom_In = () => {
    if (!is_Max_Zoom) dispatch({ type: "SET_SCALE", payload: scale + 0.1 });
  };

  useEffect(() => {
    dispatch({
      type: "SET_PAGE_NUMBER",
      payload: serviceType === "Rent Roll" ? 1 : getSelectedPages?.[0],
    });
    return () => {
      dispatch({ type: "SET_SCALE", payload: scale });
    };
  }, [dispatch, getSelectedPages]);

  return (
    <div className="control-panel">
      <PageControl
        numPages={serviceType === "Rent Roll" ? numPages : getSelectedPages}
        pageNumber={pageNumber}
        first_Page_Class={first_Page_Class}
        last_Page_Class={last_Page_Class}
        go_To_First_Page={go_To_First_Page}
        go_To_Previous_Page={go_To_Previous_Page}
        go_To_Next_Page={go_To_Next_Page}
        go_To_Last_Page={go_To_Last_Page}
        on_Page_Change={on_Page_Change}
        serviceType={serviceType}
      />
      <PageRotate rotate_Page={rotate_Page} />
      <ZoomControl
        toFixed={(scale * 100).toFixed()}
        zoom_Out_Class={zoom_Out_Class}
        zoom_In_Class={zoom_In_Class}
        zoom_Out={zoom_Out}
        zoom_In={zoom_In}
      />
    </div>
  );
};

export default ControlPanel;
