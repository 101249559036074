import React, { useState, useEffect, useRef, useCallback } from "react";
import "../History.css";
import {
  Badge,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import {
  faChartLine,
  faCircleCheck,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PreviewHistory from "./PreviewHistory";
import { BStransactions, calculateOverallTime } from "..";
import FilterBar from "./FilterBar";
import CreLoader from "../../../services/CommercialRealEstate/components/CreLoader/CreLoader";
import { useToasts } from "../../../Hooks";
import { Link } from "react-router-dom";


const HistoryTable = () => {
  const [show, setShow] = useState(false);
  const [txnViewingID, setTxnViewingID] = useState(null);
  const [processedFiles, setProcessedFiles] = useState([]);

  const [txnData, setTxnData] = useState(BStransactions);
  const [filterdata, setFilterData] = useState(BStransactions);
  const timer = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const statusStyle = { marginRight: "4px", fontSize: ".7rem" };
  const [analyzeDates, setAnalyzeDates] = useState(["", ""]);
  const [filterDates, setFilterDates] = useState(["", ""]);
  const { Toast } = useToasts();
  // fucntion for filter
  const filterBySearchQuery = useCallback(
    (arr, serachBy) => {
      if (serachBy === "search") {
        if (searchQuery === "" && searchQuery.length <= 0) {
          return arr;
        } else {
          return arr.filter((el) =>
            el.fileName.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }
      } else if (serachBy === "dates") {
        if (!filterDates[0].isValid && !filterDates[1].isValid) {
          return arr;
        } else {
          const newarr = arr.filter((el) => {
            const inTimeDates = new Date(el.inTime).setHours(0, 0, 0, 0);
            const startDate = new Date(filterDates[0]).setHours(0, 0, 0, 0);
            const endDate = new Date(filterDates[1]).setHours(0, 0, 0, 0);
            if (startDate <= inTimeDates && endDate >= inTimeDates) {
              return el;
            }
          });

          return newarr;
        }
      }
    },
    [searchQuery, filterDates]
  );
  useEffect(() => {
    const filteredTxnData = filterBySearchQuery(txnData, "search");
    setFilterData(filteredTxnData);
  }, [searchQuery, txnData, filterBySearchQuery]);

  const handleFilter = useCallback(
    (e) => {
      const [startDate, endDate] = filterDates;
      e.preventDefault();
      if (startDate && endDate) {
        // Check if startDate and endDate are valid date objects
        if (startDate.isValid && endDate.isValid) {
          const filteredDataByFilterDates = filterBySearchQuery(
            txnData,
            "dates"
          );

          setFilterData(filteredDataByFilterDates);
        } else {
          Toast("error", "Invalid dates provided");
        }
      } else {
        Toast("error", "Both startDate and endDate must be selected");
      }
    },
    [filterDates, filterBySearchQuery]
  );
  // Function to increment time every second and update txnData

  useEffect(() => {
    const incrementTime = () => {
      setTxnData((prevData) =>
        prevData.map((txn) => {
          if (processedFiles.includes(txn.id)) {
            return { ...txn,  };
          }
          let { hours, minutes, seconds } = txn.addressIn;

          // Increment seconds
          seconds += 300;
          // Check if seconds are 300, then increment minutes
          if (seconds === 300) {
            seconds = 0;
            minutes += 5;
          }
          // Check if minutes are 60, then increment hours
          if (minutes === 60) {
            minutes = 0;
            hours += 1;
          }
          // Check if hours are 24, reset to 0
          if (hours === 24) {
            hours = 0;
          }
          return { ...txn, addressIn: { hours, minutes, seconds } };
        })
      );
    };
    if (processedFiles.length < txnData.length) {
      timer.current = setInterval(incrementTime, 300000); // Run the function every 5 mins
    }
    // Cleanup interval on component unmount
    return () => {
      clearInterval(timer.current);
    };
  }, [processedFiles, txnData]);

  // view clickhandler
  const handleView = (id) => {
    setShow(true);
    setTxnViewingID(id);
  };
  // Format time as HH:MM:SS with leading zeros

  const formatTime = useCallback((time) => {
    const { hours, minutes, seconds } = time;
    const Hours = String(hours).padStart(2, "0");
    const Minutes = String(minutes).padStart(2, "0");
    const Seconds = String(seconds).padStart(2, "0");
    return `${Hours}:${Minutes}:${Seconds}`;
  }, []);

  return (
    <div className="history">
      <FilterBar
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        analyzeDates={analyzeDates}
        setAnalyzeDates={setAnalyzeDates}
        filterDates={filterDates}
        setFilterDates={setFilterDates}
        handleFilter={handleFilter}
      />
      <div className="table-container">
        <Table className="history_table" hover>
          <thead>
            <tr>
              <th>S No.</th>
              <th>File Name</th>
              <th>Developer</th>
              <th>Preview</th>
              <th>In Time</th>

              <th>Out Time</th>
              <th>Address In</th>
              <th>Status</th>
              {/* <th>Analyze</th> */}
            </tr>
          </thead>
          <tbody>
            {filterdata &&
              filterdata.map((transaction, index) => {
                const bg =
                  transaction.status === "Processing"
                    ? "warning"
                    : transaction.status === "Processing"
                    ? "danger"
                    : "success";

                const totalSeconds =
                  calculateOverallTime(transaction.addressIn) / 1000;
                const isHighlight = totalSeconds > 300;

                return (
                  <React.Fragment key={transaction.id}>
                    <tr className="table-row">
                      <td>{index + 1}</td>
                      {/* filename */}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip">{transaction.fileName}</Tooltip>
                        }
                      >
                        <td className="file_name">
                          {transaction.fileName.slice(0, 10)}...
                        </td>
                      </OverlayTrigger>

                      {/* developer name */}
                      <td>{transaction?.developer}</td>

                      {/* view and save check */}
                      <td>
                        {processedFiles.includes(transaction.id) ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip">
                                Pdf is already saved
                              </Tooltip>
                            }
                          >
                            <span
                              style={{
                                color: "rgb(14 155 14)",
                                fontSize: "16px",
                              }}
                            >
                              <FontAwesomeIcon icon={faCircleCheck} />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip">View</Tooltip>}
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              onClick={() => handleView(transaction.id)}
                            />
                          </OverlayTrigger>
                        )}
                      </td>

                      {/* intime */}
                      <td>{transaction?.inTime}</td>

                      {/* outtime */}
                      <td>
                        {transaction.outTime ? (
                          <span>{transaction.outTime}</span>
                        ) : (
                          <span style={{ fontWeight: 700 }}>{"--:--:--"}</span>
                        )}
                      </td>

                      {/* wait_time */}
                      <td>
                        <div
                          className="wait_time"
                          style={{
                            color: isHighlight ? "#960606" : "",
                            fontWeight: isHighlight ? "700" : "",
                          }}
                        >
                          {processedFiles.includes(transaction.id) ? (
                            <div
                              style={{
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                marginRight: "5px",
                                backgroundColor: isHighlight
                                  ? "#960606"
                                  : "black",
                              }}
                            />
                          ) : (
                            <Spinner animation="grow" />
                          )}

                          <span style={{ marginBottom: "1px" }}>
                            {formatTime(transaction.addressIn)}
                          </span>
                        </div>
                      </td>
                      {/* status */}
                      <td>
                        {/* hey */}

                        <span className="cre-processing-card-status-msg">
                          {transaction.status && (
                            <Badge bg={bg}>
                              <span className="cre-processing-card-status">
                                <div style={statusStyle}>
                                  {transaction.status}
                                </div>
                                {transaction.status === "Processing" && (
                                  <CreLoader />
                                )}
                              </span>
                            </Badge>
                          )}
                        </span>
                        {/* hey */}
                      </td>

                      {/* <td>
                        <Link
                          to="/bankstatements/analysis"
                          className="analyze-link"
                          style={{textDecoration:"none",color:"rgb(16, 36, 71)"}}
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip">Analyze</Tooltip>}
                          >
                            <FontAwesomeIcon icon={faChartLine} />
                          </OverlayTrigger>
                        </Link>
                      </td> */}
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </Table>
      </div>
      {show && (
        <PreviewHistory
          show={show}
          setShow={setShow}
          txnData={txnData}
          filterdata={filterdata}
          setFilterData={setFilterData}
          setTxnData={setTxnData}
          txnViewingID={txnViewingID}
          processedFiles={processedFiles}
          setProcessedFiles={setProcessedFiles}
        />
      )}
    </div>
  );
};

export default HistoryTable;
