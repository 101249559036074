import { memo } from "react";
import ReactApexChart from "react-apexcharts";

const CategorizationParallelBarChart = () => {
  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: true,
      },
      background: "#fff",
    },

    plotOptions: {
      bar: {
        horizontal: false,
        // columnWidth: "55%",
        // endingShape: "rounded",
        borderRadiusApplication: "end",
        borderRadius: 6,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return "$" + val.toLocaleString();
      },
      offsetY: -20,
      style: {
        fontSize: "13px",
        colors: ["#304758"],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      title: {
        text: "Months",
      },
    },
    yaxis: {
      title: {
        text: "Amount ($)",
      },
      labels: {
        formatter: function (val) {
          return val.toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: function (val) {
          return "$ " + val.toLocaleString();
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetY: 0,
      fontSize: "13px",
      markers: {
        width: 12,
        height: 12,
        radius: 6,
      },
      itemMargin: {
        horizontal: 10,
      },
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    colors: ["#2E93fA", "#66DA26"],
  };

  const series = [
    {
      name: "Fixed Expenses",
      data: [5000, 5000, 5000, 5000, 5000, 5000],
    },
    {
      name: "Variable Expenses",
      data: [3200, 4100, 3800, 4500, 5200, 4800],
    },
  ];

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />
  );
};

export default memo(CategorizationParallelBarChart);
