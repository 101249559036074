import React from "react";
import {
  Redaction,
  Extraction,
  BankStatements,
  Classification,
  Recognition,
  GenerativeAI,
  CommercialRealEstate,
  PredictiveAnalytics,
  FinStatExtraction,
} from "./InferIq-Solution/services/index";
import {
  Setting,
  Dashboard,
  Faqs,
  OutputScreen,
  PageNotFound,
  PrivacyPolicy,
  PredictiveAnalyticsOutputScreen,
  Profile,
  History,
} from "./InferIq-Solution/pages/index";
import {
  BillingMatrics,
  BillingInfo,
  ClientOnboarding,
  ProcessedFilesInfo,
} from "./InferIq-Billing-Dashboard/index";
import {
  CustomerSettings,
  ProcessMateics,
  ProductInfo,
  SystemLogs,
  ServiceDashboard,
  RequestMonitoring,
} from "./InferIq-Service-Dashboard/index";
import {
  userPermission,
  initialPath,
} from "./InferIq-Solution/Auth/authConfig";
import { Navigate } from "react-router-dom";
import { UserManagment } from "./InferIq-Solution/pages/AdminSetting/components";
import ModuleSelection from "./InferIq-Solution/pages/ModuleSelection/ModuleSelection";
import BankStatementsAnalysis from "./InferIq-Solution/services/BankStatements/BankStatementsAnalysis/BankStatementsAnalysis";

const AllRoutes = [
  {
    path: "/",
    component: <Navigate to={initialPath} />,
  },
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/extraction",
    component: <Extraction />,
  },
  {
    path: "/finStatExtraction",
    component: <FinStatExtraction />,
  },
  {
    path: "/bankstatements",
    component: <BankStatements />,
  },
  {
    path: "/redaction",
    component: <Redaction />,
  },
  {
    path: "/recognition",
    component: <Recognition />,
  },
  {
    path: "/classification",
    component: <Classification />,
  },
  {
    path: "/generativeai",
    component: <GenerativeAI />,
  },
  {
    path: "/commercialRealEstate",
    component: <CommercialRealEstate />,
  },
  {
    path: "/billingMetrics",
    component: <BillingMatrics />,
  },
  {
    path: "/billingInfo",
    component: <BillingInfo />,
  },
  {
    path: "/clientOnboarding",
    component: <ClientOnboarding />,
  },
  {
    path: "/processedFileInfo",
    component: <ProcessedFilesInfo />,
  },
  {
    path: "/serviceDashboard",
    component: <ServiceDashboard />,
  },
  {
    path: "/requestMonitoring",
    component: <RequestMonitoring />,
  },
  {
    path: "/productInfo",
    component: <ProductInfo />,
  },
  {
    path: "/customerSettings",
    component: <CustomerSettings />,
  },
  {
    path: "/processMetrics",
    component: <ProcessMateics />,
  },
  {
    path: "/systemLogs",
    component: <SystemLogs />,
  },
  {
    path: "/privacyPloicy",
    component: <PrivacyPolicy />,
  },
  {
    path: "/predictiveAnalytics",
    component: <PredictiveAnalytics />,
  },
  {
    path: "/profile",
    component: <Profile />,
  },
 
  {
    path: "/settings",
    component: <Setting />,
  },
  {
    path: "/userManagement",
    component: <UserManagment />,
  },
  {
    path: "/moduleselection",
    component: <ModuleSelection />,
  },
  {
    path: "*",
    component: <PageNotFound />,
  }
];

const userPermissionPaths = userPermission?.map((route) => route?.path);
const alwaysRenderRoutes = [
  {
    path: "/",
    component: <Navigate to={initialPath} />,
  },
  {
    path: "/finStatExtraction",
    component: <FinStatExtraction />,
  },
  {
    path: "/profile",
    component: <Profile />,
  },
  {
    path: "/faqs",
    component: <Faqs />,
  },
  {
    path: "/OutputScreen",
    component: <OutputScreen />,
  },
  {
    path: "/privacyPolicy",
    component: <PrivacyPolicy />,
  },
  {
    path: "/predictiveAnalytics/PredictiveAnalyticsOutputScreen",
    component: <PredictiveAnalyticsOutputScreen />,
  },
  {
    path: "/billingMetrics",
    component: <BillingMatrics />,
  },
  {
    path: "/billingInfo",
    component: <BillingInfo />,
  },
  {
    path: "/clientOnboarding",
    component: <ClientOnboarding />,
  },
  {
    path: "/processedFileInfo",
    component: <ProcessedFilesInfo />,
  },
  {
    path: "/serviceDashboard",
    component: <ServiceDashboard />,
  },
  {
    path: "/requestMonitoring",
    component: <RequestMonitoring />,
  },
  {
    path: "/productInfo",
    component: <ProductInfo />,
  },
  {
    path: "/customerSettings",
    component: <CustomerSettings />,
  },
  {
    path: "/processMetrics",
    component: <ProcessMateics />,
  },
  {
    path: "/systemLogs",
    component: <SystemLogs />,
  },
  {
    path: "/privacyPloicy",
    component: <PrivacyPolicy />,
  },

  {
    path: "/settings",
    component: <Setting />,
  },
  {
    path: "/userManagement",
    component: <UserManagment />,
  },
  {
    path: "/moduleselection",
    component: <ModuleSelection />,
  },
  {
    path: "/bankstatements/analysis",
    component: <BankStatementsAnalysis />
  },
  {
    path: "/history",
    component: <History />
  },
  {
    path: "*",
    component: <PageNotFound />,
  },
];

export const Routes = AllRoutes?.filter((route) =>
  userPermissionPaths?.includes(route?.path)
).concat(alwaysRenderRoutes);
