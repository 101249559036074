import React, { memo, useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  faCheck,
  faFileArrowDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ControlPanel,
  CustomButton,
  PdfViewer,
} from "../../../components/index";
import "../../../components/Table/EditTransactionModal.css";
import { useCreHistoryApiCall, useHandleApi } from "../../../Hooks";
import "../History.css";
import ParentContext from "../../../context/context";
import BankStatementTable from "../../../components/Table/BankStatementTable";
import { convertMilliseconds, calculateOverallTime } from "..";

const PreviewHistory = (props) => {
  const { state, dispatch } = useContext(ParentContext);
  const {
    show,
    setShow,
    txnData,
    setTxnData,
    txnViewingID,
    processedFiles,
    setProcessedFiles,
  } = props;
  const [saveBSHistoryData, setSaveBSHistoryData] = useState(false);
  const { getBankStatementHistory } = useHandleApi();
  const { getBankStatementPreviewFile } = useCreHistoryApiCall();
  const {
    bankSttPreviewFile,
    backendResponse,
    numPages,
    pageNumber,
    scale,
    rotate,
  } = state;

  const handleSaveTxn = (id) => {
    setSaveBSHistoryData(true);

    const filteredTxnData = txnData?.filter((el) => el.id === id);

    filteredTxnData && calculateOutTime(filteredTxnData);

    setTimeout(() => {
      setSaveBSHistoryData(false);
      setShow(false);
    }, 1000);
    // some more logic to make PATCH/PUT Api call
  };

  const calculateOutTime = (filteredTxnData) => {
    const inDate = new Date(filteredTxnData[0].inTime);

    const totalMilliseconds = calculateOverallTime(
      filteredTxnData[0].addressIn
    );

    // Add the duration to the inTime
    const outDate = new Date(inDate.getTime() + totalMilliseconds);

    // Format outDate to YYYY-MM-DD HH:MM:SS
    const formattedOutTime = formatDate(outDate);
    const updatedOutTime =
      formatDate &&
      txnData?.map((txn) => {
        if (txn.id === txnViewingID) {
          const totalEndingMiliSeconds = calculateOverallTime(txn.addressIn);
          const totalwaittime = totalEndingMiliSeconds - txn.s;
          return {
            ...txn,
            outTime: formattedOutTime,
            waitTime: convertMilliseconds(totalwaittime),
            status: "Done",
          };
        } else {
          return txn;
        }
      });
    setTxnData(updatedOutTime);
    setProcessedFiles([...processedFiles, txnViewingID]);
    return formattedOutTime;
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    getBankStatementHistory();
    getBankStatementPreviewFile();
  }, []);

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="bsEditModal historyPreview"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Preview
          <CustomButton
            variant="primary"
            size="sm"
            onClick={() => setShow(false)}
            data-testid="cre-modal-close-button"
          >
            <FontAwesomeIcon icon={faXmark} />
          </CustomButton>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bs-history">
          {bankSttPreviewFile ? (
            <div className="bs-history-pdf">
              <div className="bs-history-pdfInfo">
                <span className="">_013122 WellsFargo.pdf</span>
                <span className="">2024-10-04 10:57:40</span>
                <CustomButton
                  variant="primary"
                  size="sm"
                  onClick={props.fileDownload}
                  data-testid="cre-modal-download-button"
                >
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </CustomButton>
              </div>
              <ControlPanel
                scale={scale || 1}
                setScale={(scale) =>
                  dispatch({ type: "SET_SCALE", payload: scale })
                }
                numPages={numPages || 1}
                pageNumber={pageNumber || 1}
                setPageNumber={(pageNumber) =>
                  dispatch({
                    type: "SET_PAGE_NUMBER",
                    payload: pageNumber,
                  })
                }
                rotate={rotate || ""}
                setRotate={(rotate) =>
                  dispatch({ type: "SET_ROTATE", payload: rotate })
                }
              />
              <PdfViewer pdf={bankSttPreviewFile} />
            </div>
          ) : (
            <div className="container-loader2">
              <div className="loading">
                <div className="ball one"></div>
                <div className="ball two"></div>
                <div className="ball three"></div>
                <div className="ball four"></div>
              </div>
            </div>
          )}
          {backendResponse?.data && (
            <div className="bs-history-table">
              <BankStatementTable
                tableData={backendResponse}
                serviceType="Bank Statement"
                tableAccNo="1254691015"
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {saveBSHistoryData ? (
          <CustomButton
            size="sm"
            variant="success"
            style={{ display: "flex", alignItems: "center" }}
          >
            Saved
            <FontAwesomeIcon
              icon={faCheck}
              style={{ color: "white", marginLeft: "10px" }}
            />
          </CustomButton>
        ) : (
          <CustomButton
            size="sm"
            variant="primary"
            onClick={() => handleSaveTxn(txnViewingID)}
          >
            Save
          </CustomButton>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default memo(PreviewHistory);
