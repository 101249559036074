import { Modal } from "react-bootstrap";
import "./EditTransactionModal.css";
import CustomButton from "../Button/CustomButton";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const AddMissingTransaction = React.memo((props) => {
  const {
    show,
    setShow,
    transactionData,
    bankSttTableData,
    setBankSttTableData,
    tableAccNo,
    rowIndex,
  } = props;
  const [addTransaction, setAddTransaction] = useState({});
  const [saveNewData, setSaveNewData] = useState(false);

  const handleAddMissingTransaction = () => {
    setSaveNewData(true);

    let updateTransactions = {
      ...bankSttTableData?.data[0][tableAccNo].splice(rowIndex + 1, 0, {
        ...addTransaction,
        "TEXTRACT_ID": addTransaction?.TEXTRACT_ID + "TEST123",
      }),
    };
    let transactionWithNewRow = {
      ...bankSttTableData,
      updateTransactions,
    };
    setBankSttTableData(transactionWithNewRow);

    setTimeout(() => {
      setShow(false);
      setSaveNewData(false);
    }, 1000);
  };

  useEffect(() => {
    setAddTransaction(transactionData);
  }, [transactionData]);

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="bsEditModal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Transaction
          <FontAwesomeIcon icon={faTimes} onClick={() => setShow(false)} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="bsEditModalForm">
          <div className="wrap">
            {addTransaction &&
              Object.entries(addTransaction)?.map(([key, value]) => {
                return (
                  key !== "CONFIDENCE" &&
                  key !== "TEXTRACT_ID" && (
                    <div key={key} className="editField">
                      <label>{key?.replaceAll("_", " ")}</label>
                      <input
                        type="text"
                        key={key}
                        value={value}
                        name={key}
                        onChange={(e) => {
                          setAddTransaction({
                            ...addTransaction,
                            [key]: e.target.value,
                          });
                        }}
                      />
                    </div>
                  )
                );
              })}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          size="sm"
          variant="primary"
          onClick={() => setShow(false)}
        >
          Remove
        </CustomButton>
        {saveNewData ? (
          <CustomButton
            size="sm"
            variant="success"
            style={{ display: "flex", alignItems: "center" }}
          >
            Saved
            <FontAwesomeIcon
              icon={faCheck}
              style={{ color: "white", marginLeft: "10px" }}
            />
          </CustomButton>
        ) : (
          <CustomButton
            size="sm"
            variant="primary"
            onClick={() => handleAddMissingTransaction()}
          >
            Save
          </CustomButton>
        )}
      </Modal.Footer>
    </Modal>
  );
});

export default AddMissingTransaction;
