import React, { useCallback } from "react";
import { Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faFilter,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "./DateRangePicker/DateRangePicker";

import { CustomButton } from "../../../components";
import "./FilterBar.css"; // Import your custom CSS

import { useToasts } from "../../../Hooks";
import { Link } from "react-router-dom";

const FilterBar = React.memo(
  ({
    searchQuery,
    setSearchQuery,
    analyzeDates,
    setAnalyzeDates,
    filterDates,
    setFilterDates,
    handleFilter,
  }) => {
    const { Toast } = useToasts();
    const handleChange = useCallback(
      (e) => {
        setSearchQuery(e.target.value);
      },
      [setSearchQuery]
    );

    const handleFilterAnalyze = (e) => {
      const [startDate, endDate] = analyzeDates;
      e.preventDefault();
      if (startDate && endDate) {
        // Check if startDate and endDate are valid date objects
        if (startDate.isValid && endDate.isValid) {
        } else {
          Toast("error", "Invalid dates provided");
        }
      } else {
        Toast("error", "Both startDate and endDate must be selected");
      }
    };

    const handleSearch = (e) => {
      e.preventDefault();
      setSearchQuery(searchQuery ? "" : searchQuery);
    };

    return (
      <div className="filter-bar">
        <div className="filter-bar-one-side">
          <div className="search-form">
            {/* search bar and button side */}
            <InputGroup className="search-input">
              <Form.Control
                type="text"
                placeholder="Search File Name"
                value={searchQuery}
                onChange={handleChange}
                required
              />
              <CustomButton variant="primary" onClick={handleSearch}>
                {searchQuery ? (
                  <FontAwesomeIcon icon={faTimes} />
                ) : (
                  <FontAwesomeIcon icon={faSearch} />
                )}
              </CustomButton>
            </InputGroup>
          </div>
          <Form className="date-filters" onSubmit={handleFilter}>
            <DateRangePicker
              filterDates={filterDates}
              handleDateChange={(dates) => {
                setFilterDates(dates);
              }}
              placeholder="Choose Dates Range"
              dateFormat="YYYY MMM DD"
              minDate="2020-11-10"
              maxDate={new Date()}
            />

            <CustomButton variant="primary" type="submit">
              <FontAwesomeIcon icon={faFilter} style={{ marginRight: "5px" }} />
              Filter
            </CustomButton>
          </Form>
        </div>

        {/* <Form
          className="analyze-by-date-filters date-filters"
          onSubmit={handleFilterAnalyze}
        >
          <DateRangePicker
            filterDates={analyzeDates}
            handleDateChange={(dates) => {
              setAnalyzeDates(dates);
            }}
            placeholder="Choose Dates Range"
            dateFormat="YYYY MMM DD"
            minDate="2020-11-10"
            maxDate={new Date()}
          />
          <Link to="/bankstatements/analysis" className="analyze-link">
            <CustomButton variant="primary" type="submit">
              <FontAwesomeIcon
                icon={faChartLine}
                style={{ marginRight: "5px" }}
              />
              Analyze
            </CustomButton>
          </Link>
        </Form> */}
      </div>
    );
  }
);

export default FilterBar;
